import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { UserSelectors, AppConfigSelectors } from "redux/selectors";
import { AppModules, AppModuleNames } from "components/modules/AppModules";
import { Routes } from "./Routes";
import { appStateService } from "App";
import { useTranslateFn } from "hooks/i18n";

const strings = {
	moduleNotEnabled: "account.module-not-available"
};

// Map routes to their corresponding modules
const routeToModuleMap: Record<string, AppModuleNames> = {
	// Basic module routes
	[Routes.CategoriesList]: AppModuleNames.basic,
	[Routes.ClientsList]: AppModuleNames.basic,
	[Routes.CompanyLinkList]: AppModuleNames.basic,
	[Routes.ProductsList]: AppModuleNames.basic,
	[Routes.SellOrderForm]: AppModuleNames.basic,

	// Brands module routes
	[Routes.BrandsList]: AppModuleNames.brands,

	// Stock Management module routes
	[Routes.ProductStockList]: AppModuleNames.stockManagement,
	[Routes.ProductStockEdit()]: AppModuleNames.stockManagement,
	[Routes.StockMovementsList]: AppModuleNames.stockManagement,

	// Supplier Management module routes
	[Routes.SuppliersList]: AppModuleNames.supplierManagement,

	// Order Management module routes
	[Routes.OrderManagement]: AppModuleNames.orderManagement,
	[Routes.ClientOrderList]: AppModuleNames.orderManagement,
	[Routes.ClientOrder()]: AppModuleNames.orderManagement,

	// Order Production module routes
	[Routes.OrderProductionPage]: AppModuleNames.orderProduction,
	[Routes.OrderProductionCarouselPage]: AppModuleNames.orderProduction,

	// Order External module routes
	[Routes.ExternalOrderForm()]: AppModuleNames.orderExternal,
	[Routes.InternalOrderForm]: AppModuleNames.orderExternal,

	// Store Front module routes
	[Routes.StoreHome()]: AppModuleNames.storeFront,
	[Routes.StoreItemView()]: AppModuleNames.storeFront,

	// Table Service module routes
	[Routes.ClientTables]: AppModuleNames.tableService,

	// Dashboard module routes
	[Routes.DashboardGraphDetail()]: AppModuleNames.dashboard,

	// Calendar module routes
	[Routes.Calendar]: AppModuleNames.calendar,

	// Admin module routes
	[Routes.CompanyAdminList]: AppModuleNames.admin,
	[Routes.MailerAdminList]: AppModuleNames.admin
};

/**
 * Determines if a path is an external route that doesn't require authentication
 * @param path The current path
 */
export const isExternalRoute = (path: string): boolean => {
	return (
		path === Routes.Landing ||
		path === Routes.Login ||
		path === Routes.LoginPasswdRecovery ||
		path === Routes.SignUp ||
		path === Routes.OnBoarding ||
		path === Routes.ExternalSite ||
		path.startsWith("/order/") ||
		path.startsWith("/s/")
	);
};

/**
 * Checks if a given path belongs to a specific module
 * @param path The current path
 * @param moduleName The module name to check against
 */
export const isPathInModule = (
	path: string,
	moduleName: AppModuleNames
): boolean => {
	// Check exact matches first
	if (routeToModuleMap[path] === moduleName) {
		return true;
	}

	// Check for dynamic routes (those with parameters)
	for (const [route, module] of Object.entries(routeToModuleMap)) {
		if (module === moduleName) {
			// Convert route patterns like "/path/:param" to regex
			const routePattern = route
				.replace(/\(.*\)/g, "") // Remove function calls
				.replace(/:[^/]+/g, "[^/]+"); // Replace :param with regex pattern

			const regex = new RegExp(`^${routePattern}$`);
			if (regex.test(path)) {
				return true;
			}
		}
	}

	return false;
};

/**
 * Gets the module name for a given path
 * @param path The current path
 */
export const getModuleForPath = (path: string): AppModuleNames | null => {
	// Check exact matches first
	if (routeToModuleMap[path]) {
		return routeToModuleMap[path];
	}

	// Check for dynamic routes (those with parameters)
	for (const [route, module] of Object.entries(routeToModuleMap)) {
		// Convert route patterns like "/path/:param" to regex
		const routePattern = route
			.replace(/\(.*\)/g, "") // Remove function calls
			.replace(/:[^/]+/g, "[^/]+"); // Replace :param with regex pattern

		const regex = new RegExp(`^${routePattern}$`);
		if (regex.test(path)) {
			return module;
		}
	}

	return null;
};

/**
 * A component that guards routes based on the active company's enabled modules.
 * If a user navigates to a route that belongs to a module not enabled for the current company,
 * they will be redirected to the home page.
 */
export const ModuleRouteGuard: React.FC<{ children: React.ReactNode }> = ({
	children
}) => {
	const translate = useTranslateFn();
	const location = useLocation();
	const navigate = useNavigate();
	const [isChecking, setIsChecking] = useState(false);
	const [checkedPaths, setCheckedPaths] = useState<Record<string, boolean>>(
		{}
	);

	const authenticated = useSelector(UserSelectors.selectAuthenticated);
	const activeCompanyId = useSelector(
		UserSelectors.selectUserProfileActiveCompany
	);

	// Add selectors for profile loading state
	const profileLoaded = useSelector(
		AppConfigSelectors.getCompanyProfileLoaded
	);
	const profileLoading = useSelector(
		AppConfigSelectors.getCompanyProfileLoading
	);
	const isLoading = useSelector(AppConfigSelectors.getLoading);

	const pathKey = `${activeCompanyId}:${location.pathname}`;

	const checkRouteAccess = useCallback(async () => {
		if (isChecking) return;

		setIsChecking(true);

		// Get the module for the current path
		const requiredModule = getModuleForPath(location.pathname);

		// If we can't determine the module, allow access (might be a new route not mapped yet)
		if (!requiredModule) {
			// Mark this path as checked for this company
			setCheckedPaths((prev) => ({
				...prev,
				[pathKey]: true
			}));
			setIsChecking(false);
			return;
		}

		// Check if the required module is enabled
		const isModuleEnabled = AppModules[requiredModule]?.enabled ?? false;

		if (!isModuleEnabled) {
			// Module is not enabled, redirect to home
			setIsChecking(false);
			appStateService.appManager.showWarning(
				translate(strings.moduleNotEnabled)
			);
			navigate(Routes.Home);
		}

		// Mark this path as checked for this company
		setCheckedPaths((prev) => ({
			...prev,
			[pathKey]: true
		}));

		setIsChecking(false);
	}, [location.pathname, pathKey, isChecking, translate, navigate]);

	const verifyRoute = useCallback(async () => {
		// If profile is still loading, don't check routes yet
		if (!profileLoaded || profileLoading || isLoading) {
			setIsChecking(false);
			return;
		}

		// Check if the current route is external
		const isExternal = isExternalRoute(location.pathname);

		// TODO: Fix this
		// Skip check for external routes, home, error, and authentication routes immediately
		if (isExternal) {
			setIsChecking(false);
			return;
		}

		// If not authenticated, don't check routes
		if (!authenticated) {
			setIsChecking(false);
			return;
		}

		// If we've already checked this path for this company, don't check again
		// const pathKey = `${activeCompanyId}:${location.pathname}`;
		if (checkedPaths[pathKey]) {
			setIsChecking(false);
			return;
		}

		checkRouteAccess();
	}, [
		checkRouteAccess,
		location.pathname,
		pathKey,
		profileLoading,
		isLoading,
		checkedPaths,
		authenticated
	]);

	// Use a more efficient approach to track checked paths
	useEffect(() => {
		verifyRoute();
	}, [verifyRoute]);

	// Reset checked paths when company changes
	useEffect(() => {
		setCheckedPaths({});
	}, [activeCompanyId]);

	// If profile is loading or we're checking routes, show nothing to prevent flash of content
	if (profileLoading || isLoading || isChecking) {
		return null;
	}

	return <>{children}</>;
};

export default ModuleRouteGuard;
