import FirebaseFunctionsService from "services/firebase/functions/FirebaseFunctionsService";

/**
 * Used to get the App Config through a Cloud Function
 */
class GetAppConfigService extends FirebaseFunctionsService {
	constructor() {
		super("getAppConfig");
	}
}

export { GetAppConfigService };
