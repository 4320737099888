import React, { useCallback } from "react";
import { appStateService } from "App";
import { Toast } from "./Toast";
import { AppConfigSelectors } from "redux/selectors";
import { useSelector } from "react-redux";
import { IToast } from "models/toast/Toast";

import "./ToastContainer.scss";

/**
 * Toast container component that manages multiple toasts.
 * @returns The toast container component.
 */
export const ToastContainer: React.FC = () => {
	const toasts = useSelector(AppConfigSelectors.getToasts);

	const handleDismiss = useCallback((toastId: string) => {
		appStateService.appManager.dismissToast(toastId);
	}, []);

	return (
		<div className="toast-container">
			{toasts.length > 0 &&
				toasts.map((toast: IToast) => (
					<Toast
						key={toast.id}
						message={toast.message}
						type={toast.type}
						onDismiss={() => handleDismiss(toast.id)}
						open={toast.open}
					/>
				))}
		</div>
	);
};
