import React, { useMemo } from "react";
import { IClickUpTaskComment } from "models/chatAssist";
import { appStateService } from "App";
import { CompanySelectors, UserSelectors } from "redux/selectors";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useTranslateFn } from "hooks/i18n";

import "./ChatAssistModal.scss";

/**
 * Contract for the Chat Message Group.
 */
export interface IChatMessageGroupProps {
	ticketMessage: IClickUpTaskComment;
}

/**
 * Chat Message Group, which composes a single interaction content.
 */
export const ChatMessageGroup: React.FC<IChatMessageGroupProps> = ({
	ticketMessage
}) => {
	const translate = useTranslateFn();
	// TODO: diff. currentUser from message user
	const strings = {
		actions: {
			messageCopied: translate("chatAssist.chat.messageCopied")
		}
	};
	const activeCompanyId = useSelector(
		UserSelectors.selectUserProfileActiveCompany
	);
	const activeCompany = useSelector((state: RootState) =>
		CompanySelectors.getCompanyById(state, activeCompanyId)
	);

	const isReporter = useMemo(
		() => ticketMessage.user.username.toLowerCase().includes("beebase"),
		[ticketMessage]
	);

	const resolvedUserName = useMemo(
		() => (isReporter ? activeCompany.name : ticketMessage.user.username),
		[activeCompany, ticketMessage]
	);

	const onClickChatMessage = (event: React.MouseEvent<HTMLDivElement>) => {
		event.preventDefault();

		window.navigator.clipboard.writeText(ticketMessage.comment_text);
		appStateService.appManager.showInfoToast(strings.actions.messageCopied);
	};

	return (
		<div
			id={`chat-message-${ticketMessage.id}`}
			className={`chat-message-group ${isReporter ? "reporter" : ""}`}
		>
			<div className="chat-message-title">
				<span className="sender">{resolvedUserName}</span>
				<span className="date-time">
					{appStateService.format.time(
						new Date(parseInt(`${ticketMessage.date}`)),
						{
							hour: "2-digit",
							minute: "2-digit",
							hour12: false
						}
					)}
				</span>
			</div>
			<div className="chat-message-comment" onClick={onClickChatMessage}>
				{ticketMessage.comment_text}
			</div>
		</div>
	);
};
