import React from "react";
import { Toggle } from "@fluentui/react";

import "./input.scss";
import { WarningIcon } from "../icon";
import { IconSize } from "../icon/shared/interfaces";

/**
 * Contract for the properties of the InputToggle component.
 */
export interface IInputToggleProps {
	id: string;
	label: string;
	checked?: undefined | boolean;
	disabled?: undefined | boolean;
	onChange?:
		| undefined
		| ((event: React.MouseEvent<HTMLInputElement>) => void);
	onText?: undefined | string;
	offText?: undefined | string;
	hint?: undefined | string;
}

/**
 * InputToggle Component definitions, for switching things on and off.
 *
 * @param props The possible toggle component properties.
 * @returns JSX.Element
 */
const InputToggle: React.FC<IInputToggleProps> = (props: IInputToggleProps) => {
	return (
		<>
			{props?.hint ? (
				<div className="input-hint">
					<WarningIcon size={IconSize.MD} /> {props?.hint ?? ""}
				</div>
			) : null}
			<Toggle
				id={props?.id}
				label={props?.label}
				checked={props?.checked ?? false}
				disabled={props?.disabled ?? false}
				onChange={(
					event: React.MouseEvent<HTMLInputElement>,
					checked: boolean
				) => {
					return typeof props?.onChange === "function"
						? props?.onChange({
								...event,
								currentTarget: {
									...event.currentTarget,
									checked: checked,
									ariaChecked: `${checked}`
								}
						  })
						: null;
				}}
				onText={props?.onText}
				offText={props?.offText}
			/>
		</>
	);
};

export { InputToggle };
