import * as Sentry from "@sentry/react";

/**
 * Sentry configuration contract
 */
export interface ISentryConfig {
	dsn: string;
	integrations: any[];
	tracesSampleRate: number;
	replaysSessionSampleRate: number;
	replaysOnErrorSampleRate: number;
}

/**
 * Sentry Service fixture implementation class.
 */
export class SentryService {
	startupTime: Date;
	private _config: ISentryConfig = {
		dsn: process.env.REACT_APP_SENTRY_DSN,
		integrations: [
			Sentry.browserTracingIntegration({
				// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
				tracePropagationTargets: [
					/^https:\/\/beebase\.[store|online]\//
				]
			}),
			Sentry.replayIntegration()
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, // Capture 100% of the transactions
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	};

	constructor() {
		if (process.env.NODE_ENV !== "development") {
			Sentry.init(this._config);
			this.startupTime = new Date();
		}
	}

	private prepareUser(): any {
		// TODO: Get user from Redux
		// const user = UserSelectors.selectUserProfile(store.getState());
		const user = { mail: "anonymous", id: "anonymous" };

		return {
			email: user.mail ?? "anonymous",
			id: user.id ?? "anonymous"
		};
	}

	/**
	 * Logs a message to Sentry.
	 *
	 * @param message Message to be logged.
	 */
	log(message: string): string {
		return Sentry.captureMessage(message, this.prepareUser());
	}

	/**
	 * Logs an error to Sentry.
	 *
	 * @param error Error to be logged.
	 */
	error(error: Error): string {
		return Sentry.captureException(error, this.prepareUser());
	}

	/**
	 * Checks if Sentry is initialized.
	 *
	 * @returns True if Sentry is initialized, false otherwise.
	 */
	isInitialized(): boolean {
		return this.startupTime !== undefined;
	}
}

export const SentryServiceInstance = new SentryService();
