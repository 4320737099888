import { createSelector } from "@reduxjs/toolkit";
import { ICustomer } from "models/index";
import { RootState } from "redux/store";

/**
 * Selectors: Customer.
 */
export const CustomersSelectors = {
	getCustomers: (state: RootState) => state.customers.list,
	getCustomerByIds: createSelector(
		(state: RootState, itemIds: string[]) => itemIds,
		(state: RootState) => state.customers.list,
		(itemIds, customers: ICustomer[]) =>
			customers.filter((customer) => itemIds.includes(customer.id))
	),
	getCustomerById: createSelector(
		(state: RootState, itemId: string) => itemId,
		(state: RootState) => state.customers.list,
		(itemId, customers: ICustomer[]) =>
			customers.find((customer) => customer.id === itemId)
	),
	getCustomersLoading: (state: RootState) => state.customers.loading,
	getCustomersQueried: (state: RootState) => state.customers.queried,
	getIsEditing: (state: RootState) => state.customers.editing,
	getCustomersEditing: (state: RootState) => state.customers.editingList
};
