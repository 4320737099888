// src/services/calendar/CompanyCalendarService.ts
import { Timestamp, Unsubscribe } from "firebase/firestore";
import { ICalendarItem } from "interfaces/calendar/ICalendarItem";
import { CompanySegment } from "services/companies/CompanySegment";
import { actions } from "redux/reducers/calendar/reducer";
import {
	and,
	PropQueryCriteria,
	whereBetween,
	whereLike
} from "services/firebase/helpers";

/**
 * Service for managing calendar events for a company
 */
export class CompanyCalendarService extends CompanySegment<ICalendarItem> {
	constructor(companyId: string) {
		super(
			companyId,
			"calendar",
			actions.setEvents,
			actions.setLoadedEvents,
			actions.setLoadingEvents
		);
	}

	/**
	 * Creates a new calendar event
	 * @param event The event to create
	 * @returns Promise with the created event
	 */
	async createEvent(event: ICalendarItem): Promise<boolean> {
		const newEvent = {
			...event,
			timestamp: Timestamp.now(),
			enabled: true,
			deleted: false
		};

		return await this.createItem(newEvent);
	}

	/**
	 * Updates an existing calendar event
	 * @param event The event to update
	 * @returns Promise with the update result
	 */
	async updateEvent(event: ICalendarItem): Promise<boolean> {
		const updatedEvent = {
			...event,
			timestamp: Timestamp.now()
		};

		return await this.updateItem(updatedEvent);
	}

	/**
	 * Deletes a calendar event
	 * @param eventId The ID of the event to delete
	 * @returns Promise indicating success
	 */
	async deleteEvent(eventId: string): Promise<boolean> {
		return await this.deleteItem(eventId);
	}

	/**
	 * Gets all calendar events for the company
	 * @returns Promise with array of events
	 */
	async getAllEvents(): Promise<ICalendarItem[]> {
		return await this.queryItems(whereLike("enabled", true));
	}

	/**
	 * Gets events for a specific date range
	 * @param startDate Start date of range
	 * @param endDate End date of range
	 * @returns Promise with array of events in range
	 */
	async getEventsByDateRange(
		startDate: Date,
		endDate: Date
	): Promise<ICalendarItem[]> {
		return await this.queryItems([
			and(
				...whereLike("enabled", true),
				...whereBetween("startDate", startDate, endDate)
			)
		]);
	}

	/**
	 * Gets a single event by ID
	 * @param eventId The ID of the event to get
	 * @returns Promise with the event
	 */
	async getEventById(eventId: string): Promise<ICalendarItem | null> {
		return await this.getItemById(eventId);
	}

	/**
	 * Sets up a real-time listener for calendar events
	 * @param callback Function to call when events change
	 * @returns Unsubscribe function
	 */
	async onEventsChange(
		callback: (events: ICalendarItem[]) => void
	): Promise<Unsubscribe> {
		const filters: PropQueryCriteria[][] = [whereLike("enabled", true)];

		return Promise.resolve(this.getItemsLive(callback, filters));
	}
}
