import React, { useMemo } from "react";

import { IChatAssistSlice, IClickUpTask } from "models/chatAssist";
import { BackIcon } from "components/shared/icon";
import { IconSize } from "components/shared/icon/shared/interfaces";
import { useDispatch } from "react-redux";
import { actions } from "redux/reducers/chatAssist/reducer";

/**
 * Contract for the Chat Assist Header component.
 */
export interface IChatAssistHeaderProps {
	activeTicket: IClickUpTask;
	chatAssist: IChatAssistSlice;
}

/**
 * Component for handling the Header variations of the Chat Assist group.
 */
export const ChatAssistHeader: React.FC<IChatAssistHeaderProps> = ({
	chatAssist,
	activeTicket
}) => {
	const dispatch = useDispatch();

	// TODO: Add translate support
	const strings = useMemo(
		() => ({
			defaultTitle: "Precisa de Ajuda?",
			ticketHistoryTitle: "Histórico de Ajudas",
			ticketTitle: `Ticket ${activeTicket?.id ?? ""}`
		}),
		[chatAssist.isOnline, activeTicket, chatAssist.tickets]
	);

	return (
		<div className={"chat-assist-header"}>
			{chatAssist.showHistory ? (
				<BackIcon
					size={IconSize.MD}
					onClick={() => {
						if (activeTicket !== null) {
							dispatch(actions.setActiveTicket(""));
						} else if (chatAssist.showHistory) {
							dispatch(actions.setShowHistory(false));
						}
					}}
				/>
			) : null}
			<h2 className={"chat-assist-modal-title"}>
				{chatAssist.showHistory
					? activeTicket !== null
						? strings.ticketTitle
						: strings.ticketHistoryTitle
					: strings.defaultTitle}
			</h2>
		</div>
	);
};
