import FirebaseFunctionsService from "services/firebase/functions/FirebaseFunctionsService";

/**
 * Service for handling 2FA verification during login.
 * This service uses a callable function with App Check enabled.
 */
class Verify2FAService extends FirebaseFunctionsService {
	constructor() {
		super("verify2FA");
	}

	/**
	 * Generates a new 2FA code and sends it to the user's email
	 * @param userId The ID of the user requiring 2FA
	 * @returns Promise with the result of the operation
	 */
	async generateCode(userId: string) {
		return this.callService({
			userId,
			action: "generate"
		});
	}

	/**
	 * Verifies a 2FA code provided by the user
	 * @param userId The ID of the user
	 * @param code The 2FA code to verify
	 * @returns Promise with the result of the operation
	 */
	async verifyCode(userId: string, code: string) {
		return this.callService({
			userId,
			code
		});
	}
}

export { Verify2FAService };
