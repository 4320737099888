import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxSlices } from "redux/slices";
import { categoriesInitialState as initialState, ICategory } from "models";
import { ISetEditingAction } from "interfaces/actions";
import { IdentifiedItem } from "interfaces/index";

const editListItemById = <T extends IdentifiedItem>(
	list: T[],
	itemId: string,
	itemUpdated: T
): T[] => {
	const index = list.findIndex((listItem) => listItem.id === itemId);
	if (index >= 0) {
		list[index] = itemUpdated;
	}
	return list;
};

/**
 * Categories Redux slice.
 */
export const categoriesSlice = createSlice({
	name: ReduxSlices.Categories,
	initialState,
	reducers: {
		setList: (state, action: PayloadAction<ICategory[]>) => {
			state.list = action.payload;
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		setQueried: (state, action: PayloadAction<boolean>) => {
			state.queried = action.payload;
			state.loading = false;
		},
		setEditing: (
			state,
			action: PayloadAction<ISetEditingAction<ICategory>>
		) => {
			state.editing = action.payload.editing;
			state.editingList = action.payload.items;
		},
		setEditByID: (state, action: PayloadAction<ICategory>) => {
			editListItemById(state.list, action.payload.id, action.payload);

			// TODO: Remove and externalize the approach above
			// const index = state.list.findIndex(
			// 	(item) => item.id === action.payload.id
			// );
			// if (index >= 0) {
			// 	// const newList = [...state.list];

			// 	// newList[index] = action.payload;

			// 	// state.list = [...newList];

			// 	state.list[index] = action.payload;
			// }
		},
		setEditingList: (state, action: PayloadAction<ICategory[]>) => {
			state.editingList = action.payload;
		},
		resetEditing: (state) => {
			state.editing = false;
			state.editingList = [];
		},
		reset: (state) => {
			state.editing = false;
			state.editingList = [];
			state.list = [];
			state.loading = false;
			state.queried = false;
		}
		// setError: (state, action: PayloadAction<string>) => {
		// 	state.error = action.payload;
		// }
	}
});

export const { reducer, actions } = categoriesSlice;

export default reducer;

// TODO: Selectors, split file
