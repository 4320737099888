import { appStateService, store } from "App";
import { actions } from "redux/reducers/chatAssist/reducer";
import {
	ChatAssistSelectors,
	CompanySelectors,
	UserSelectors
} from "redux/selectors";

/**
 * Handles the creation of a new comment right below and active ticket comments list.
 *
 * @param onComplete [Optional] The completion callback.
 */
export const onCreateCommentToActiveTicket = async (
	onComplete?: undefined | ((createdItem: any) => Promise<void>)
) => {
	const _svc = appStateService.clickUp.get();
	const state = store.getState();
	const chatAssist = ChatAssistSelectors.get(state);
	const activeTicket = ChatAssistSelectors.getActiveTicket(state);
	const activeUser = UserSelectors.selectUserProfile(state);
	const activeCompanyId = UserSelectors.selectUserProfileActiveCompany(state);
	const activeCompany = CompanySelectors.getCompanyById(
		state,
		activeCompanyId
	);
	const createTime = new Date().getTime();
	const chatMessage = chatAssist.data.message;

	if (!chatAssist.submitting) {
		store.dispatch(actions.setSubmitting(true));
	}

	await _svc
		.createTaskComment(activeTicket.id, chatMessage)
		.then((result) => {
			const ticketMessagesById =
				ChatAssistSelectors.getMessagesByTicketId(
					state,
					activeTicket.id
				);

			// Anticipates the adding of a message to the queue.
			store.dispatch(
				actions.setMessagesByTicketId({
					ticketId: activeTicket.id,
					messages: [
						...ticketMessagesById,
						{
							id: `${result.id}`,
							taskId: activeTicket.id,
							groupAssignee: "",
							reactions: [],
							reply_count: 0,
							date: createTime,
							assignee: activeUser.mail,
							comment_text: chatMessage,
							user: {
								email: activeUser.mail,
								username:
									activeCompany?.name ?? activeUser.name,
								color: "black",
								id: ""
							}
						}
					]
				})
			);

			if (typeof onComplete === "function") {
				onComplete(result);
			}

			// const _editorBox: HTMLTextAreaElement = document.getElementById(
			// 	chatEditorTextBoxId
			// ) as HTMLTextAreaElement;
			// _editorBox.value = "";
			// dispatch(actions.setMessage(""));
			// onGetTasksAndComments(true);
		})
		.catch((error) => {
			throw error;
		})
		.finally(() => {
			store.dispatch(actions.setSubmitting(false));
		});
};
