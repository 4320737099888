import React from "react";

/**
 * InputBase props contract interface.
 */
interface ITextAreaProps {
	id: string;
	className?: undefined | string;
	onChange?: undefined | ((event: any) => void);
	onKeyDown?: undefined | ((event: any) => void);
	onKeyUp?: undefined | ((event: any) => void);
	children?: JSX.Element | string | any;
	disabled?: undefined | boolean;
	placeholder?: undefined | string;
	rows?: undefined | number;
	style?: undefined | React.CSSProperties;
	minLength?: undefined | number;
	maxLength?: undefined | number;
	autoFocus?: undefined | boolean;
}

/**
 * InputBase, basic for constructing other shared input components.
 *
 * @param props IInputBaseProps
 * @returns
 */
function TextArea(props: ITextAreaProps): JSX.Element {
	return (
		<textarea
			id={props.id}
			onChange={props.onChange ?? undefined}
			onKeyDown={props.onKeyDown ?? undefined}
			onKeyUp={props.onKeyUp ?? undefined}
			disabled={props.disabled ?? undefined}
			rows={props.rows ?? undefined}
			className={props.className ?? undefined}
			placeholder={props.placeholder ?? undefined}
			style={props.style ?? undefined}
			minLength={props.minLength ?? undefined}
			maxLength={props.maxLength ?? undefined}
			autoFocus={props?.autoFocus ?? undefined}
			{...props}
			value={props.children}
		></textarea>
	);
}

export { TextArea, ITextAreaProps };
