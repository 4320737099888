// components/ChatModal.tsx
import React, { useEffect, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import { actions } from "redux/reducers/chatAssist/reducer";
import { ChatAssistSelectors } from "redux/selectors";
import { checkIsOnline } from "./utils";

import { ChatAssistHeader } from "./ChatAssistHeader";
import { TeamOfflineMessage } from "./TeamOfflineMessage";
import { ChatAssistHistory } from "./ChatAssistHistory";
import { ChatAssistTicketForm } from "./ChatAssistTicketForm";

import "./ChatAssistModal.scss";

/**
 * Renders the Modal part of the Chat Assistance module.
 */
export const ChatAssistModal: React.FC = () => {
	const dispatch = useDispatch();
	const chatAssist = useSelector(ChatAssistSelectors.get);
	const activeTicketInstance = useSelector(
		ChatAssistSelectors.getActiveTicket
	);

	const strings = useMemo(
		() => ({
			classNames: {
				overlay: "chat-assist-modal-overlay",
				closeButton: "close-button"
			},
			actions: {
				closeChat: "Fechar chat"
			}
		}),
		[]
	);

	const onToggleChat = (
		event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>
	) => {
		const targetElement = event.nativeEvent.target as Element;
		const overlayRef = document.querySelector(
			`div.${strings.classNames.overlay}`
		);
		const closeButtonRef = document.querySelector(
			`button.${strings.classNames.closeButton}`
		);

		if ([overlayRef, closeButtonRef].includes(targetElement)) {
			dispatch(actions.toggleChat());
		}
	};

	// Check online status when component mounts or operational hours change
	useEffect(() => {
		const checkStatus = () => {
			const online = checkIsOnline(chatAssist.operationalHours);
			dispatch(actions.updateOnlineStatus(online));
		};

		checkStatus();

		// Set up interval to check status periodically (e.g., every minute)
		const interval = setInterval(checkStatus, 60000);

		return () => clearInterval(interval);
	}, [dispatch, chatAssist.operationalHours]);

	useEffect(() => {
		return () => {
			// When unmounting, clears up the form
			dispatch(actions.reset());
		};
	}, []);

	if (!chatAssist.isOpen) return null;

	return (
		<div
			className={strings.classNames.overlay}
			onClickCapture={onToggleChat}
		>
			<div className={"chat-assist-modal-container"}>
				<button
					className={strings.classNames.closeButton}
					onClick={onToggleChat}
					aria-label={strings.actions.closeChat}
				>
					&times;
				</button>

				<ChatAssistHeader
					chatAssist={chatAssist}
					activeTicket={activeTicketInstance}
				/>

				<ChatAssistHistory />

				{!chatAssist.showHistory && (
					<>
						<TeamOfflineMessage chatAssist={chatAssist} />
						<ChatAssistTicketForm />
					</>
				)}
			</div>
		</div>
	);
};
