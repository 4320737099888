import { Routes } from "components/router/Routes";
import { ComponentPaths } from "components/router/ComponentPaths";
import { LazyLoad } from "components/router/LazyLoad";
import { RouteObject } from "react-router-dom";
import { AuthLayerEnabledModule } from "./AuthLayerEnabled.module";
import { AppModuleNames } from "./AppModules";

/**
 * The Order External module routes.
 *
 * @returns The module routes.
 */
export const OrderExternalModule: () => RouteObject[] = () => {
	return AuthLayerEnabledModule(AppModuleNames.orderExternal, [
		{
			path: Routes.ExternalOrderForm(),
			element: LazyLoad(ComponentPaths.ExternalOrder)
		},
		{
			path: Routes.InternalOrderForm,
			element: LazyLoad(ComponentPaths.InternalOrder)
		}
	]);
};
