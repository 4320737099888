import FirebaseFunctionsService from "services/firebase/functions/FirebaseFunctionsService";

/**
 * Used to Create an User Account, after customer validation has occurred.
 * This service uses a callable function with App Check enabled.
 */
class CreateUserAccountService extends FirebaseFunctionsService {
	constructor() {
		super("createUserAccount");
	}

	/**
	 * Creates a user account with the provided customer ID and password
	 * @param customerId The ID of the validated customer
	 * @param password The password for the new account
	 * @returns Promise with the result of the operation
	 */
	async createAccount(customerId: string, password: string) {
		return this.callService({
			customerId,
			password
		});
	}
}

export { CreateUserAccountService };
