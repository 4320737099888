import { ISliceList } from "interfaces/slices";
import { IChatAssist } from "./IChatAssist";
import { OperationalHours } from "./OperationalHours";
import { IClickUpTaskCommentsById } from "./clickUp/IClickUpTaskCommentsById";
import { IClickUpTask } from "./clickUp/IClickUpTask";

/**
 * The contract to customize Slice behaviors
 */
export interface IChatAssistSlice extends ISliceList<IChatAssist> {
	isOpen: boolean;
	submitting: boolean;
	error: string | null;
	success: boolean;
	data: {
		message: string;
	};
	operationalHours: OperationalHours;
	isOnline: boolean;
	ticketMessages: IClickUpTaskCommentsById;
	tickets: IClickUpTask[];
	loadingTickets: boolean;
	queriedTickets: boolean;
	activeTicket: null | string;
	showHistory: boolean;
}

/**
 * The initial state of the Chat Assist Slice.
 */
export const chatAssistInitialState: IChatAssistSlice = {
	editing: false,
	loading: false,
	submitting: false,
	queried: false,
	list: [],
	editingList: [],
	isOpen: false,
	error: null,
	success: false,
	data: {
		message: ""
	},
	operationalHours: {
		startHour: 9, // 9 AM
		endHour: 18, // 6 PM
		days: [1, 2, 3, 4, 5], // Monday-Friday
		timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
	},
	isOnline: false,
	ticketMessages: {},
	tickets: [],
	loadingTickets: false,
	queriedTickets: false,
	activeTicket: "",
	showHistory: false
};
