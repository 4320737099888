import React from "react";
import { Icon } from "./Icon";
import { IIconDerivateProps } from "./shared/interfaces";

/**
 * Fluent UI's Error Badge Icon component.
 *
 * @param props The properties of the component.
 * @returns JSX.Element
 */
export const ErrorBadgeIcon: React.FC<IIconDerivateProps> = (props) => {
	return <Icon name="ErrorBadge" {...props} />;
};
