import { Buffer } from "buffer";

const _locale = "pt-BR";
const _currency = {
	code: "BRL",
	symbol: "R$"
};
const _localeOptions: Intl.NumberFormatOptions = {
	style: "currency",
	currency: _currency.code
};

/**
 * Class for Formatters of the system, formatting data from each method.
 */
export class FormatServicesFixture {
	/**
	 * Formats a number to a currency value.
	 *
	 * @param value The number to be formatted.
	 * @param localeOpts [Optional] The locale options to be used.
	 * @returns The formatted currency value.
	 */
	currency(
		value: number,
		localeOpts: Intl.NumberFormatOptions = undefined
	): string {
		return value.toLocaleString(_locale, localeOpts);
	}
	/**
	 * Formats a value and adds a currency prefix to it.
	 *
	 * @param value The value to be formatted.
	 * @returns The formatted value with a currency prefix.
	 */
	currencyPrefix(value: number): string {
		return `${this.currency(value, _localeOptions)}`;
	}
	/**
	 * Formats a Date into a locale string.
	 *
	 * @param date The date to be formatted.
	 * @returns The formatted date.
	 */
	date(
		date: Date,
		localeOpts: Intl.DateTimeFormatOptions = undefined
	): string {
		const dateObject = new Date(date ?? Date.now());
		return dateObject.toLocaleDateString(_locale, localeOpts);
	}
	/**
	 * Formats a Date into a locale Date/time string.
	 *
	 * @param dateTime The date to be formatted.
	 * @returns The formatted date.
	 */
	dateTime(
		dateTime: Date,
		localeOpts: Intl.DateTimeFormatOptions = undefined
	): string {
		const dateObject = new Date(dateTime ?? Date.now());
		return `${this.date(dateObject, localeOpts)} ${this.time(
			dateObject,
			localeOpts
		)}`;
	}
	/**
	 * Formats a Date into a locale time string.
	 *
	 * @param dateTime The date to be formatted.
	 * @returns The formatted date.
	 */
	time(
		dateTime: Date,
		localeOpts: Intl.DateTimeFormatOptions = undefined
	): string {
		return (
			dateTime instanceof Date ? dateTime : new Date(dateTime)
		).toLocaleTimeString(_locale, localeOpts);
	}
	/**
	 * Formats a string|number to a decimal point-based locale string.
	 *
	 * @param value The value to be formatted.
	 * @param decimalPoints The number of decimal points to be formatted.
	 * @returns The formatted value.
	 */
	float(value: string | number, decimalPoints: number = 2): string {
		return !value
			? parseFloat("0").toFixed(decimalPoints)
			: typeof value === "number"
			? value.toFixed(decimalPoints)
			: parseFloat(value).toFixed(decimalPoints);
	}
	/**
	 * Formats a string|number to an ISO Time string.
	 *
	 * @param dateTime The date to be formatted.
	 * @returns The formatted date.
	 */
	timeISO(dateTime: string | Date): string {
		const isoDate =
			dateTime instanceof Date
				? dateTime?.toISOString()
				: new Date(dateTime).toISOString();
		const isoTime = isoDate.split("T")[1];

		return isoTime;
	}

	/**
	 * Encodes a string to Base64.
	 *
	 * @param value The string to be encoded.
	 * @returns The encoded string.
	 */
	encodeBase64(value: string): string {
		try {
			// Node's Buffer
			return Buffer.from(value).toString("base64");
		} catch (error) {
			if (process.env.NODE_ENV === "development") {
				console.error("Error encoding base64:", error);
			}
			return "";
		}
	}

	/**
	 * Decodes a Base64 string.
	 *
	 * @param value The string to be decoded.
	 * @returns The decoded string.
	 */
	decodeBase64(value: string): string {
		try {
			// Node's Buffer
			return Buffer.from(value, "base64").toString("utf-8");
		} catch (error) {
			if (process.env.NODE_ENV === "development") {
				console.error("Error decoding base64:", error);
			}
			return "";
		}
	}

	/**
	 * Joins a date and a time string.
	 *
	 * @param date The date to have a time set / joined
	 * @param timeString Time string shape sample: 00:00:00.00Z
	 * @returns A new date, with both date and time joint.
	 */
	joinDateAndTimeString(date: Date, timeString: string): Date {
		let _finalDate = date;

		if (timeString !== null && timeString !== "" && date instanceof Date) {
			_finalDate = new Date(
				`${_finalDate.toISOString().split("T")[0]}T${timeString}`
			);
		}

		return _finalDate;
	}
}

/**
 * Singleton instance of the FormatService exported.

 */
export const formatServices = new FormatServicesFixture();
