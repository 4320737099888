// components/ChatModal.tsx
import React, { useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import { actions } from "redux/reducers/chatAssist/reducer";
import { ChatAssistSelectors } from "redux/selectors";

import "./ChatAssistModal.scss";

/**
 * Renders the View History Button, part of the Chat Assist module.
 */
export const ChatAssistHistoryButton: React.FC = () => {
	const dispatch = useDispatch();
	const chatAssist = useSelector(ChatAssistSelectors.get);

	const strings = useMemo(
		() => ({
			titles: {
				viewHistory: "Ver o histórico de atendimentos na empresa"
			},
			actions: {
				viewHistory: `Ver Atendimentos`
			}
		}),
		[chatAssist.isOnline]
	);

	return (
		<>
			<button
				className="chat-history-view-button"
				onClick={() => {
					dispatch(actions.setShowHistory(true));
				}}
				title={strings.titles.viewHistory}
				aria-label={strings.titles.viewHistory}
			>
				{strings.actions.viewHistory}
				<span className="ball-flag">{chatAssist.tickets.length}</span>
			</button>
		</>
	);
};
