import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "redux/reducers/chatAssist/reducer";
import {
	ChatAssistSelectors,
	CompanySelectors,
	UserSelectors
} from "redux/selectors";
import { handleSubmitMessage, onGetTasksAndComments } from "./utils";

import { RootState } from "redux/reducers";
import { InlineTicketDetail } from "./InlineTicketDetail";
import { ChatMessageGroup } from "./ChatMessageGroup";
import { SendIcon } from "components/shared/icon";
import { TextArea } from "components/shared/input";
import { IconSize } from "components/shared/icon/shared/interfaces";

import "./ChatAssistModal.scss";

const _historyTimeIntervalMs = 45000;

export interface IChatAssistTicketDetails {}

/**
 *
 * @param props
 */
export const ChatAssistTicketDetails: React.FC<IChatAssistTicketDetails> = (
	props
) => {
	const chatEditorTextBoxId = "chat-editor-textbox-input";
	const dispatch = useDispatch();
	const chatAssist = useSelector(ChatAssistSelectors.get);
	const activeTicketInstance = useSelector(
		ChatAssistSelectors.getActiveTicket
	);
	const activeTicketMessages = useSelector((state: RootState) =>
		ChatAssistSelectors.getMessagesByTicketId(
			state,
			activeTicketInstance?.id ?? ""
		)
	);

	const canSubmit = useMemo(() => {
		const _result = chatAssist.data.message.trim();

		return _result !== null && _result !== "" && _result.length > 0;
	}, [chatAssist.data]);

	const onClearMessageField = async () => {
		const _editorBox: HTMLTextAreaElement = document.getElementById(
			chatEditorTextBoxId
		) as HTMLTextAreaElement;
		_editorBox.value = "";
		dispatch(actions.setMessage(""));
		await onGetTasksAndComments(true);
	};

	const onEnterCapture = async (event: React.KeyboardEvent) => {
		if (!canSubmit || event.key !== "Enter") return;

		handleSubmitMessage(event, onClearMessageField);
	};

	return (
		<div className="chat-assist-active-ticket">
			<h4 className="chat-assist-active-ticket-title">
				{activeTicketInstance.name}
			</h4>
			<div className="chat-assist-active-ticket-details">
				<InlineTicketDetail activeTicket={activeTicketInstance} />
				<div className="chat-assist-active-ticket-chat">
					<div className="chat-history-scrollable">
						{activeTicketMessages.length > 0 &&
							activeTicketMessages.map((ticketMessage) => {
								return (
									<ChatMessageGroup
										key={ticketMessage.id}
										ticketMessage={ticketMessage}
									/>
								);
							})}
					</div>
				</div>
				<div className="chat-assist-active-ticket-editor">
					<div className="chat-editor-textbox">
						<TextArea
							autoFocus
							disabled={chatAssist.submitting}
							id={chatEditorTextBoxId}
							onKeyDown={onEnterCapture}
							onKeyUp={(event) => {
								const _text = event.currentTarget.value;
								dispatch(actions.setMessage(_text));
							}}
						></TextArea>
					</div>
					<div className="chat-editor-actions">
						<button
							disabled={chatAssist.submitting || !canSubmit}
							className="chat-editor-submit-button"
							onKeyDown={onEnterCapture}
							onClick={(event) => {
								handleSubmitMessage(event, onClearMessageField);
							}}
						>
							<SendIcon size={IconSize.MD} />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
