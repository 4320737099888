import React, { useRef } from "react";
import { SidePanel } from "components/shared/panel/SidePanel";
import { appStateService } from "App";
import { useSelector } from "react-redux";
import { UserSelectors } from "redux/selectors";
import { useTranslateFn } from "hooks/i18n"; // Adjust the import path as needed
import { TabList } from "components/shared/tab";
import { UserProfileForm } from "./profile/UserProfileForm";
import { UserCompanyForm } from "./company/UserCompanyForm";

/**
 * Contract for the properties of the UserProfileForm component.
 */
interface IUserPreferencesSideGroupProps {
	/**
	 * Determines whether the modal is open or not.
	 */
	open: boolean;
}

/**
 * UserProfileForm Component, which displays the user's profile information.
 * It is also editable by enabling the mode.
 *
 * @param props IUserProfileFormProps
 * @returns React.FC<IUserProfileFormProps>
 */

const UserPreferencesSideGroup: React.FC<IUserPreferencesSideGroupProps> = (
	props
) => {
	const translate = useTranslateFn();
	const strings = {
		tabNames: {
			userProfile: translate(
				"userPreferencesSideGroup.tabTitles.userProfileForm"
			),
			userCompany: translate(
				"userPreferencesSideGroup.tabTitles.companyForm"
			)
		},
		formTitle: translate("userPreferencesSideGroup.title")
	};
	const userProfileData = useSelector(UserSelectors.selectUserProfile);
	const originalProfileData = useRef(userProfileData);
	const isEditing = useSelector(UserSelectors.selectUserProfileFormEditing);

	const onAfterSave = (closeForm: undefined | boolean) => {
		appStateService.user.setProfileFormEditing(false);

		if (closeForm && appStateService.user.getProfileFormOpen()) {
			appStateService.user.setProfileFormOpen(false);
		}
	};

	const handleSaveAndClose = () => {
		// TODO: Save User Profile changes

		// TODO: Save Company's changes

		onAfterSave(true);
	};

	const handleCancel = () => {
		// Restores the original data to the form, if anything changed.
		appStateService.user.setName(originalProfileData.current?.name ?? "");
		appStateService.user.setDisplayName(
			originalProfileData.current?.displayName ?? ""
		);
		// appStateService.user.setEmail(originalProfileData.current.mail);

		// Closes the form.
		appStateService.user.setProfileFormEditing(false);
		appStateService.user.setProfileFormOpen(false);
	};

	return (
		<SidePanel
			title={strings.formTitle}
			open={props.open}
			onSubmit={handleSaveAndClose}
			onCancel={handleCancel}
			isDirty={
				isEditing &&
				(userProfileData?.name !== originalProfileData.current?.name ||
					userProfileData?.displayName !==
						originalProfileData.current?.displayName)
			}
		>
			<TabList
				wrapTabs
				tabs={[
					{
						id: "userProfile",
						title: strings.tabNames.userProfile,
						children: (
							<UserProfileForm
								// handleSave={handleSaveChanges}
								onCancel={handleCancel}
								// onEdit={}
								onAfterSave={onAfterSave}
							/>
						)
					},
					{
						id: "userCompany",
						title: strings.tabNames.userCompany,
						children: <UserCompanyForm />
					}
				]}
			/>
		</SidePanel>
	);
};

export { UserPreferencesSideGroup, IUserPreferencesSideGroupProps };
