import { Timestamp } from "firebase/firestore";
import { IFirebaseItem } from "interfaces/index";
import { ISliceList } from "interfaces/slices";
import { AppUserPlan, PaymentMethod, AppReferrer, PaymentStatus } from "enums";

/**
 * Customer basic interface.
 */
interface ICustomer extends IFirebaseItem {
	email: string;
	name: string;
	birthday: string;
	accountPlan: string | AppUserPlan;
	leadId: string;
	payment: {
		status: PaymentStatus;
		method: PaymentMethod;
		timestamp: Timestamp;
	};
	validation: {
		code: string;
		timestamp: Timestamp;
		used: boolean;
		usedOn: Timestamp | null;
	};
	referrer: AppReferrer;
}

/**
 * Customer Redux slice interface.
 */
interface ICustomerSlice extends ISliceList<ICustomer> {}

/**
 * Customer factory class.
 */
class Customer implements ICustomer {
	id: string = "";
	email: string = "";
	name: string = "";
	birthday: string = "";
	accountPlan: string | AppUserPlan = AppUserPlan.standard;
	leadId: string = "";
	payment: {
		status: PaymentStatus;
		method: PaymentMethod;
		timestamp: Timestamp;
	} = {
		status: PaymentStatus.pending,
		method: PaymentMethod.pix,
		timestamp: Timestamp.now()
	};
	validation: {
		code: string;
		timestamp: Timestamp;
		used: boolean;
		usedOn: Timestamp | null;
	} = {
		code: "",
		timestamp: Timestamp.now(),
		used: false,
		usedOn: null
	};
	referrer: AppReferrer = AppReferrer.website;
	deleted: boolean = false;
	timestamp: Timestamp;
	lastChangedBy?: undefined | string = "";

	constructor(data?: undefined | ICustomer | Partial<ICustomer>) {
		this.id = data?.id ?? "";
		this.email = data?.email ?? "";
		this.name = data?.name ?? "";
		this.birthday = data?.birthday ?? "";
		this.accountPlan = data?.accountPlan ?? "";
		this.leadId = data?.leadId ?? "";

		this.payment = {
			status: data?.payment?.status ?? PaymentStatus.pending,
			method: data?.payment?.method ?? PaymentMethod.pix,
			timestamp: data?.payment?.timestamp ?? Timestamp.now()
		};

		this.validation = {
			code: data?.validation?.code ?? "",
			timestamp: data?.validation?.timestamp ?? Timestamp.now(),
			used: data?.validation?.used ?? false,
			usedOn: data?.validation?.usedOn ?? null
		};

		this.referrer = data?.referrer ?? AppReferrer.website;

		this.deleted = data?.deleted ?? false;
		this.timestamp = data?.timestamp ?? Timestamp.now();
		this.lastChangedBy = data?.lastChangedBy ?? "";
	}
}

const customersInitialState: ICustomerSlice = {
	list: [],
	loading: false,
	queried: false,
	editing: false,
	editingList: []
};

export { customersInitialState, ICustomer, ICustomerSlice, Customer };
