import React from "react";

import { IComboBox, ITimePickerProps, TimePicker } from "@fluentui/react";

import "components/shared/timePicker/TimePicker.scss";

/**
 * TimePickerGroup properties
 */
export interface ITimePickerGroupProps extends ITimePickerProps {
	label?: string;
	value: Date | null;
	id: string;
	disabled?: boolean;
	dateTime?: boolean;
	onChange?: (event: React.FormEvent<IComboBox>, time: Date) => void;
	className?: string;
	placeholder?: string;
	useHour12?: boolean;
	required?: boolean;
}

/**
 * Re-usable DatePicker component, which wraps itself in a group
 *
 * @param props
 * @returns
 */
export const TimePickerGroup: React.FC<ITimePickerGroupProps> = (props) => {
	// TODO: Extract texts to the translator service
	const texts = {
		idNotInformed: "ID-não-informado",
		labelNotInformed: "Título-não-informado"
	};

	const resolvedLabel = props.label ?? texts.labelNotInformed;
	const resolvedId = props.id ?? texts.idNotInformed;

	return (
		<div className="beebase-time-picker">
			<label htmlFor={resolvedId}>{resolvedLabel}</label>
			<TimePicker
				id={resolvedId}
				value={props.value}
				useHour12={props?.useHour12 ?? false}
				disabled={props?.disabled ?? undefined}
				className={props?.className ?? undefined}
				onChange={props.onChange}
				title={resolvedLabel}
				required={props.required}
			/>
		</div>
	);
};
