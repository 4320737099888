// utils/operationalHoursChecker.ts

import { translate } from "hooks/i18n";
import { OperationalHours } from "models/chatAssist";

export const checkIsOnline = (operationalHours: OperationalHours): boolean => {
	const now = new Date();
	const userTimeZone =
		operationalHours.timeZone ||
		Intl.DateTimeFormat().resolvedOptions().timeZone;

	// Convert to the configured timezone
	const options: Intl.DateTimeFormatOptions = {
		hour: "numeric",
		hour12: false,
		timeZone: userTimeZone
	};

	const currentHour = parseInt(
		new Intl.DateTimeFormat("en-US", options).format(now),
		10
	);
	const currentDay = now.toLocaleDateString("en-US", {
		timeZone: userTimeZone,
		weekday: "long"
	});
	const currentDayIndex = [
		"sunday",
		"monday",
		"tuesday",
		"wednesday",
		"thursday",
		"friday",
		"saturday"
	].indexOf(currentDay.toLowerCase());

	// Check if current day is in operational days
	const isOperationalDay = operationalHours.days.includes(currentDayIndex);

	// Check if current hour is within operational hours
	const isOperationalHour =
		currentHour >= operationalHours.startHour &&
		currentHour < operationalHours.endHour;

	return isOperationalDay && isOperationalHour;
	// return false;
};

export const formatOperationalHours = (
	operationalHours: OperationalHours
): string => {
	const days = [
		translate("calendar.weekdays.short.sunday"),
		translate("calendar.weekdays.short.monday"),
		translate("calendar.weekdays.short.tuesday"),
		translate("calendar.weekdays.short.wednesday"),
		translate("calendar.weekdays.short.thursday"),
		translate("calendar.weekdays.short.friday"),
		translate("calendar.weekdays.short.saturday")
	];

	if (!operationalHours) return "";

	const sortedActiveDays = [...operationalHours.days];

	const activeDays = sortedActiveDays
		.map((dayIndex) => days[dayIndex])
		.join(", ");

	const startAmPm = operationalHours.startHour >= 12 ? "PM" : "AM";
	const endAmPm = operationalHours.endHour >= 12 ? "PM" : "AM";
	const startHour12 = operationalHours.startHour % 12 || 12;
	const endHour12 = operationalHours.endHour % 12 || 12;

	const oprTimeZone = translate(
		`timezone.${operationalHours.timeZone.toLowerCase()}`
	);

	return `${activeDays} ${startHour12}${startAmPm}-${endHour12}${endAmPm} (${oprTimeZone})`;
};
