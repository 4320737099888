import FirebaseFunctionsService from "services/firebase/functions/FirebaseFunctionsService";

/**
 * Creates a reference to access the functions/UserSignUp as an API Service.
 */
class UserSignUpService extends FirebaseFunctionsService {
	constructor() {
		super("userSignUp");
	}

	signUp = async (data: any) => {
		return await this.callService(data, false);
	};
}

export { UserSignUpService };
