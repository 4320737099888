import {
	Unsubscribe,
	User,
	browserLocalPersistence,
	getAuth,
	onAuthStateChanged,
	setPersistence,
	signInWithEmailAndPassword,
	sendPasswordResetEmail,
	signOut,
	updatePassword,
	updateProfile,
	applyActionCode,
	UserCredential
} from "firebase/auth";

import { appStateService, store } from "App";
import { actions } from "redux/reducers/user/userProfileReducer";
import { UserSelectors } from "redux/selectors";

import { IUserProfile, IUserProfileFull, IUserProfileSlice } from "models";
import { translate } from "hooks/i18n";
import { StoreType } from "types";
import { Timestamp } from "firebase/firestore";
import { IAuthService } from "interfaces/services";
import { configAppModules } from "components/modules";

/**
 * Decides if the user is authenticated or not, and loads the state accordingly.
 *
 * @param user The user data
 * @param authState The authentication state
 */
async function _decideAndLoadAuthState(
	user: User,
	authState: IUserProfileSlice,
	onAuthenticated: (_user: User, _state: IUserProfileSlice) => Promise<void>,
	onAnonymous: () => void,
	skipResetOnAnonymous: boolean = false
): Promise<boolean> {
	let result: boolean = false;

	if (!!user) {
		// User is signed in, see docs for a list of available properties
		// https://firebase.google.com/docs/reference/js/firebase.User
		if (!authState?.authenticated || authState?.profile?.id !== user.uid) {
			if (typeof onAuthenticated === "function")
				await onAuthenticated(user, authState);
		}

		result = true;
	} else if (authState?.authenticated) {
		if (!skipResetOnAnonymous && typeof onAnonymous === "function")
			onAnonymous();
	}

	return result;
}

/**
 * Class for managing the authentication process as a service.
 */
class AuthService implements IAuthService {
	_store: StoreType = null;

	constructor(reduxStore: StoreType) {
		this._store = reduxStore;
	}

	/**
	 * Authenticates the user with the given credentials.
	 * At the moment, implements a call to firebase/auth.
	 *
	 * @param user The user name
	 * @param pwd The user password
	 *
	 * @returns A promise with the authentication result as boolean.
	 */
	async authenticate(user: string, pwd: string): Promise<boolean> {
		let result = false;

		if (!user || !pwd) {
			appStateService.appManager.showWarning(
				"page.login.form.submit.error"
			);
			return result;
		}

		this._store.dispatch(actions.setAuthenticating(true));
		appStateService.appManager.setLoading(true);

		const auth = getAuth();

		await setPersistence(auth, browserLocalPersistence).then(
			async () =>
				await signInWithEmailAndPassword(auth, user, pwd)
					.then(async (userCredential) => {
						// Signed in, prepares the available data for the state layer
						const userProfileData: IUserProfileFull =
							this.populateUserFromCredential(userCredential);

						// TODO: Implement the company selection service and invoke

						// // Re-assigning the firestore instance to the appStateService
						// appStateService.restartService("firestore", <
						// 	Firestore
						// >() => {
						// 	return getFirestore() as Firestore;
						// });
						// appStateService.firestore = getFirestore();

						// Tries to locate the user in the database
						// If not located, updates the database to include the object
						await appStateService.user.checkUserExistsByID(
							userProfileData.id,
							this.onCheckUserExistsOk,
							this.onCheckUserExistsError
						);

						result = true;
					})
					.catch((error) => {
						// TODO: HANDLE AUTH ERROR PROPERLY

						this.handleAuthError(error, (message) => {
							store.dispatch(actions.setAuthenticating(false));
							// appStateService.appManager.showMessage(message);
						});

						// TODO: log error, using external service
					})
		);

		appStateService.appManager.setLoading(false);

		return result;
	}

	/**
	 * Handles the Error scenario, for when checking user exist or not.
	 *
	 * @param error
	 */
	private async onCheckUserExistsError(error): Promise<void> {
		store.dispatch(actions.setUser(null));
		store.dispatch(actions.setAuthenticated(false));
		store.dispatch(actions.setAuthenticating(false));
	}

	/**
	 * Handles the case of the user being found in the DB,
	 * Then the check should populate more data.
	 *
	 * @param userProfile The user profile to check
	 */
	private async onCheckUserExistsOk(
		userProfile: null | IUserProfile
	): Promise<void> {
		if (!userProfile) {
			// Anything else to handle this case?
			return;
		}

		// User was found, updates the local auth state
		store.dispatch(
			actions.setUser({
				...(userProfile as IUserProfileFull)
			})
		);

		// Also, initiates the request for user companies' data and wait for it to complete
		const companies = await appStateService.company.service
			.get()
			.getUserCompanies();

		// Ensure the active company is set if not already
		if (companies.length > 0 && !userProfile.activeCompany) {
			await appStateService.user.setActiveCompany(companies[0].id);
		}

		// Updates the authenticated state
		store.dispatch(actions.setAuthenticated(true));
		store.dispatch(actions.setAuthenticating(false));

		// Force a reload of the company profile
		appStateService.appManager.setProfileLoaded(false);
		appStateService.appManager.setProfileLoading(false);

		// Gets the modules enabled for the user account
		configAppModules();
	}

	/**
	 * Populates the user profile Data from a given user credential,
	 * From Firebase Auth.
	 *
	 * @param credential
	 */
	private populateUserFromCredential(
		credential: UserCredential
	): IUserProfileFull {
		const user = credential?.user ?? null;

		if (!user) {
			return null;
		}

		let userProfileData: IUserProfileFull = {
			id: user.uid,
			name: user?.displayName ?? user?.email?.split("@")[0] ?? "",
			displayName: user?.displayName ?? "",
			mail: user?.email ?? "",
			pictureURL: user?.photoURL ?? "",
			timestamp: Timestamp.now(),
			deleted: false,
			activeCompany: "",
			companies: [],
			roles: {
				admin: false
			},
			customerId: "",
			firstAccess: true
		};

		return userProfileData;
	}

	/**
	 * Logs off the current user.
	 * At the moment, implements a call to firebase/auth.
	 */
	async logOff(): Promise<void> {
		const auth = getAuth();
		await signOut(auth)
			.then(() => {
				// Sign-out successful.
				AuthService.resetStatic();
			})
			.catch((error) => {
				// An error happened.
				appStateService.appManager.showError(String(error));
			});
	}

	reset(): void {
		this._store.dispatch(actions.reset());
	}

	/**
	 * Resets the local user state.
	 */
	static resetStatic(): void {
		store.dispatch(actions.reset());
	}

	/**
	 * Monitors if there is a local authenticated user action.
	 * At the moment, implements a call to firebase/auth.
	 *
	 * @returns A promise with the verification result as boolean.
	 */
	monitorAuthState(): Unsubscribe {
		let unsubscribe: Unsubscribe;
		const self = this;
		const auth = getAuth();
		const persistedState = UserSelectors.select(store.getState());

		// TODO: Fix the repetitive calls to this function

		// Creates an unsubscriber which will be returned to the caller
		return onAuthStateChanged(
			auth,
			(user) => {
				// Decides the auth state and treats it
				try {
					_decideAndLoadAuthState(
						user,
						persistedState,
						async (_user) => {
							await AuthService.setLocalUserStatic(
								_user,
								persistedState
							);

							// After authentication is complete, ensure company data is loaded
							if (_user) {
								try {
									// Ensure user companies are loaded
									const companies =
										await appStateService.company.service
											.get()
											.getUserCompanies();

									// Ensure the active company is set if not already
									const activeCompany =
										UserSelectors.selectUserProfileActiveCompany(
											store.getState()
										);
									if (
										companies.length > 0 &&
										!activeCompany
									) {
										await appStateService.user.setActiveCompany(
											companies[0].id
										);
									}

									// Force a reload of the company profile
									appStateService.appManager.setProfileLoaded(
										false
									);
									appStateService.appManager.setProfileLoading(
										false
									);
								} catch (error) {
									console.error(
										"Error loading company data:",
										error
									);
									// Ensure loading states are reset on error
									self.resetProfileLoaders();
								}
							} else {
								// Reset loading states for unauthenticated users
								self.resetProfileLoaders();
							}
						},
						() => {
							// Reset all states when user is not authenticated
							AuthService.resetStatic();
							self.resetProfileLoaders();
						},
						false
					);

					// IMPORTANT: Always set authenticating to false once we have result
					store.dispatch(actions.setAuthenticating(false));
				} catch (error) {
					console.error("Error in auth state monitor:", error);
					// Ensure loading states are reset on error
					self.resetProfileLoaders();
					store.dispatch(actions.setAuthenticating(false));
				}
			},
			(error) => {
				// Clean-up authenticating state and show error
				console.error("Auth state monitor error:", error);
				appStateService.appManager.showError(String(error));
				store.dispatch(actions.setAuthenticating(false));
				self.resetProfileLoaders();
			}
		);
	}

	/**
	 * Verifies if there is a local authenticated user.
	 * At the moment, implements a call to firebase/auth.
	 *
	 * @returns A promise with the verification result as boolean.
	 */
	async verifyLocalAuth(): Promise<boolean> {
		let result: boolean = false;

		// TODO: Verify in localstorage if there is a user logged in
		const auth = getAuth();
		const user = auth.currentUser;
		const persistedState = this.getFromLocalState();

		result = await this.decideAndLoadAuthState(user, persistedState, true);

		// If user is authenticated, ensure company data is loaded
		if (result && user) {
			try {
				// Ensure user companies are loaded
				const companies = await appStateService.company.service
					.get()
					.getUserCompanies();

				// Ensure the active company is set if not already
				const activeCompany =
					UserSelectors.selectUserProfileActiveCompany(
						store.getState()
					);
				if (companies.length > 0 && !activeCompany) {
					await appStateService.user.setActiveCompany(
						companies[0].id
					);
				}

				// Force a reload of the company profile if needed
				if (!appStateService.appManager.getCompanyProfileLoaded()) {
					appStateService.appManager.setProfileLoaded(false);
					appStateService.appManager.setProfileLoading(false);
				}
			} catch (error) {
				console.error("Error loading company data:", error);
			}
		}

		// IMPORTANT: Always make sure authenticating is set to false after verification
		store.dispatch(actions.setAuthenticating(false));

		return result;
	}

	/**
	 * Decides if the user is authenticated or not, and loads the state accordingly.
	 *
	 * @param user The user data
	 * @param authState The authentication state
	 */
	async decideAndLoadAuthState(
		user: User,
		authState: IUserProfileSlice,
		skipResetOnAnonymous: boolean = false
	): Promise<boolean> {
		const result = await _decideAndLoadAuthState(
			user,
			authState,
			async (user) =>
				await AuthService.setLocalUserStatic(user, authState),
			() => {
				AuthService.resetStatic();
			},
			skipResetOnAnonymous
		);

		return result;
	}

	/**
	 * Requests a password reset email.
	 *
	 * @param email The user email
	 * @returns
	 */
	async requestPasswordReset(email: string): Promise<boolean> {
		let result: boolean = false;
		const auth = getAuth();
		await sendPasswordResetEmail(auth, email)
			.then(() => {
				result = true;
				// Password reset email sent!
				appStateService.appManager.showSuccess(
					"page.login.form.passwordResetEmailSent"
				);
			})
			.catch((error) => {
				// Shows error message to end-user
				this.handleAuthError(error);
			});

		return result;
	}

	/**
	 * Gets the local user state.
	 *
	 * @returns The user data
	 */
	getFromLocalState(): IUserProfileSlice {
		const persistedState = UserSelectors.select(store.getState());

		// if (!persistedState?.authenticated) return null;

		return persistedState;
	}

	// /**
	//  * Sets the local user state.
	//  *
	//  * @param user The user data
	//  */
	// private setLocalUser(user: User): void {
	// 	return AuthService.setLocalUserStatic(user);
	// }

	/**
	 * Sets the local user state.
	 *
	 * @param user The user data
	 */
	private static async setLocalUserStatic(
		user: User,
		rehydratingState?: undefined | IUserProfileSlice
	): Promise<void> {
		const userProfileData: IUserProfileFull = !user
			? null
			: {
					id: user?.uid ?? rehydratingState?.profile?.id ?? "",
					name:
						user?.displayName ??
						rehydratingState?.profile?.name ??
						user?.email?.split("@")?.[0] ??
						"",
					displayName:
						user?.displayName ??
						rehydratingState?.profile?.displayName ??
						"",
					mail: user?.email ?? rehydratingState?.profile?.mail ?? "",
					pictureURL:
						user?.photoURL ??
						rehydratingState?.profile?.pictureURL ??
						"",
					timestamp:
						rehydratingState?.profile?.timestamp ?? Timestamp.now(),
					deleted: rehydratingState?.profile?.deleted ?? false,
					activeCompany:
						rehydratingState?.profile?.activeCompany ?? "",
					roles: rehydratingState?.profile?.roles ?? {
						admin: false
					},
					companies: rehydratingState?.profile?.companies ?? [],
					customerId: rehydratingState?.profile?.customerId ?? "",
					firstAccess: rehydratingState?.profile?.firstAccess ?? true
			  };

		const userHasId = userProfileData?.id !== "";

		// sets the authenticated user
		if (userHasId) {
			store.dispatch(actions.setUser(userProfileData));
		}

		// IMPORTANT: Always ensure authenticated, and authenticating flags are set accordingly
		store.dispatch(actions.setAuthenticated(userHasId));
		store.dispatch(actions.setAuthenticating(false));

		// If user is authenticated, ensure company data is loaded
		if (userProfileData.id !== "") {
			try {
				// Ensure user companies are loaded
				await appStateService.company.service.get().getUserCompanies();

				// Force a reload of the company profile if needed
				if (!appStateService.appManager.getCompanyProfileLoaded()) {
					appStateService.appManager.setProfileLoaded(false);
					appStateService.appManager.setProfileLoading(false);
				}
			} catch (error) {
				console.error("Error loading company data:", error);
			}
		}
	}

	/**
	 *
	 * @param user
	 * @param onLoading
	 * @param onLoaded
	 */
	login(
		user,
		onLoading: () => Promise<void>,
		onLoaded: () => Promise<void>
	): void {
		// Only authenticated once, since there are control flags now
		// If the process is loading (started), won't enter the if.
		// Or if the process is loaded (concluded), won't enter the if.
		// Or if the authentication was initialized, won't enter the if.

		if (UserSelectors.selectAuthenticating(this._store.getState())) {
		}

		if (typeof onLoading === "function") Promise.resolve(onLoading());

		appStateService.user.setUser(user);

		// not async
		appStateService.user.setIsAdmin(user.isAdmin);

		if (typeof onLoaded === "function") Promise.resolve(onLoaded());
	}

	setUser(user: IUserProfile): void {
		store.dispatch(actions.setUser(user as IUserProfileFull));
	}

	/**
	 * Sets the authenticating state in the Redux store.
	 *
	 * @param authenticating Whether the app is currently authenticating
	 */
	setAuthenticating(authenticating: boolean): void {
		store.dispatch(actions.setAuthenticating(authenticating));
	}

	setAuthenticated(authenticated: boolean): void {
		this._store.dispatch(actions.setAuthenticated(authenticated));
	}

	handleAuthError(
		error: any,
		onMessageReady: (message: string) => void = undefined
	): void {
		const errorCode = `${error.code}`;
		let errorMessage = error.message;

		// Switches between Firebase Auth error codes
		// and shows the corresponding message
		switch (errorCode) {
			case "auth/wrong-password":
				errorMessage = translate("page.login.form.submit.error");
				break;
			case "auth/user-not-found":
				errorMessage = translate(
					"page.login.form.submit.error.mailNotFound"
				);
				break;
			case "auth/network-request-failed":
				errorMessage = translate(
					"page.login.form.submit.error.offline"
				);
				break;
			case "auth/missing-email":
				errorMessage = translate("page.login.form.empty.mail");
				break;
			case "auth/invalid-email":
				errorMessage = translate(
					"page.login.form.submit.error.invalidMail"
				);
				break;

			case "400":
				errorMessage = translate("page.login.form.submit.error");
				break;
			default:
				break;
		}

		// Invokes the onMessageReady (optionally), then shows an error message to end-user
		if (typeof onMessageReady === "function") onMessageReady(errorMessage);
		appStateService.appManager.showWarning(errorMessage);
	}

	/**
	 * Signs out the current user.
	 */
	async signOut(): Promise<void> {
		try {
			const auth = getAuth();
			await auth.signOut();
			this._store.dispatch(actions.setUser(null));
			this._store.dispatch(actions.setAuthenticated(false));
		} catch (error) {
			appStateService.appManager.showErrorToast(
				translate("page.login.form.submit.error")
			);
		}
	}

	/**
	 * Updates the user's password.
	 *
	 * @param currentPassword The current password
	 * @param newPassword The new password
	 * @returns A promise with the update result as boolean
	 */
	async updatePassword(
		currentPassword: string,
		newPassword: string
	): Promise<boolean> {
		try {
			const auth = getAuth();
			const user = auth.currentUser;
			if (!user) {
				throw new Error("No user is currently signed in");
			}

			// Re-authenticate user before password change
			await signInWithEmailAndPassword(auth, user.email, currentPassword);
			await updatePassword(user, newPassword);
			return true;
		} catch (error) {
			appStateService.appManager.showErrorToast(
				translate("page.profile.password.update.error")
			);
			return false;
		}
	}

	/**
	 * Updates the user's profile information.
	 *
	 * @param displayName The new display name
	 * @param photoURL The new photo URL
	 * @returns A promise with the update result as boolean
	 */
	async updateProfile(
		displayName: string,
		photoURL?: string
	): Promise<boolean> {
		try {
			const auth = getAuth();
			const user = auth.currentUser;
			if (!user) {
				throw new Error("No user is currently signed in");
			}

			// Update Firebase Auth profile
			await updateProfile(user, {
				displayName,
				photoURL: photoURL || null
			});

			// Get current user data from Redux store
			const currentUserData = UserSelectors.selectUserProfile(
				this._store.getState()
			);
			if (!currentUserData) {
				throw new Error("User data not found in state");
			}

			// Update Firestore profile
			await appStateService.user.updateItem({
				...currentUserData,
				displayName,
				pictureURL: photoURL || null
			});

			return true;
		} catch (error) {
			appStateService.appManager.showErrorToast(
				translate("page.profile.update.error")
			);
			return false;
		}
	}

	/**
	 * Verifies the user's email.
	 *
	 * @param code The verification code
	 * @returns A promise with the verification result as boolean
	 */
	async verifyEmail(code: string): Promise<boolean> {
		try {
			const auth = getAuth();
			const user = auth.currentUser;
			if (!user) {
				throw new Error("No user is currently signed in");
			}

			await applyActionCode(auth, code);
			return true;
		} catch (error) {
			appStateService.appManager.showErrorToast(
				translate("page.profile.email.verify.error")
			);
			return false;
		}
	}

	/**
	 * Deletes the current user's account.
	 *
	 * @returns A promise with the deletion result as boolean
	 */
	async deleteAccount(): Promise<boolean> {
		try {
			const auth = getAuth();
			const user = auth.currentUser;
			if (!user) {
				throw new Error("No user is currently signed in");
			}

			// Delete user data from Firestore
			await appStateService.user.deleteItem(user.uid);

			// Delete Firebase Auth account
			await user.delete();

			// Clear state
			this._store.dispatch(actions.setUser(null));
			this._store.dispatch(actions.setAuthenticated(false));

			return true;
		} catch (error) {
			appStateService.appManager.showErrorToast(
				translate("page.profile.delete.error")
			);
			return false;
		}
	}

	/**
	 * Clears the authentication state.
	 */
	clearState(): void {
		this._store.dispatch(actions.setUser(null));
		this._store.dispatch(actions.setAuthenticated(false));
		this._store.dispatch(actions.setAuthenticating(false));

		// Clear local storage
		localStorage.removeItem("user");
		localStorage.removeItem("lastCompany");
	}

	/**
	 * Resets the loaders for unauthenticated users.
	 */
	resetProfileLoaders(): void {
		// Reset loading states for unauthenticated users
		appStateService.appManager.setProfileLoaded(false);
		appStateService.appManager.setProfileLoading(false);
		appStateService.appManager.setLoading(false);
	}
}

const AuthServiceInstance = new AuthService(store);

export { AuthService, AuthServiceInstance };
