import {
	QueryFieldFilterConstraint,
	WhereFilterOp,
	query,
	where,
	addDoc,
	collection,
	doc,
	getDocs,
	setDoc,
	onSnapshot,
	Timestamp,
	QueryCompositeFilterConstraint,
	and,
	Firestore
} from "firebase/firestore";
import "firebase/firestore";
import { Unsubscribe } from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { FirebaseOperators, PropQueryCriteria } from "./helpers";
import { IFirebaseItem } from "interfaces/index";
import { ErrorServiceInstance } from "services/error/errorService";

// Interface for error handling service
interface IErrorHandler {
	handleError(error: Error, message: string): void;
}

/**
 * Class for managing the basics of any Firebase Firestore collection's data.
 */
class FirebaseServiceHandler<T extends IFirebaseItem> {
	private _deletedPropName: string = "deleted";
	private _collectionName: string;
	private _firestore: Firestore;
	private _errorHandler: IErrorHandler | null;

	constructor(
		collectionName: string,
		firestore: Firestore,
		errorHandler: IErrorHandler | null = null
	) {
		this._collectionName = collectionName;
		this._firestore = firestore;
		this._errorHandler = errorHandler;
	}

	/**
	 * Handles the error from Firebase commands.
	 *
	 * @param error The error to handle
	 *
	 * @returns The error message itself, after dealing with it.
	 */
	handleError(error: string | FirebaseError): string | FirebaseError {
		const errorMessage = "Firebase Error: " + error;
		if (process.env.NODE_ENV === "development") {
			console.log(errorMessage);
		}

		if (this._errorHandler) {
			this._errorHandler.handleError(
				new Error(JSON.stringify(error)),
				errorMessage
			);
		}

		return error;
	}

	/**
	 * Returns all the items in the collection,
	 * Not including the deleted ones.
	 *
	 * @returns A promise with the items.
	 */
	async getItems(): Promise<T[]> {
		const items: T[] = [];
		const baseQuery = this.getBaseFilterQuery();

		const querySnapshot = await getDocs(
			query(collection(this._firestore, this._collectionName), baseQuery)
		).catch((error) => {
			this.handleError(error);
		});

		if (!querySnapshot || !querySnapshot.docs) return items;

		querySnapshot.docs.forEach((doc) => {
			if (doc.exists()) {
				const identifiedData: T = doc.data() as T;

				// Rectify the use of ID, by the Firebase ID itself
				identifiedData.id =
					!identifiedData.id || identifiedData.id === ""
						? doc.id
						: identifiedData.id;

				items.push(identifiedData);
			}
		});

		return items;
	}

	/**
	 * Returns all the items in the collection,
	 * OnSnapshot of the collection (on DB changed).
	 * Not including the deleted ones.
	 *
	 * @returns A promise with the items.
	 */
	getItemsLive(
		onReady: (items: T[]) => void,
		filters?: undefined | PropQueryCriteria[][]
	): Unsubscribe {
		const baseQuery = [this.getBaseFilterQuery()];

		if (filters && filters.length > 0) {
			filters.forEach((filter, index) => {
				baseQuery.push(
					where(filter[index][0], filter[index][1], filter[index][2])
				);
			});
		}

		const querySnapshot = onSnapshot(
			query(
				collection(this._firestore, this._collectionName),
				...baseQuery
			),
			(result) => {
				const items: T[] = [];

				if (!result || !result.docs) return items;

				result.docs.forEach((doc) => {
					if (doc.exists()) {
						const identifiedData: T = doc.data() as T;

						// Rectify the use of ID, by the Firebase ID itself
						if (!identifiedData.id || identifiedData.id === "") {
							identifiedData.id = doc.id;
						}

						items.push(identifiedData);
					}
				});

				onReady(items);
			},
			(error) => {
				this.handleError(error);
			}
		);

		return querySnapshot;
	}

	/**
	 * Returns the items that are not marked as deleted.
	 *
	 * @param prop The prop name to use for filtering
	 * @param value The value to use for filtering
	 * @param operator [Optional] The operator to use for filtering
	 *
	 * @returns A promise with the items that match the filter.
	 */
	async queryItemsByProp(
		prop: string,
		value: string,
		operator: WhereFilterOp = FirebaseOperators.equals
	): Promise<T[]> {
		let results: T[] = [];

		try {
			const queryInstruction = query(
				collection(this._firestore, this._collectionName),
				where(prop, operator, value),
				this.getBaseFilterQuery()
			);

			const querySnapshot = await getDocs(queryInstruction).catch(
				(error) => {
					this.handleError(error);
				}
			);

			if (!querySnapshot || !querySnapshot.docs) return results;

			querySnapshot.docs.forEach((doc) => {
				if (doc.exists() && String(doc.data()[prop]).includes(value)) {
					results.push(doc.data() as T);
				}
			});

			return results;
		} catch (error) {
			this.handleError(error);
			return [];
		}
	}

	/**
	 * Returns the items that are not marked as deleted.
	 *
	 * @param queryParams The query params to use for filtering
	 */
	async queryItems(
		queryParams: PropQueryCriteria[] | QueryCompositeFilterConstraint[]
	): Promise<T[]> {
		let results: T[] = [];

		try {
			let queryInstruction;

			if (queryParams.length > 0) {
				if (queryParams[0] instanceof QueryCompositeFilterConstraint) {
					// For composite constraints, create a query with both the base filter and composite constraints
					queryInstruction = query(
						collection(this._firestore, this._collectionName),
						and(
							this.getBaseFilterQuery(),
							...(queryParams as QueryCompositeFilterConstraint[])
						)
					);
				} else {
					// For PropQueryCriteria, convert to where clauses and combine with base filter
					const whereClauses = (
						queryParams as PropQueryCriteria[]
					).map((param) => where(param[0], param[1], param[2]));
					queryInstruction = query(
						collection(this._firestore, this._collectionName),
						this.getBaseFilterQuery(),
						...whereClauses
					);
				}
			} else {
				// If no additional constraints, just use the base filter
				queryInstruction = query(
					collection(this._firestore, this._collectionName),
					this.getBaseFilterQuery()
				);
			}

			const querySnapshot = await getDocs(queryInstruction);

			if (!querySnapshot || !querySnapshot.docs) return results;

			querySnapshot.docs.forEach((doc) => {
				if (doc.exists()) {
					results.push(doc.data() as T);
				}
			});
		} catch (error) {
			this.handleError(error);
			return [];
		}

		return results;
	}

	/**
	 * Returns the item with the given id.
	 *
	 * @param id The id of the item to return
	 * @returns A promise with the item or null if not found.
	 */
	async getItemById(id: string): Promise<T | null> {
		const items = await this.queryItemsByProp("id", id);
		return items.find((item) => item.id === id) || null;
	}

	/**
	 * Creates a new item in the DB, in the collection specified in this instance.
	 *
	 * @param item The item to create
	 * @returns A promise with the result of the operation as boolean.
	 */
	async createItem(
		item: T,
		onCreated: (
			item: T,
			isCreation?: undefined | boolean
		) => Promise<void> | void = undefined
	): Promise<boolean> {
		try {
			const userId: string = this.getUserId();
			const itemCopy: T = { ...item };

			const newDoc = await addDoc(
				collection(this._firestore, this._collectionName),
				itemCopy
			);

			// Sets the item to with the backfill generated ID,
			// await this.updateItem({ id: result.id });
			await setDoc(
				doc(this._firestore, this._collectionName, newDoc.id),
				{ id: newDoc.id, lastChangedBy: userId, deleted: false },
				{ merge: true }
			);

			// Copies the ID generated back to the instance
			itemCopy.id = newDoc.id;

			// If any callback was specified for after creation, calls it
			if (typeof onCreated === "function") {
				onCreated({ ...itemCopy, id: newDoc.id }, true);
			}

			return true;
		} catch (ex) {
			// Calls the AppManagerService from the StateService in order to demonstrate an error message
			this.handleError(ex);

			return false;
		}
	}

	/**
	 * Updates an item
	 *
	 * @param item
	 * @returns
	 */
	async updateItem(
		item: T,
		onUpdated: (
			item: T,
			isCreation?: undefined | boolean
		) => Promise<void> | void = undefined
	): Promise<boolean> {
		try {
			const userId: string = this.getUserId();

			if (!item)
				throw Error("Cannot update an invalid object reference.");

			const itemCopy: T = { ...item };

			// const itemToUpdate = await this.getItemById(item.id);
			// if (!itemToUpdate) return this.createItem(item);

			if (!itemCopy.id) {
				throw Error("Cannot update an object without an ID.");
			}

			await setDoc(
				doc(this._firestore, this._collectionName, itemCopy.id),
				{
					...itemCopy,
					lastChangedOn: Timestamp.now(),
					lastChangedBy: userId
				},
				{ merge: true }
			);

			// If any callback was specified for after update, calls it
			if (typeof onUpdated === "function") {
				onUpdated(itemCopy, false);
			}

			return !!itemCopy;
		} catch (ex) {
			// Calls the AppManagerService from the StateService in order to demonstrate an error message
			this.handleError(ex);

			return false;
		}
	}

	/**
	 * Deletes an item from the DB, based on the ID.
	 *
	 * @param id The ID of the removing item.
	 *
	 * @returns
	 */
	async deleteItem(
		id: string,
		onDeleted: (itemId: string) => void = undefined
	): Promise<boolean> {
		try {
			const userId: string = this.getUserId();
			const itemToDelete = await this.getItemById(id);

			if (!itemToDelete) return false;

			await setDoc(
				doc(this._firestore, this._collectionName, id),
				{
					deleted: true,
					deletedOn: Timestamp.now(),
					lastChangedBy: userId
				},
				{ merge: true }
			);

			// If any callback was specified for after deletion, calls it
			if (typeof onDeleted === "function") onDeleted(id);

			return true;
		} catch (ex) {
			// Calls the AppManagerService from the StateService in order to demonstrate an error message
			this.handleError(ex);

			return false;
		}
	}

	/**
	 * Gets the user ID from the auth state.
	 *
	 * @returns The user ID or null if not authenticated.
	 */
	getUserId(): null | string {
		// This method should be implemented by the service that extends this class
		return null;
	}

	/**
	 * Returns the base filter query for the collection.
	 *
	 * @returns The base filter query.
	 */
	private getBaseFilterQuery(): QueryFieldFilterConstraint {
		return where(this._deletedPropName, "==", false);
	}

	/**
	 * Converts a JSON string into an object of T.
	 *
	 * @param data The string data to convert
	 * @returns The JSON object as T.
	 */
	fromJSONString(data: string): T {
		if (!data) {
			return null;
		}

		return JSON.parse(data) as T;
	}

	/**
	 * Converts a collection of collections into a single list of
	 * objects of type T. It includes a dupe checker.
	 *
	 * @param lists The collection of lists to unify
	 * @returns A unified version of the lists provided
	 */
	unifyLists<T extends IFirebaseItem>(lists: T[][]): T[] {
		const _items = lists.reduce((acc: T[], curr: T[]) => {
			const _acc = [...acc];
			curr.forEach((item) => {
				if (item && !_acc.find((p) => p && p.id === item.id)) {
					_acc.push(item);
				}
			});
			return _acc;
		});

		return _items;
	}
}

export { FirebaseServiceHandler, IErrorHandler };
