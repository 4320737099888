import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	userProfileInitialState as initialState,
	IUserApplicationRoles,
	IUserProfileFull
} from "models";

const _sliceName = "userProfile";

export const userProfileSlice = createSlice({
	name: _sliceName,
	initialState,
	reducers: {
		setId(state, action: PayloadAction<string>) {
			state.profile.id = action.payload;
		},
		setName(state, action: PayloadAction<string>) {
			// Setting new state value
			state.profile.name = action.payload;
		},
		setDisplayName(state, action: PayloadAction<string>) {
			state.profile.displayName = action.payload;
		},
		setEmail(state, action: PayloadAction<string>) {
			state.profile.mail = action.payload;
		},
		setIsAdmin(state, action: PayloadAction<boolean>) {
			state.profile.roles = {
				...state.profile.roles,
				admin: action.payload
			};
		},
		setUser(state, action: PayloadAction<IUserProfileFull>) {
			state.profile.id = action.payload.id;
			state.profile.name = action.payload.name;
			state.profile.mail = action.payload.mail;
			state.profile.displayName = action.payload.displayName;
			state.profile.activeCompany = action.payload.activeCompany;
			state.profile.roles = { ...action.payload.roles };
			state.profile.companies = action.payload.companies;
		},
		setProfilePicture(state, action: PayloadAction<string>): void {
			state.profile.pictureURL = action.payload;
		},
		setAuthenticating(state, action: PayloadAction<boolean>): void {
			state.authenticating = action.payload;
		},
		setAuthenticated(state, action: PayloadAction<boolean>): void {
			state.authenticated = action.payload;
		},
		setProfileFormOpen(state, action: PayloadAction<boolean>): void {
			state.form.isOpen = action.payload;
		},
		setProfileFormEditing(state, action: PayloadAction<boolean>): void {
			state.form.isEditingInfo = action.payload;
		},
		setRoles(state, action: PayloadAction<IUserApplicationRoles>): void {
			state.profile.roles = { ...action.payload };
		},
		setCompanies(state, action: PayloadAction<string[]>): void {
			state.profile.companies = action.payload;
		},
		setActiveCompany(state, action: PayloadAction<string>): void {
			state.profile.activeCompany = action.payload;
		},
		reset(state): void {
			state.profile = { ...initialState.profile };
			state.authenticated = initialState.authenticated;
			state.authenticating = initialState.authenticating;
		},
		resetForm(state): void {
			state.form = initialState.form;
		}
	}
});

export const { reducer, actions } = userProfileSlice;

export default reducer;

// TODO: Selectors, split file
