import React from "react";
import { Icon } from "./Icon";
import { IIconDerivateProps } from "./shared/interfaces";

/**
 * @description Icon for opening a link in a new window
 */
export const OpenInNewWindowIcon: React.FC<IIconDerivateProps> = (props) => {
	return <Icon name="OpenInNewWindow" {...props} />;
};
