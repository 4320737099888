import { AppModuleNames, ModuleActionIds } from "components/modules";
import { AppProfileNames } from "components/modules/profiles/AppProfileNames";
import { MessageModalType } from "enums";
import { IStoreProfileConfig } from "interfaces/index";
import { IAppConfigSlice, IToast, IToastSlice } from "models/index";
import { RootState } from "redux/store";

/**
 * Selectors: AppConfig.
 */
export const AppConfigSelectors = {
	getAppBarHidden: (state: RootState): boolean =>
		state.appConfig.appBar.hidden,
	getAppBarTitle: (state: RootState): string => state.appConfig.appBar.title,
	getAppConfig: (state: RootState): IAppConfigSlice => state.appConfig,
	getAppConfigId: (state: RootState): string => state.appConfig.id,
	getLoading: (state: RootState): boolean => state.appConfig.loader.loading,
	getLoadingText: (state: RootState): string =>
		state.appConfig.loader.loadingText,
	getMessageModalType: (state: RootState): MessageModalType =>
		state.appConfig.messageModal.type,
	getMessageModalOpen: (state: RootState): boolean =>
		state.appConfig.messageModal.open,
	getMessageModalTitle: (state: RootState): string =>
		state.appConfig.messageModal.title,
	getMessageModalText: (state: RootState): string =>
		state.appConfig.messageModal.text,
	getCompanyProfileType: (state: RootState): AppProfileNames | null =>
		state.appConfig.companyProfile.type,
	getCompanyProfileLoading: (state: RootState): boolean =>
		state.appConfig.companyProfile.loading,
	getCompanyProfileLoaded: (state: RootState): boolean =>
		state.appConfig.companyProfile.loaded,
	getCompanyProfileModules: (state: RootState): null | AppModuleNames[] =>
		state.appConfig.companyProfile.modules,
	getCompanyProfileModuleActions: (
		state: RootState
	): null | ModuleActionIds[] => state.appConfig.companyProfile.moduleActions,
	getCompanyProfileConfig: (state: RootState): IStoreProfileConfig =>
		state.appConfig.companyProfile.config,
	getToastConfig: (state: RootState): IToastSlice => state.appConfig.toast,
	getToasts: (state: RootState): IToast[] => state.appConfig.toast.queue,
	getMaxToasts: (state: RootState): number => state.appConfig.toast.maxToasts
};
