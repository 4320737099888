// components/ChatModal.tsx
import React, { useState, useEffect, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import { actions } from "redux/reducers/chatAssist/reducer";
import { ChatAssistSelectors } from "redux/selectors";
import { handleSubmitMessage } from "./utils";

import "./ChatAssistModal.scss";

/**
 * Renders the Ticket Form part of the Chat Assistance module.
 */
export const ChatAssistTicketForm: React.FC = () => {
	const dispatch = useDispatch();
	const chatAssist = useSelector(ChatAssistSelectors.get);
	const [localMessage, setLocalMessage] = useState("");

	const strings = useMemo(
		() => ({
			form: {
				message: "Conte-nos o que aconteceu:",
				submitting: "Enviando...",
				online: "Online",
				offline: "Offline",
				statusMessage: `Estamos ${
					chatAssist.isOnline ? "disponíveis" : "indisponíveis"
				}`
			},
			actions: {
				submit: "Enviar"
			}
		}),
		[chatAssist.isOnline]
	);

	const canSubmit = useMemo(() => {
		const _result = localMessage.trim();

		return _result !== null && _result !== "" && _result.length > 5;
	}, [localMessage]);

	const onEnterCapture = (event: React.KeyboardEvent) => {
		if (!canSubmit || event.key !== "Enter") return;

		handleSubmitMessage(event);
	};

	useEffect(() => {
		if (chatAssist.isOpen) {
			setLocalMessage(chatAssist.data.message);
		}
	}, [chatAssist.isOpen, chatAssist.data.message]);

	return (
		<form
			onSubmit={handleSubmitMessage}
			className={"form"}
			onKeyDownCapture={onEnterCapture}
		>
			<div className={"chat-assist-modal-form-group"}>
				<label htmlFor="message" className={"chat-assist-modal-label"}>
					{strings.form.message}
					<span
						className={`status-indicator ${
							chatAssist.isOnline ? "online" : "offline"
						}`}
						title={strings.form.statusMessage}
						aria-label={strings.form.statusMessage}
					>
						{chatAssist.isOnline
							? strings.form.online
							: strings.form.offline}
					</span>
				</label>
				<textarea
					autoFocus
					id="message"
					className={"chat-assist-modal-textarea"}
					value={localMessage}
					onChange={(e) => {
						setLocalMessage(e.target.value);
						dispatch(actions.setMessage(e.target.value));
					}}
					rows={5}
					required
					disabled={chatAssist.submitting}
				/>
			</div>

			{chatAssist.error && (
				<div className={"error"}>{chatAssist.error}</div>
			)}

			<button
				type="submit"
				className={"chat-assist-submit-button"}
				disabled={chatAssist.submitting || !canSubmit}
			>
				{chatAssist.submitting
					? strings.form.submitting
					: strings.actions.submit}
			</button>
		</form>
	);
};
