import { appStateService, store } from "App";
import { IClickUpGetUserTasksResponse } from "models/chatAssist";
import { actions } from "redux/reducers/chatAssist/reducer";
import { ChatAssistSelectors, UserSelectors } from "redux/selectors";

/**
 * Gets the list of Tasks and comments for the Chat Assist component.
 *
 * @param chatAssist The current Chat Assist State
 * @param forceGet [Optional] Whether to force get the data, or not
 * @returns
 */
export const onGetTasksAndComments = async (forceGet: boolean = false) => {
	const _svc = appStateService.clickUp.get();
	const state = store.getState();
	const userProfile = UserSelectors.selectUserProfile(state);
	const chatAssist = ChatAssistSelectors.get(state);

	if (chatAssist.loading || (chatAssist.queried && !forceGet)) return;

	store.dispatch(actions.setLoading(true));

	await _svc
		.getUserTickets(userProfile.mail)
		.then((userTickets: IClickUpGetUserTasksResponse) => {
			const _tasks = userTickets.tasks;

			store.dispatch(actions.setTickets(_tasks));

			Promise.all(
				_tasks.map(async (_task) => {
					await _svc
						.getTaskMessages(_task.id)
						.then((data) => {
							if (typeof data === "object") {
								store.dispatch(
									actions.setMessagesByTicketId({
										ticketId: _task.id,
										messages: data.comments
									})
								);
							}
						})
						.catch((error) => {
							throw error;
						});
				})
			).finally(() => {
				store.dispatch(actions.setLoading(false));
				store.dispatch(actions.setQueried(true));
			});
		});
};
