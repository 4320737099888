import { formatDate } from "date-fns";
import { enUS, ptBR } from "date-fns/locale";
import { useCallback } from "react";

/**
 * Hook to format dates using date-fns
 * @returns A function to format dates
 */
export const useFormatFn = () => {
	const lang = "ptBR";
	const locales = {
		enUS,
		ptBR
	};

	const locale = locales[lang];

	const format = useCallback(
		(date: Date, formatStr: string) => {
			return formatDate(date, formatStr, { locale });
		},
		[locale]
	);

	return format;
};
