/**
 * Sound provider
 */
export interface ISoundProvider {
	orders: {
		notification: string;
	};
}

const notificationMap = {
	orders: "tones/notification-pluck-on.mp3"
};

/**
 * AppSoundProvider
 */
export const AppSoundProvider: ISoundProvider = {
	orders: {
		notification: notificationMap.orders
	}
};
