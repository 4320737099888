/**
 * Enum for AppReferrers,
 * those currently mapped in the platform.
 */
export enum AppReferrer {
	website = "website",
	instagram = "instagram",
	facebook = "facebook",
	google = "google",
	whatsapp = "whatsapp",
	other = "other"
}
