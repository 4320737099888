import { IChatAssistSlice } from "models/chatAssist";
import React, { useMemo } from "react";
import { formatOperationalHours } from "./utils";

/**
 *
 */
export interface ITeamOfflineMessageProps {
	chatAssist: IChatAssistSlice;
}

/**
 * Offline Message block, for using within the Chat Assist component.
 *
 */
export const TeamOfflineMessage: React.FC<ITeamOfflineMessageProps> = ({
	chatAssist
}) => {
	// TODO: Add translate support

	const strings = useMemo(
		() => ({
			offline: {
				message:
					"Nosso time de Suporte em Tempo Real está em horário de descanso.",
				operationHours: "Horário de Funcionamento:",
				actionPlan:
					"Você ainda pode enviar um ticket, nós o responderemos quando novamente online. Obrigado!"
			}
		}),
		[]
	);

	return (
		!chatAssist.isOnline && (
			<div className="offline-message">
				<p>{strings.offline.message}</p>
				<p>
					{strings.offline.operationHours}&nbsp;
					<br />
					{formatOperationalHours(chatAssist.operationalHours)}
				</p>
				<p>{strings.offline.actionPlan}</p>
			</div>
		)
	);
};
