import { createSelector } from "@reduxjs/toolkit";
import { CalendarItemType } from "enums";
import { CalendarViewMode } from "components/page/calendar/shared/enum";
import { RootState } from "../../store";
import { ICalendarItem } from "interfaces/calendar/ICalendarItem";
import { IClientOrder } from "models/order";

/**
 * Calendar selectors
 */
export const CalendarSelectors = {
	getCurrentEvent: (state: RootState): ICalendarItem =>
		state.calendar.currentEvent,
	getLoadingEvents: (state: RootState): boolean =>
		state.calendar.loading.events,
	getLoadingOrders: (state: RootState): boolean =>
		state.calendar.loading.orders,
	getError: (state: RootState): string | null => state.calendar.error,
	getSelectedMode: (state: RootState): CalendarViewMode =>
		state.calendar.selectedMode,
	getSelectedDate: (state: RootState): Date => state.calendar.selectedDate,
	getOrders: (state: RootState): IClientOrder[] => state.calendar.orders,
	getEvents: createSelector(
		(state: RootState) => state.calendar.events,
		(events: ICalendarItem[]): ICalendarItem[] =>
			events.filter((order) => order.type === CalendarItemType.Event)
	),
	getTasks: createSelector(
		(state: RootState) => state.calendar.events,
		(events: ICalendarItem[]): ICalendarItem[] =>
			events.filter((order) => order.type === CalendarItemType.Task)
	),
	getLoadedEvents: (state: RootState): boolean =>
		state.calendar.loaded.events,
	getLoadedOrders: (state: RootState): boolean =>
		state.calendar.loaded.orders,
	getShowOrders: (state: RootState): boolean =>
		state.calendar.filters.showOrders,
	getShowEvents: (state: RootState): boolean =>
		state.calendar.filters.showEvents,
	getShowTasks: (state: RootState): boolean =>
		state.calendar.filters.showTasks
};
