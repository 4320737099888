// components/ChatFab.tsx
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "redux/reducers/chatAssist/reducer";
import { ChatAssistModal } from "./shared/ChatAssistModal";
import { ChatAssistSelectors } from "redux/selectors";
import { useTranslateFn } from "hooks/i18n";

import "./shared/ChatAssistFab.scss";

/**
 * Implements the Chat Assistance modules, independently.
 */
const ChatAssistFab: React.FC = () => {
	const dispatch = useDispatch();
	const isOpen = useSelector(ChatAssistSelectors.getIsOpen);
	const translate = useTranslateFn();
	const strings = useMemo(
		() => ({
			label: {
				openChat: translate("chatAssist.fab.label.openChat")
			}
		}),
		[translate]
	);

	return !isOpen ? (
		<button
			className={"chat-fab"}
			onClick={() => dispatch(actions.toggleChat())}
			aria-label={strings.label.openChat}
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			>
				<path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
			</svg>
		</button>
	) : (
		<ChatAssistModal />
	);
};

export { ChatAssistFab };
