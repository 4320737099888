// components/ChatModal.tsx
import React, { useEffect, useRef } from "react";

import { useSelector } from "react-redux";
import {
	ChatAssistSelectors,
	CompanySelectors,
	UserSelectors
} from "redux/selectors";
import { onGetTasksAndComments } from "./utils";

import { RootState } from "redux/reducers";
import { ChatAssistTicketDetails } from "./ChatAssistTicketDetails";
import { ChatAssistTicketHistoryList } from "./ChatAssistTicketHistoryList";
import { ChatAssistHistoryButton } from "./ChatAssistHistoryButton";

import "./ChatAssistModal.scss";

const _historyTimeIntervalMs = 45000;

/**
 * Renders the message History part of the Chat Assist module.
 */
export const ChatAssistHistory: React.FC = () => {
	// Chat history re-loading interval
	const updateInterval = useRef<null | NodeJS.Timeout>(null);
	const activeCompanyId = useSelector(
		UserSelectors.selectUserProfileActiveCompany
	);
	const previousActiveCompanyId = useRef(null);
	const activeCompany = useSelector((state: RootState) =>
		CompanySelectors.getCompanyById(state, activeCompanyId)
	);
	const chatAssist = useSelector(ChatAssistSelectors.get);
	const activeTicketInstance = useSelector(
		ChatAssistSelectors.getActiveTicket
	);

	useEffect(() => {
		async function initAutoReload() {
			if (!activeCompany) return Promise.resolve();

			previousActiveCompanyId.current = activeCompany.id;
			await onGetTasksAndComments();

			// Clearing any other previously set interval
			if (updateInterval.current !== null) {
				clearInterval(updateInterval.current);
			}

			updateInterval.current = setInterval(async () => {
				await onGetTasksAndComments(true);
			}, _historyTimeIntervalMs);
		}

		initAutoReload();

		return () => {
			if (updateInterval.current) {
				clearInterval(updateInterval.current);
				updateInterval.current = null;
			}
		};
	}, [activeCompany]);

	useEffect(() => {
		return () => {
			if (updateInterval.current) {
				clearInterval(updateInterval.current);
				updateInterval.current = null;
			}
		};
	}, []);

	return chatAssist.tickets.length > 0 ? (
		<div className="chat-assist-history">
			{chatAssist.showHistory ? (
				activeTicketInstance !== null ? (
					<ChatAssistTicketDetails />
				) : (
					<ChatAssistTicketHistoryList />
				)
			) : (
				<ChatAssistHistoryButton />
			)}
		</div>
	) : null;
};
