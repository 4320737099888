import {
	AppCheck,
	initializeAppCheck,
	ReCaptchaV3Provider
} from "firebase/app-check";
import { FirebaseApp } from "firebase/app";

/**
 * Service to handle reCAPTCHA integration with Firebase.
 */
class ReCaptchaService {
	private static instance: ReCaptchaService;
	private initialized: boolean = false;
	private appCheckInstance: AppCheck | null = null;

	private constructor() {
		// Private constructor to enforce singleton
	}

	/**
	 * Gets the singleton instance of ReCaptchaService.
	 */
	public static getInstance(): ReCaptchaService {
		if (!ReCaptchaService.instance) {
			ReCaptchaService.instance = new ReCaptchaService();
		}
		return ReCaptchaService.instance;
	}

	/**
	 * Gets the App Check instance.
	 */
	public getAppCheck(): AppCheck {
		return this.appCheckInstance;
	}

	/**
	 * Initializes reCAPTCHA with Firebase App Check.
	 * @param siteKey - The reCAPTCHA site key
	 * @param debug - Whether to enable debug mode (default: false)
	 */
	public initialize(
		app: FirebaseApp,
		siteKey: string,
		debug: boolean = false
	): AppCheck {
		if (!app) {
			throw new Error("app-not-initialized");
		}

		if (this.initialized) return this.appCheckInstance;

		try {
			if (debug) {
				// Enable debug mode for development
				// @ts-ignore - This is a valid property for development
				window.FIREBASE_APPCHECK_DEBUG_TOKEN =
					process.env.REACT_APP_APP_CHECK_DEV_KEY;
			}

			const provider = new ReCaptchaV3Provider(siteKey);

			this.appCheckInstance = initializeAppCheck(app, {
				provider: provider,
				isTokenAutoRefreshEnabled: !debug
			});

			if (debug) {
				console.log("recaptcha-app-check-initialized");
			}

			this.initialized = true;

			return this.appCheckInstance;
		} catch (error) {
			if (debug) {
				console.error("failed-to-initialize-recaptcha", error);
			}
			throw error;
		}
	}

	/**
	 * Checks if reCAPTCHA is initialized.
	 */
	public isInitialized(): boolean {
		return this.initialized;
	}
}

export { ReCaptchaService };
