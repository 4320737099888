import {
	Functions,
	getFunctions,
	HttpsCallable,
	httpsCallableFromURL
} from "firebase/functions";
import { AppCheckTokenResult } from "firebase/app-check";
import { getAppCheckToken } from "../FirebaseService";

const _url = "https://<region-id>-<project-id>.cloudfunctions.net/";
/* const _url =
	process.env.NODE_ENV !== "production"
		? "http://127.0.0.1:5001/<project-id>/<region-id>/"
		: "https://<region-id>-<project-id>.cloudfunctions.net/"; */

// TODO: Pick the info using the Google Secret Manager
const _project = "bebase-web";
const _region = "southamerica-east1"; // São Paulo region

/**
 * Basic Service implementation to call Firebase Functions.
 */
abstract class FirebaseFunctionsService {
	protected baseURL: string;
	protected functionName: string;
	protected functions: Functions;
	private _lastCallable: undefined | HttpsCallable;
	private _appCheckEnabled: boolean;
	private _appCheckToken: AppCheckTokenResult;

	/**
	 * Constructor for the Firebase Functions Service.
	 *
	 * @param functionName The name of the function to be called.
	 */
	constructor(functionName: string) {
		this.baseURL = _url
			.replace("<region-id>", _region)
			.replace("<project-id>", _project);
		this.functionName = functionName;
		this._appCheckEnabled = true;
		// this._appCheckEnabled = process.env.NODE_ENV !== "development";
		// this.functions = getFunctions();
	}

	/**
	 * Calls the Firebase Function by it's URL specified during object construction.
	 *
	 * @param data The data to be sent to the function.
	 * @param appCheckToken The App Check token to be sent to the function.
	 * @returns The response from the function.
	 */
	public callService = async (data: any, useAppCheck: boolean = true) => {
		const functionURL = `${this.baseURL}${this.functionName}`;

		return await this.httpsCall(
			functionURL,
			data,
			useAppCheck ?? undefined
		);
	};

	/**
	 * Calls an endpoint using Firebase Functions config,
	 * however, the URL can be passed as a parameter.
	 *
	 * @param url The URL of the function to be called.
	 * @param data The data to be sent to the function.
	 * @returns The response from the function.
	 */
	public httpsCall = async (
		url: string,
		data: any,
		useAppCheck?: undefined | boolean
	) => {
		this.functions = !this.functions ? getFunctions() : this.functions;

		// If App Check is enabled and useAppCheck is true, get the App Check token and use it
		if (this._appCheckEnabled && useAppCheck) {
			this._appCheckToken = await getAppCheckToken();
			this._lastCallable = httpsCallableFromURL(this.functions, url, {
				limitedUseAppCheckTokens: this._appCheckEnabled
			});
		} else {
			this._lastCallable = httpsCallableFromURL(this.functions, url);
		}

		const response = await this._lastCallable(data);

		return response;
	};

	/**
	 * Gets the App Check token enabled in this service.
	 *
	 * @returns The App Check token.
	 */
	public getAppCheckToken = async () => {
		if (this._appCheckEnabled) {
			this._appCheckToken = await getAppCheckToken();
		}

		return this._appCheckToken;
	};
}

export default FirebaseFunctionsService;
