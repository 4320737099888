import { IOption } from "../dropdown";

/**
 * Handles the Change event for a Text or TextArea component.
 *
 * @param fieldName The name of the field to be updated.
 * @param setNewItem The function to update the item.
 * @returns The function to handle the Change event.
 */
export function onChangeField<TItem>(
	fieldName: string,
	setNewItem: (prev) => TItem
) {
	return (
		event: React.ChangeEvent<
			HTMLInputElement | HTMLTextAreaElement | HTMLTextAreaElement
		>
	) => {
		// TODO: Add support for nested properties to be set below.
		// Based on example below
		// Example:
		// When the input is ariaChecked, the value should be parsed back to boolean.
		// When the input is a number type, the value should be parsed back to floating point.
		// Otherwise, the value should be parsed back to string and let the component handle it.
		const value: number | string | boolean | TItem =
			event?.currentTarget?.ariaChecked &&
			["true", "false"].includes(event.currentTarget.ariaChecked)
				? event.currentTarget.ariaChecked === "true"
				: event?.target?.type === "number"
				? parseFloat(event?.target?.value)
				: event?.target?.value;
		//
		// setNewItem((prev) => ({ ...prev, [fieldName]: value } as TItem));

		const keys = fieldName.split(".");
		// const value = // ...parse value as needed...

		setNewItem((prev) => {
			const updated = { ...prev };
			let current = updated;
			keys.forEach((key, index) => {
				if (index === keys.length - 1) {
					current[key] = value;
				} else {
					current[key] = { ...current[key] };
					current = current[key];
				}
			});
			return updated;
		});
	};
}

/**
 * Handles the Change event for a Select component.
 *
 * @param fieldName The name of the field to be updated.
 * @param setNewItem The function to update the item.
 *
 * @returns The function to handle the Change event.
 */
export function onChangeSelect<TItem, IOptionType extends IOption>(
	fieldName: string,
	setNewItem: (prev) => TItem
): (event: any, option: IOptionType) => void {
	return (event: any, option: IOptionType) => {
		const value = option.key;
		const keys = fieldName.split(".");

		setNewItem((prev) => {
			const updated = { ...prev };
			let current = updated;

			keys.forEach((key, index) => {
				if (index === keys.length - 1) {
					current[key] = value;
				} else {
					current[key] = { ...current[key] };
					current = current[key];
				}
			});

			return updated;
		});
	};
}

// Create a new function which accepts editing an array of strings
// which is the base value of a given property

/**
 * Handles the Change event for an Array of strings.
 *
 * @param fieldName The name of the field to be updated.
 * @param setNewItem The function to update the item.
 *
 * @returns The function to handle the Change event.
 */
export function onChangeArrayField<TItem>(
	fieldName: string,
	setNewItem: (prev) => TItem
) {
	return (
		event: React.ChangeEvent<
			HTMLInputElement | HTMLTextAreaElement | HTMLTextAreaElement
		>
	) => {
		const value = event?.target?.value
			? event?.target?.value
			: event?.currentTarget?.value
			? event?.currentTarget?.value
			: "";
		const keys = fieldName.split(".");
		// const value = // ...parse value as needed...

		setNewItem((prev) => {
			const updated = { ...prev };
			let current = updated;
			keys.forEach((key, index) => {
				if (index === keys.length - 1) {
					current[key] = value;
				} else {
					current[key] = { ...current[key] };
					current = current[key];
				}
			});
			return updated;
		});
	};
}
