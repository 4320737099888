import FirebaseFunctionsService from "services/firebase/functions/FirebaseFunctionsService";

/**
 * Creates a reference to access the functions/getSecret as an API Service.
 * Used to retrieve secrets from Secret Manager via Firebase Functions.
 */
class GetSecretService extends FirebaseFunctionsService {
	constructor() {
		super("getSecret");
	}
}

export { GetSecretService };
