import { appStateService, store } from "App";
import { actions } from "redux/reducers/chatAssist/reducer";
import { onGetTasksAndComments } from "./onGetTasksAndComments";
import { ChatAssistSelectors } from "redux/selectors";

/**
 * Creates a new ticket, activates it,
 * As well as posts its first message - the user contact message.
 */
export const onCreateNewTicketAndActivate = async () => {
	const _svc = appStateService.clickUp.get();
	const state = store.getState();
	const chatAssist = ChatAssistSelectors.get(state);

	store.dispatch(actions.setLoading(true));

	// Submits data to click up
	await _svc
		.enqueueTicket(chatAssist.data.message, async (createdTicket) => {
			if (!createdTicket || createdTicket?.custom_id === "") {
				throw Error("chat-assist-submission-error");
			}

			// setTimeout(async () => {
			// 	await _svc
			// 		.getTaskMessages(createdTicket.id)
			// 		.then((data) => {
			// 			if (typeof data === "object") {
			// 				dispatch(
			// 					actions.setMessagesByTicketId({
			// 						ticketId: createdTicket.id,
			// 						messages: data.comments
			// 					})
			// 				);
			// 			}
			// 			onGetTasksAndComments(true);
			// 		})
			// 		.catch((error) => {
			// 			throw error;
			// 		});
			// }, 4000);

			//dispatch(actions.submitSuccess());
			store.dispatch(actions.setMessage(""));
			store.dispatch(actions.setActiveTicket(createdTicket.id));
			store.dispatch(actions.setShowHistory(true));
			await onGetTasksAndComments(true);
		})
		.finally(() => {
			store.dispatch(actions.setLoading(false));
		});
};
