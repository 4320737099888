import { appStateService, store } from "App";
import { AppModules } from "./AppModules";
import { ModuleActions } from "./actions";
import { AppProfiles } from "./profiles/AppProfiles";
import { CompanySelectors, UserSelectors } from "redux/selectors";
import { AppProfileNames } from "./profiles/AppProfileNames";
import { IAppProfile } from "./profiles/IAppProfile";

/**
 * Handles the completion of the configuration process.
 */
async function onCompletedConfig() {
	appStateService.appManager.setProfileLoaded(true);
	appStateService.appManager.setProfileLoading(false);
	appStateService.appManager.setLoading(false);
}

/**
 * Logs off the user and clears the profile data from the state.
 */
export async function logOffAndClearProfileData() {
	// Logs off the user and redirect to /
	await appStateService.auth.logOff();
	AppModules.applyProfile([]);
	ModuleActions.applyProfile([]);
	appStateService.appManager.setProfileType(null);
	appStateService.appManager.setProfileLoading(false);
	appStateService.appManager.setProfileLoaded(false);
	appStateService.appManager.setLoading(false);
}

/**
 * Preloads the user profile data and sets the state accordingly.
 */
export async function preloadUserProfile() {
	try {
		let userId = null;
		const userSvc = appStateService.user;
		const profileIsLoading =
			appStateService.appManager.getCompanyProfileLoading();
		const profileIsLoaded =
			appStateService.appManager.getCompanyProfileLoaded();

		// If already loading or loaded, don't do anything
		if (profileIsLoading) {
			return;
		}

		// If already loaded, just ensure loading is false and return
		if (profileIsLoaded) {
			if (profileIsLoading) {
				appStateService.appManager.setLoading(false);
			}

			return;
		}

		// Begins the profile loading process
		appStateService.appManager.setProfileLoading(true);
		userId = userSvc.getUserId();

		// No login detected, no need to load profile anymore
		if (!userId) {
			await logOffAndClearProfileData();
			return;
		}

		// Init user company data, then select it from state
		await userSvc.getLatestProfile().then((user) => {
			if (user?.id !== null) {
				userSvc.setAuthenticated(true);
				userSvc.setAuthenticating(false);
				appStateService.appManager.setProfileLoaded(true);
				appStateService.appManager.setProfileLoading(false);
			} else {
				// No user profile located
			}
		});
	} catch (error) {
		appStateService.error.handleError(error);
		await logOffAndClearProfileData();
	}
}

/**
 * Prepares and Configures the application modules,
 * Based on User Account access data.
 */
export async function configAppModules() {
	try {
		const companySvc = appStateService.company.service.get();
		const profileIsLoading =
			appStateService.appManager.getCompanyProfileLoading();
		const profileIsLoaded =
			appStateService.appManager.getCompanyProfileLoaded();

		// If already loading or loaded, don't do anything
		if (profileIsLoading || !profileIsLoaded) {
			return;
		}

		// Begins the profile loading process
		// appStateService.appManager.setProfileLoading(true);
		// userId = userSvc.getUserId();

		// No login detected, no need to load profile anymore
		// if (!userId) {
		// 	logOffAndClearProfileData();
		// 	return;
		// }

		try {
			// Ensure user companies are loaded and wait for the operation to complete
			await companySvc.getUserCompanies();
		} catch (error) {
			console.error("Error loading user companies:", error);
			// Even if there's an error, we should set profileLoaded to true to avoid getting stuck
			appStateService.error.handleError(error);
			appStateService.appManager.setLoading(false);
			return;
		}

		const storeState = store.getState();
		const activeCompanyId =
			UserSelectors.selectUserProfileActiveCompany(storeState);

		const activeCompany = CompanySelectors.getCompanyById(
			storeState,
			activeCompanyId
		);

		const userCompanies =
			UserSelectors.selectUserProfileCompanies(storeState);

		if (
			!activeCompanyId ||
			(!activeCompany?.companyProfile && userCompanies.length === 0)
		) {
			// TODO: Handle the case where a user doesn't yet have a company
			// Enables the modules below according to the user profile
			AppModules.applyProfile([]);
			ModuleActions.applyProfile([]);
			// Even if there's no company profile, we should set profileLoaded to true to avoid getting stuck
			appStateService.appManager.setProfileLoaded(true);
			appStateService.appManager.setProfileLoading(false);
			appStateService.appManager.setLoading(false);
			return;
		}

		const userCompanyProfileName = activeCompany.companyProfile;
		const userCompanyProfile: IAppProfile = AppProfiles[
			userCompanyProfileName
		] as IAppProfile;

		// Loads the state with the modules config
		appStateService.appManager.setProfileConfigModules(
			userCompanyProfile?.appModules
		);
		appStateService.appManager.setProfileConfigModuleActions(
			userCompanyProfile?.moduleActions
		);

		// Enables the modules below according to the user profile
		AppModules.applyProfile(userCompanyProfile?.appModules || []);
		ModuleActions.applyProfile(userCompanyProfile?.moduleActions || []);

		// Sets the profile type and other configurations
		appStateService.appManager.setProfileType(
			AppProfileNames[userCompanyProfileName]
		);
		await onCompletedConfig();
	} catch (error) {
		// Catch any unexpected errors and ensure we don't get stuck in loading state
		console.error("Unexpected error in configAppModules:", error);
		await logOffAndClearProfileData();
	}
}
