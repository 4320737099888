import { Libraries } from "@googlemaps/js-api-loader";

/**
 * @description Configuration for the Google Maps API
 */
export interface IMapsConfigs {
	mapIds: {
		quickView: string;
	};
	mapZooms: {
		default: number;
		quickView: number;
	};
	enabledAPIs: Libraries;
	placeScopes: string[];
}

/**
 * @description Configuration for the Google Maps API
 */
export const MapsConfigs: IMapsConfigs = {
	mapIds: {
		quickView: "c7c3650c11a8ffea"
	},
	mapZooms: {
		default: 14,
		quickView: 14
	},
	enabledAPIs: ["places", "core", "marker"] as Libraries,
	placeScopes: [
		"restaurant",
		"food",
		"store",
		"establishment",
		"business",
		"point_of_interest",
		"local_business",
		"health",
		"finance",
		"place_of_worship",
		"school",
		"shopping_mall",
		"supermarket",
		"convenience_store",
		"clothing_store",
		"electronics_store",
		"home_goods_store",
		"furniture_store",
		"book_store",
		"cafe",
		"bakery",
		"bar",
		"night_club",
		"lodging",
		"real_estate_agency",
		"car_dealer",
		"car_repair",
		"car_wash",
		"gas_station",
		"parking",
		"moving_company",
		"storage",
		"travel_agency",
		"art_gallery",
		"museum",
		"park",
		"gym",
		"spa",
		"beauty_salon",
		"hair_care",
		"laundry",
		"locksmith",
		"electrician",
		"plumber",
		"insurance_agency",
		"lawyer",
		"pharmacy",
		"doctor",
		"dentist",
		"veterinary_care",
		"pet_store",
		"library",
		"post_office",
		"atm",
		"bank"
	]
};
