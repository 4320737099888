import { MessageModalType } from "enums";

/**
 * The Toast object definition
 */
export interface IToast {
	id: string;
	message: string;
	type: MessageModalType;
	open: boolean;
	timestamp: number;
}

/**
 * The Toast slice definition
 */
export interface IToastSlice {
	queue: IToast[];
	maxToasts?: number;
}

/**
 * The initial state of the Toast slice
 */
export const toastInitialState: IToastSlice = {
	queue: [],
	maxToasts: 3 // Maximum number of toasts to show at once
};
