import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { appStateService } from "App";
import { UserSelectors } from "redux/selectors";
import { useTranslate } from "hooks/i18n";
import { LoadingOverlay } from "components/shared/overlay";
import { User } from "firebase/auth";
import { IUserProfileSlice } from "models";
import { useLocation } from "react-router-dom";
import { isExternalRoute } from "components/router/ModuleRouteGuard";

/**
 * Contract for the properties provided to the AppAuthentication component.
 */
interface IAppAuthenticationProps {
	key?: string;
	children?: React.ReactNode;
}

/**
 * Application authentication layer, which abstracts
 * the necessary operations of the authentication ecosystem.
 */
export function AppAuthentication({
	key,
	children
}: IAppAuthenticationProps): JSX.Element {
	const authMonitor = useRef(null);
	const authenticating = useSelector(UserSelectors.selectAuthenticating);
	const authenticated = useSelector(UserSelectors.selectAuthenticated);
	const loadingText = useTranslate("global.loading");
	const location = useLocation();

	// Check if current path is an external route
	const isExternal = isExternalRoute(location.pathname);

	// Handle authentication state changes
	const handleAuthenticated = (user: User, state: IUserProfileSlice) => {
		// User is authenticated, update state if needed
		if (!authenticated) {
			handleAnonymous();
		}
	};

	const handleAnonymous = () => {
		// User is not authenticated, clean up state
		appStateService.auth.logOff();
	};

	// Starts up a monitor for the authentication state, which can identify changes from server-side as well.
	useEffect(() => {
		if (!authMonitor.current) {
			authMonitor.current = appStateService.auth.monitorAuthState;
		}

		const unsubscribeMonitor = authMonitor.current(
			handleAuthenticated,
			handleAnonymous,
			false
		);

		return () => {
			unsubscribeMonitor();
		};
	}, []);

	// Only show loading overlay if authenticating AND not on an external route
	// This prevents the loading overlay from showing on login/signup pages
	if (authenticating && !isExternal) {
		return <LoadingOverlay text={loadingText} />;
	}

	return <>{children}</>;
}

export { IAppAuthenticationProps };
