import { appStateService, store } from "App";
import { Timestamp } from "firebase/firestore";
import { IUserProfile, IUserProfileFull } from "models";
import { ReduxStoreService } from "services/redux";
import { StoreType } from "types/StoreType";

// Actions and Selectors
import { actions as clientActions } from "redux/reducers/clients/reducer";
import { actions as categoriesActions } from "redux/reducers/categories/reducer";
import { actions as brandActions } from "redux/reducers/brands/reducer";
import { actions as productActions } from "redux/reducers/products/reducer";
import { actions as clientOrderActions } from "redux/reducers/orders/client/reducer";
import { actions as supplierActions } from "redux/reducers/suppliers/reducer";
import { actions as userProfileActions } from "redux/reducers/user/userProfileReducer";
import { actions as stockListActions } from "redux/reducers/stock/list/reducer";
import { actions as stockMovementActions } from "redux/reducers/stock/movement/reducer";
import { UserSelectors } from "redux/selectors";
import { AppModules, ModuleActions } from "components/modules";

/**
 * Class for managing the User data in the State Layer of the app.
 * TODO: Use the UserSelectors to read the data from the state.
 */
class UserService extends ReduxStoreService<IUserProfileFull> {
	constructor(reduxStore: StoreType) {
		super(
			"userProfiles",
			() => {},
			(queried: boolean) => userProfileActions.reset(),
			(querying: boolean) => {},
			reduxStore
		);
	}

	// TODO: Implement the use of logic to create the user profile when that is not available.

	getName() {
		if (!store) return;

		// Reading current state value
		return UserSelectors.selectUserProfileName(store.getState());
	}

	setName(newName: string) {
		if (!store || !newName) return;

		store.dispatch(userProfileActions.setName(newName));
	}

	setDisplayName(newDisplayName: string) {
		if (!store || !newDisplayName) return;

		store.dispatch(userProfileActions.setDisplayName(newDisplayName));
	}

	getDisplayName() {
		if (!store) return;

		return UserSelectors.selectUserProfileDisplayName(store.getState());
	}

	getId() {
		if (!store) return;

		return UserSelectors.selectUserProfileId(store.getState());
	}

	setId(id: string) {
		if (!store) return;

		store.dispatch(userProfileActions.setId(id));
	}

	getEmail() {
		if (!store) return;

		return UserSelectors.selectUserProfile(store.getState()).mail;
	}

	setEmail(email: string) {
		if (!store) return;

		store.dispatch(userProfileActions.setEmail(email));
	}

	getIsAdmin() {
		if (!store) return;

		return (
			UserSelectors.selectUserProfileRoles(store.getState())?.admin ??
			false
		);
	}

	setIsAdmin(isAdmin: boolean) {
		if (!store) return;

		store.dispatch(userProfileActions.setIsAdmin(isAdmin));
	}

	/**
	 * Sets the user profile in the Redux store.
	 *
	 * @param user The user profile to set.
	 */
	setUser(user: IUserProfile) {
		if (!store) return;

		if (!user) {
			return;
		}

		store.dispatch(userProfileActions.setId(user.id));
		store.dispatch(userProfileActions.setName(user.name));
		store.dispatch(userProfileActions.setDisplayName(user.displayName));
		store.dispatch(userProfileActions.setEmail(user.mail));
		store.dispatch(userProfileActions.setProfilePicture(user.pictureURL));
		store.dispatch(userProfileActions.setCompanies(user.companies));
		store.dispatch(userProfileActions.setActiveCompany(user.activeCompany));
	}

	getCompanies() {
		if (!store) return;

		return UserSelectors.selectUserProfileCompanies(store.getState());
	}

	getRoles() {
		if (!store) return;

		return UserSelectors.selectUserProfileRoles(store.getState());
	}

	getActiveCompany() {
		if (!store) return;

		return UserSelectors.selectUserProfileActiveCompany(store.getState());
	}

	getProfileFormOpen() {
		if (!store) return;

		return UserSelectors.selectUserProfileFormOpen(store.getState());
	}

	getCustomerId() {
		if (!store) return;

		return UserSelectors.selectUserProfileCustomerId(store.getState());
	}

	getFirstAccess() {
		if (!store) return;

		return UserSelectors.selectUserProfileFirstAccess(store.getState());
	}

	async getActiveCompanyName() {
		if (!store) return;

		const activeCompanyId = this.getActiveCompany();

		const activeCompany = await appStateService.company.service
			.get()
			.getItemById(activeCompanyId);

		return activeCompany?.name ?? activeCompanyId;
	}

	getPictureURL() {
		if (!store) return;

		return UserSelectors.selectUserProfile(store.getState()).pictureURL;
	}

	/**
	 * Sets the user profile picture URL.
	 *
	 * @param url The URL to set the picture to.
	 */
	setPictureURL(url: string) {
		if (!store) return;

		store.dispatch(userProfileActions.setProfilePicture(url));
	}

	/**
	 * Sets the user profile active company.
	 *
	 * @param company The company to set as active.
	 */
	async setActiveCompany(company: string): Promise<void> {
		if (!store) return;

		const userID = UserSelectors.selectUserProfileId(store.getState());
		const currentActiveCompany =
			UserSelectors.selectUserProfileActiveCompany(store.getState());

		// No change detected, no need to update, nor clear anything
		if (currentActiveCompany === company) {
			return;
		}

		try {
			// Set loading state to true before making changes
			appStateService.appManager.setLoading(true);

			// We need to set profileLoading to true but keep profileLoaded as is
			// This prevents the infinite loop while still showing the loader
			appStateService.appManager.setProfileLoading(true);

			// Get the user from the database
			const foundUser = await this.getItemById(userID);

			// Update the user's active company in the database
			foundUser.activeCompany = company;
			await this.updateItem(foundUser);

			// Update the active company in Redux
			store.dispatch(userProfileActions.setActiveCompany(company));

			// Cleans-up the main lists of the system
			store.dispatch(brandActions.reset());
			store.dispatch(clientActions.reset());
			store.dispatch(categoriesActions.reset());
			store.dispatch(productActions.reset());
			store.dispatch(supplierActions.reset());
			store.dispatch(clientOrderActions.reset());
			store.dispatch(stockListActions.reset());
			store.dispatch(stockMovementActions.reset());

			// Reset modules
			AppModules.applyProfile([]);
			ModuleActions.applyProfile([]);

			// Always set profileLoaded to false to trigger reconfiguration
			appStateService.appManager.setProfileLoaded(false);

			// Set profileLoading to false to allow reconfiguration to start
			appStateService.appManager.setProfileLoading(false);

			// Ensure loading is set to false
			appStateService.appManager.setLoading(false);
		} catch (error) {
			appStateService.appManager.showError(
				"Failed to switch company: " +
					(error.message || "Unknown error")
			);

			// Make sure to reset loading states in case of error
			appStateService.appManager.setProfileLoading(false);
			appStateService.appManager.setProfileLoaded(true); // Set to true to avoid getting stuck
			appStateService.appManager.setLoading(false);
		}
	}

	/**
	 * Sets the user profile form open or closed.
	 *
	 * @param open Whether the form is open or not.
	 */
	setProfileFormOpen(open: boolean) {
		if (!store) return;

		store.dispatch(userProfileActions.setProfileFormOpen(open));
	}

	/**
	 * Sets the user profile form editing or not.
	 *
	 * @param editing Whether the form is being edited or not.
	 */
	setProfileFormEditing(editing: boolean) {
		if (!store) return;

		store.dispatch(userProfileActions.setProfileFormEditing(editing));
	}

	/**
	 * Resets the state of the UserProfile form slice.
	 */
	resetForm() {
		if (!store) return;

		store.dispatch(userProfileActions.resetForm());
	}

	/**
	 * Checks whether the user exists in the DB or not,
	 * saving it automatically if first login.
	 *
	 * @param id The user ID to check existence.
	 */
	async checkUserExistsByID(
		id: string,
		onComplete: undefined | ((item: IUserProfile) => Promise<void>),
		onError: undefined | ((error: Error) => Promise<void>)
	): Promise<void> {
		let userEntry = null;

		// Tries to locate the user in the database
		// If not located, updates the database to include the object
		await appStateService.user
			.queryItemsByProp("id", id, "==")
			.then(async (items) => {
				// If the user is not found, creates a new entry to track it
				if (items.length === 0) {
					await this.saveNewUser((data) => {
						userEntry = data;
					});
				} else {
					userEntry = items[0];
				}
			})
			.catch(async (error) => {
				if (typeof onError === "function") {
					await onError(error);
				}
			});

		if (typeof onComplete === "function") await onComplete(userEntry);

		return userEntry;
	}

	/**
	 * Saves a new user profile to the DB, by updating the data.
	 *
	 * @param onSaved Callback to be invoked when the data is done saving.
	 */
	private async saveNewUser(
		onSaved: (data: IUserProfile) => void
	): Promise<void> {
		const userProfileData: IUserProfileFull = {
			name: this.getName(),
			displayName: this.getDisplayName(),
			mail: this.getEmail(),
			id: this.getId(),
			pictureURL: this.getPictureURL(),
			timestamp: Timestamp.now(),
			deleted: false,
			activeCompany: this.getActiveCompany(),
			companies: this.getCompanies(),
			roles: this.getRoles(),
			customerId: this.getCustomerId(),
			firstAccess: this.getFirstAccess()
		};

		await appStateService.user.updateItem(userProfileData, onSaved);
	}

	/**
	 * Sets the authenticated state in the Redux store.
	 *
	 * @param authenticated Whether the user is authenticated
	 */
	setAuthenticated(authenticated: boolean) {
		if (!store) return;

		store.dispatch(userProfileActions.setAuthenticated(authenticated));
	}

	/**
	 * Sets the authenticating state in the Redux store.
	 *
	 * @param authenticating Whether the app is currently authenticating
	 */
	setAuthenticating(authenticating: boolean) {
		if (!store) return;

		store.dispatch(userProfileActions.setAuthenticating(authenticating));
	}

	/**
	 * Fetches the latest user profile data from the storage.
	 *
	 * @returns The latest user profile data.
	 */
	async getLatestProfile(): Promise<null | IUserProfileFull> {
		const self = this;
		const userId = this.getUserId();

		return await self.getItemById(userId).then((user) => {
			if (!user) {
				return null;
			}

			self.setUser(user);

			return user;
		});
	}
}

export { UserService };
