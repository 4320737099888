import FirebaseFunctionsService from "services/firebase/functions/FirebaseFunctionsService";

/**
 * Used to authenticate a first-login User Validation Code.
 */
class SendReleaseAnnouncementService extends FirebaseFunctionsService {
	constructor() {
		super("sendReleaseAnnouncementService");
	}
}

export { SendReleaseAnnouncementService };
