import React from "react";

import "./RouterFrame.scss";

/**
 * Contract for using the Router Frame component
 */
interface IRouterFrame {
	children: React.ReactNode;
}

/**
 * Header spacer component
 */
export const RouterFrame: React.FC<IRouterFrame> = (props) => {
	return <div className="router-frame">{props.children}</div>;
};
