import { IChatAssist } from "models/index";
import { actions } from "redux/reducers/chatAssist/reducer";
import { CompanySegment } from "services/companies/CompanySegment";

export const _listName = "chatAssists";

const _baseStoragePath = (chatAssistId: string) =>
	`chatAssists/${chatAssistId}`;

export abstract class AbstractCompanyChatAssistService extends CompanySegment<IChatAssist> {
	constructor(companyId: string) {
		super(companyId, _listName, actions.setList, actions.setQueried);
	}
}

/**
 * Class for managing the Chat Assists data, for a company.
 * Manages the Redux store, as well as the Firebase database modifications.
 * Also, registers logs of the operations.
 */
class CompanyChatAssistService extends AbstractCompanyChatAssistService {
	constructor(companyId: string) {
		super(companyId);
	}
}

export { CompanyChatAssistService };
