import { CalendarViewMode } from "components/page/calendar/shared/enum";
import { CalendarItemType } from "enums";
import { Timestamp } from "firebase/firestore";
import { ICalendarItem } from "interfaces/calendar/ICalendarItem";
import { IClientOrder } from "models/order";

/**
 * Calendar interface
 */
export interface ICalendar {
	title: string;
	description: string;
	createdAt: Date;
	updatedAt: Date;
	events: ICalendarItem[];
}

/**
 * Calendar
 */
export class Calendar implements ICalendar {
	title: string;
	description: string;
	createdAt: Date;
	updatedAt: Date;
	events: ICalendarItem[];

	constructor(data?: ICalendar) {
		this.title = data?.title ?? "";
		this.description = data?.description ?? "";
		this.createdAt = data?.createdAt ?? new Date();
		this.updatedAt = data?.updatedAt ?? new Date();
		this.events = data?.events ?? [];
	}
}

/**
 * Calendar item
 */
export class CalendarItem implements ICalendarItem {
	// props
	id: string;
	title: string;
	description: string;
	startDate: Timestamp;
	endDate: Timestamp;
	allDay: boolean;
	type: CalendarItemType;
	enabled: boolean;
	color: string;
	timestamp: Timestamp;
	lastChangedBy: string;

	// constructor
	constructor(data?: Partial<ICalendarItem>) {
		this.id = data?.id ?? "";
		this.title = data?.title ?? "";
		this.description = data?.description ?? "";
		this.startDate = data?.startDate ?? Timestamp.now();
		this.endDate = data?.endDate ?? Timestamp.now();
		this.allDay = data?.allDay ?? false;
		this.type = data?.type ?? CalendarItemType.Event;
		this.enabled = data?.enabled ?? true;
		this.color = data?.color ?? "#000000";
		this.timestamp = data?.timestamp ?? Timestamp.now();
		this.lastChangedBy = data?.lastChangedBy ?? "";
	}
}

/**
 * Calendar slice interface
 */
export interface ICalendarSlice {
	orders: IClientOrder[];
	events: ICalendarItem[];
	currentEvent: ICalendarItem;
	loading: {
		events: boolean;
		orders: boolean;
	};
	loaded: {
		events: boolean;
		orders: boolean;
	};
	error: string | null;
	selectedMode: CalendarViewMode;
	selectedDate: Date;
	filters: {
		showOrders: boolean;
		showEvents: boolean;
		showTasks: boolean;
	};
}

/**
 * Calendar slice
 */
export class CalendarSlice implements ICalendarSlice {
	orders: IClientOrder[];
	events: ICalendarItem[];
	currentEvent: ICalendarItem;
	loading: {
		events: boolean;
		orders: boolean;
	};
	loaded: {
		events: boolean;
		orders: boolean;
	};
	error: string | null;
	selectedMode: CalendarViewMode;
	selectedDate: Date;
	filters: {
		showOrders: boolean;
		showEvents: boolean;
		showTasks: boolean;
	};

	constructor(data?: ICalendarSlice) {
		this.orders = data?.orders ?? [];
		this.events = data?.events ?? [];
		this.currentEvent = data?.currentEvent ?? new CalendarItem();
		this.loading = data?.loading ?? {
			events: false,
			orders: false
		};
		this.loaded = data?.loaded ?? {
			events: false,
			orders: false
		};
		this.error = data?.error ?? null;
		this.selectedMode = data?.selectedMode ?? CalendarViewMode.Week;
		this.selectedDate = data?.selectedDate ?? new Date();
		this.filters = data?.filters ?? {
			showOrders: true,
			showEvents: true,
			showTasks: true
		};
	}
}

/**
 * Initial state for the calendar slice
 */
export const calendarSliceInitialState: ICalendarSlice = {
	...new CalendarSlice()
};
