import { ModuleActionIds } from "../actions";
import { AppModuleNames } from "../AppModules";
import { IAppProfile } from "./IAppProfile";

/**
 * The application Premium Plan Profile config.
 * Almost all modules and actions allowed.
 */
export const AppMasterPlanProfile: IAppProfile = {
	appModules: [
		AppModuleNames.basic,
		AppModuleNames.brands,
		AppModuleNames.dashboard,
		AppModuleNames.orderExternal,
		AppModuleNames.orderManagement,
		AppModuleNames.orderProduction,
		AppModuleNames.stockManagement,
		AppModuleNames.storeFront,
		AppModuleNames.supplierManagement,
		AppModuleNames.tableService,
		AppModuleNames.calendar
	],
	moduleActions: [
		ModuleActionIds.brandsListView,
		ModuleActionIds.orderProductionModeView
	]
};
