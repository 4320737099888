/**
 * Icon size enum.
 */
enum IconSize {
	XS = "xs",
	SM = "sm",
	MD = "md",
	LG = "lg",
	XL = "xl"
}

/**
 * Icon derivate props interface.
 * It doesn't include name property.
 */
interface IIconDerivateProps {
	/**
	 * Icon size.
	 */
	size?: undefined | IconSize;

	/**
	 * Icon color.
	 */
	color?: undefined | string;

	/**
	 * Icon style.
	 */
	style?: undefined | React.CSSProperties;

	/**
	 * Icon class name.
	 */
	className?: undefined | string;

	/**
	 * Icon click event handler.
	 */
	onClick?: undefined | (() => void);

	/**
	 * Icon tooltip.
	 */
	tooltip?: undefined | string;
}

/**
 * Icon props interface.
 */
interface IIconProps extends IIconDerivateProps {
	/**
	 * Icon name.
	 */
	name: string;
}

export { IIconProps, IIconDerivateProps, IconSize };
