import { FirebaseOperators, PropQueryCriteria } from "./firestoreQuery.types";
import "firebase/firestore";

/**
 * Returns the necessary criteria for a "where-between" query.
 * In Firestore, some indexing issues might occur - please, monitor the Console Log.
 *
 * @param propName The property name to use for filtering
 * @param minValue The minimum value to use for filtering
 * @param maxValue The maximum value to use for filtering
 * @param includeDeleted Whether to include deleted items in the query
 *
 * @returns The criteria for the Where-Between query.
 */
export function whereBetween(
	propName: string,
	minValue: string | number | Date,
	maxValue: string | number | Date,
	includeDeleted: boolean = false
): PropQueryCriteria[] {
	const conditions: PropQueryCriteria[] = [
		[propName, FirebaseOperators.greaterThanOrEqual, minValue],
		[propName, FirebaseOperators.lessThanOrEqual, maxValue]
	];

	return includeDeleted
		? conditions
		: [["deleted", FirebaseOperators.equals, false], ...conditions];
}
