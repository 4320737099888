import React from "react";
import { Overlay } from "./Overlay";

interface ILoadingOverlayProps {
	text?: string;
}

export function LoadingOverlay({ text }: ILoadingOverlayProps): JSX.Element {
	return (
		<Overlay open>
			<div className="loading-overlay">{text}</div>
		</Overlay>
	);
}
