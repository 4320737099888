// const REACT_APP_CLICKUP_CLIENT_ID = process.env.REACT_APP_CLICKUP_CLIENT_ID;

import { appStateService, store } from "App";
import { getShortId } from "components/shared/util";
import { Timestamp } from "firebase/firestore";
import {
	ClickUpPriority,
	ClickUpTaskFieldId,
	ClickUpTicketStatus,
	IChatAssist,
	IClickUpGetUserTasksResponse,
	IClickUpTask,
	IClickUpTaskComment
} from "models/chatAssist";
import { CompanySelectors, UserSelectors } from "redux/selectors";
import { AbstractCompanyChatAssistService } from "services/chatAssist/CompanyChatAssistService";

// const REACT_APP_CLICKUP_CLIENT_KEY = process.env.REACT_APP_CLICKUP_CLIENT_KEY;
const REACT_APP_CLICKUP_CLIENT_TOKEN =
	process.env.REACT_APP_CLICKUP_CLIENT_TOKEN;
const REACT_APP_CLICKUP_LIST_ID = process.env.REACT_APP_CLICKUP_LIST_ID;
const REACT_APP_CLICKUP_TICKET_QUEUE_ID =
	process.env.REACT_APP_CLICKUP_TICKET_QUEUE_ID;
const _clickUpApiURL = "https://api.clickup.com/api/v2";

/**
 * ClickUp Service.
 */
export class ClickUpService {
	private headers: any;
	private baseURL: string;
	private chatAssist: AbstractCompanyChatAssistService;

	constructor() {
		this.baseURL = _clickUpApiURL;
		this.headers = {
			Authorization: REACT_APP_CLICKUP_CLIENT_TOKEN,
			"Content-Type": "application/json",
			Accept: "application/json"
		};
		this.chatAssist = appStateService.company.chatAssist.get();
	}

	validateClientConfig() {
		if (!REACT_APP_CLICKUP_LIST_ID) {
			throw new Error(
				"ClickUp: List ID is a requirement for integration."
			);
		}
	}

	/**
	 * Gets the standard request headers.
	 *
	 * @param method The Request Method to be used
	 * @param body [optional] The body to pass on to the request
	 * @returns
	 */
	getRequestHeaders(method: string = "GET", body?: undefined | string) {
		let _requestData: { [key: string]: any } = {
			method: method,
			headers: this.headers
		};

		if (body && body !== null) {
			_requestData.body = body;
		}

		return _requestData;
	}

	/**
	 * Creates a task in a given List, by ID.
	 *
	 * @param task The Task data, to be created.
	 *
	 * @returns The created Task.
	 */
	async createTask(
		task: Partial<IClickUpTask>,
		listId: string = REACT_APP_CLICKUP_LIST_ID
	): Promise<IClickUpTask> {
		this.validateClientConfig();

		if (!task.name) {
			throw new Error(
				"ClickUp: Task name is a requirement for integration."
			);
		}

		const response = await fetch(
			`${this.baseURL}/list/${listId}/task`,
			this.getRequestHeaders("POST", JSON.stringify(task))
		);

		return response.ok ? response.json() : null;
	}

	/**
	 * Gets a task by its custom ID.
	 *
	 * @param customId The custom ID of the Task.
	 *
	 * @returns The Task, if found.
	 */
	async getTaskByCustomId(customId: string): Promise<IClickUpTask | null> {
		let foundTask: IClickUpTask | null = null;

		this.validateClientConfig();

		const response = await fetch(
			`${this.baseURL}/list/${REACT_APP_CLICKUP_LIST_ID}/task?custom_id=${customId}`,
			this.getRequestHeaders("GET")
		);

		if (response.ok) {
			foundTask = (await response.json()) as IClickUpTask;
		}

		return foundTask;
	}

	/**
	 * Views a task in the ClickUp app.
	 *
	 * @param taskId The Task ID.
	 */
	async viewTask(taskId: string) {
		window.open(`https://app.clickup.com/t/${taskId}`, "_blank");
	}

	/**
	 * Gets the list of default tags.
	 *
	 * @returns Collection of default ClickUp tags.
	 */
	getDefaultTags(): string[] {
		const tags: string[] = [];

		tags.push(`hostname:${window.location.hostname}`);
		tags.push(`env:${process.env.NODE_ENV}`);

		return tags;
	}

	/**
	 * Gets the tags used to identify a user task
	 */
	getUserTags(): string[] {
		const _state = store.getState();
		const userProfile = UserSelectors.selectUserProfile(_state);

		if (!userProfile) return [];

		return [userProfile.mail];
	}

	/**
	 * Gets the custom fields of a Task formatted for ClickUp,
	 * with owner data as param.
	 *
	 * @param ownerData The owner data
	 * @returns
	 */
	getTaskOwnerFields(ownerData: {
		email: string;
		name: string;
		companyName?: undefined | string;
		companyId?: undefined | string;
	}) {
		const customFields = [
			{
				id: ClickUpTaskFieldId.userMail,
				value: ownerData.email ?? "system-default@app"
			},
			{
				id: ClickUpTaskFieldId.userName,
				value: ownerData.name ?? "system-default"
			}
		];

		if (
			ownerData?.companyId !== undefined &&
			ownerData?.companyId !== null &&
			ownerData.companyId !== ""
		) {
			customFields.push({
				id: ClickUpTaskFieldId.userCompanyId,
				value: ownerData.companyId ?? "default-id"
			});
		}

		if (
			ownerData?.companyName !== undefined &&
			ownerData?.companyName !== null &&
			ownerData.companyName !== ""
		) {
			customFields.push({
				id: ClickUpTaskFieldId.userCompanyName,
				value: ownerData.companyName ?? "app-default"
			});
		}

		return customFields;
	}

	/**
	 * Handles the creation of a ticket, based on the current user.
	 * Currently working for Authenticated users.
	 */
	async enqueueTicket(
		message: string,
		onComplete: (_item: IClickUpTask) => void | Promise<void>
	): Promise<null | IClickUpTask> {
		const self = this;
		let ticketCreated: IClickUpTask = null;
		let clickUpTask: null | Partial<IClickUpTask> = null;
		const appState = store.getState();
		const activeUser = UserSelectors.selectUserProfile(appState);
		const activeCompanyId =
			UserSelectors.selectUserProfileActiveCompany(appState);
		const activeCompany = CompanySelectors.getCompanyById(
			appState,
			activeCompanyId
		);
		const uri = window.location.toString();

		this.validateClientConfig();

		const description =
			`**BeeBase App: Ticket Interno**\r\n` +
			`Empresa Cliente: **${activeCompany.name}**\r\n` +
			`E-mail: **${activeUser.mail}**\r\n` +
			`Nome: **${activeUser.name}**\r\n` +
			`Descrição: **${message}**\r\n` +
			`Contexto/Página: **${uri}**`;

		await self.chatAssist
			.createItem(
				{
					id: "",
					ticketQueueId: "", // To be set after the Click-up item has been created
					message: description,
					timestamp: Timestamp.now(),
					userId: activeUser.id,
					deleted: false
				},
				async (
					createdAssistRequest: IChatAssist,
					isCreation?: boolean
				) => {
					clickUpTask = {
						custom_id: createdAssistRequest.id,
						name: `[${getShortId(
							createdAssistRequest.id
						)}] Atendimento - ${
							activeCompany?.name ?? activeUser.mail
						} (${appStateService.format.dateTime(
							createdAssistRequest.timestamp.toDate()
						)})`,
						// appStateService.format.dateTime(
						// createdAssistRequest.timestamp.toDate()
						markdown_description: description,
						priority: ClickUpPriority.Normal,
						status: ClickUpTicketStatus.EmAberto,
						tags: [...self.getDefaultTags(), ...self.getUserTags()],
						custom_fields: self.getTaskOwnerFields({
							email: activeUser.mail,
							name: activeUser.name,
							companyId: activeCompany?.id,
							companyName: activeCompany?.name
						})
					};

					clickUpTask = await this.createTask(
						clickUpTask,
						REACT_APP_CLICKUP_TICKET_QUEUE_ID
					);

					if (!clickUpTask) {
						throw Error(
							"Error-creating-user-support-ticket-clickup"
						);
					}

					createdAssistRequest.ticketQueueId = clickUpTask.id;

					// Adds the comment of the created task.
					await self.createTaskComment(clickUpTask.id, message);

					// Sets the assist ticket queue ID
					await self.chatAssist.updateItem(createdAssistRequest);

					ticketCreated = clickUpTask as IClickUpTask;

					if (typeof onComplete === "function") {
						onComplete(ticketCreated);
					}
				}
			)
			.catch((error) => {
				const errorMessage: string = `Error-creating-user-support-ticket: ${error}`;

				if (process.env.NODE_ENV === "development") {
					console.error(errorMessage);
				}

				//Logging the unsuccessful operation
				appStateService.error.handleError(error, errorMessage);

				return null;
			});

		return ticketCreated;
	}

	/**
	 *
	 * @param taskId
	 * @returns
	 */
	async getTaskMessages(
		taskId: string
	): Promise<null | { comments: IClickUpTaskComment[] }> {
		//${this.baseURL}/task/${taskId}/comment

		const url = `${this.baseURL}/task/${taskId}/comment`;

		const result = await fetch(url, this.getRequestHeaders("GET"));

		if (!result.ok) {
			const errorMessage: string = `Error-getting-task-comments-from-clickup: Status ${result.status} ${result.statusText}`;

			if (process.env.NODE_ENV === "development") {
				console.error(errorMessage);
			}

			//Logging the unsuccessful operation
			appStateService.error.handleError(
				Error(errorMessage),
				errorMessage
			);

			return await result.json();
		}

		const _data = await result.json();

		return _data;
	}

	/**
	 *
	 * @param userMail
	 * @returns
	 */
	async getUserTickets(
		userMail: string
	): Promise<any | IClickUpGetUserTasksResponse> {
		const state = store.getState();
		const activeCompanyId =
			UserSelectors.selectUserProfileActiveCompany(state);
		const activeCompany = CompanySelectors.getCompanyById(
			state,
			activeCompanyId
		);
		let _url = `${this.baseURL}/list/${REACT_APP_CLICKUP_TICKET_QUEUE_ID}/task?tags[]=${userMail}`;

		if (activeCompany?.id) {
			_url += `&custom_fields=[{"field_id":"${ClickUpTaskFieldId.userCompanyId}","operator":"=","value":"${activeCompany.id}"}]`;
		}
		const _requesHeaders = this.getRequestHeaders("GET");
		const result = await fetch(_url, _requesHeaders);

		if (!result.ok) {
			const errorMessage: string = `Error-getting-user-tickets-clickup: Status ${result.status} ${result.statusText}`;

			if (process.env.NODE_ENV === "development") {
				console.error(errorMessage);
			}

			//Logging the unsuccessful operation
			appStateService.error.handleError(
				Error(errorMessage),
				errorMessage
			);

			return await result.json();
		}

		const _data = await result.json();

		return _data as IClickUpGetUserTasksResponse;
	}

	/**
	 * Creates a comment under a given task, by its ID.
	 *
	 * @param taskId
	 * @param comment
	 * @returns
	 */
	async createTaskComment(
		taskId: string,
		comment: string
	): Promise<{
		date: number;
		hist_id: string;
		id: number;
		version: {
			object_type: string;
			date_created: number;
		};
	}> {
		const userProfile = UserSelectors.selectUserProfile(store.getState());
		const _url = `${this.baseURL}/task/${taskId}/comment`;
		const _requesHeaders = this.getRequestHeaders(
			"POST",
			JSON.stringify({
				comment_text: comment
			})
		);
		const result = await fetch(_url, _requesHeaders);

		if (!result.ok) {
			const errorMessage: string = `Error-adding-task-comment-clickup: Status ${result.status} ${result.statusText}`;

			if (process.env.NODE_ENV === "development") {
				console.error(errorMessage);
			}

			//Logging the unsuccessful operation
			appStateService.error.handleError(
				Error(errorMessage),
				errorMessage
			);

			return await result.json();
		}

		const _data = await result.json();

		return _data;
	}
}
