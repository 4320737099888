import React from "react";
import { GiftBoxOpenIcon } from "components/shared/icon";
import { useTranslateFn } from "hooks/i18n";

import "./LoaderScreen.scss";

/**
 * Props for the LoaderScreen component
 */
export interface ILoaderScreenProps {
	authenticated?: boolean;
}

/**
 * Renders a loading screen with an icon and text
 */
export const LoaderScreen = ({ authenticated }: ILoaderScreenProps) => {
	const translate = useTranslateFn();
	const strings = {
		loading: {
			authenticated: translate("loading.authenticated"),
			unauthenticated: translate("loading.unauthenticated")
		}
	};

	return (
		<div className="App-loading">
			<div className="loading-icon">
				<GiftBoxOpenIcon />
			</div>
			<div className="loading-text">
				{
					strings.loading[
						authenticated ? "authenticated" : "unauthenticated"
					]
				}
			</div>
		</div>
	);
};
