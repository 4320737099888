import { FirebaseError } from "firebase/app";
import { AppManagerServiceInstance } from "services/appManager/AppManagerService";
import { AuthServiceInstance } from "services/auth/AuthService";

/**
 * Contract for the Error management service structure.
 */
interface IErrorService {
	handleError: (
		error: Error | { message: string },
		message?: undefined | string
	) => Promise<void>;
	handleCriticalError: (
		error: Error | { message: string },
		message?: undefined | string
	) => Promise<void>;
	handleUnauthenticatedError: (error: Error | { message: string }) => void;
}

/**
 * Service for handling with system errors in general
 */
export class ErrorService implements IErrorService {
	private _skipFilteredErrors: string[] = ["permission-denied"];
	private _unauthenticatedErrors: string[] = ["functions/unauthenticated"];

	handleUnauthenticatedError(error: Error) {
		if (process.env.NODE_ENV === "development") {
			console.error("dev-handleUnauthenticatedError", error);
		}

		if (!AuthServiceInstance.getFromLocalState().authenticated) {
			AuthServiceInstance.logOff();
		}
	}

	async handleError(
		error: Error | FirebaseError,
		message?: undefined | string
	) {
		const isFirebaseError = error instanceof FirebaseError;

		if (process.env.NODE_ENV === "development") {
			console.error("dev-handleError", error);
		}

		// If the error is in the filtered list, do not show it
		if (
			this._skipFilteredErrors.find((e) => error.message.includes(e)) ||
			(isFirebaseError &&
				this._skipFilteredErrors.find((e) => error.code.includes(e)))
		) {
			// Skip the error
			return;
		} else if (
			isFirebaseError &&
			this._unauthenticatedErrors.find(
				(e) => error.code.includes(e) || error.message.includes(e)
			)
		) {
			this.handleUnauthenticatedError(error);
			return;
		}

		AppManagerServiceInstance.showError(message ? message : error.message);
	}

	async handleCriticalError(error: Error, message?: undefined | string) {
		const isFirebaseError = error instanceof FirebaseError;
		if (process.env.NODE_ENV === "development") {
			console.error("dev-handleCriticalError", error);
		}

		// If the error is in the filtered list, do not show it
		if (
			this._skipFilteredErrors.find((e) => error.message.includes(e)) ||
			(isFirebaseError &&
				this._skipFilteredErrors.find((e) => error.code.includes(e)))
		) {
			return;
		} else if (
			isFirebaseError &&
			this._unauthenticatedErrors.find((e) => error.code.includes(e))
		) {
			this.handleUnauthenticatedError(error);
			return;
		}

		AppManagerServiceInstance.showError(message ? message : error.message);
	}
}

export const ErrorServiceInstance = new ErrorService();
