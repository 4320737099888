import { appStateService, store } from "App";
import { ChatInstance, IAIModelProcess } from "models/index";
import {
	ChatCompletionCreateParamsNonStreaming,
	ChatCompletionMessageParam
} from "openai/resources";
import { UserSelectors } from "redux/selectors";
import { FirebaseServiceHandler } from "services/firebase/FirebaseServiceHandler";
import {
	OpenAIModelVersion,
	OpenAIService
} from "services/openai/OpenAIService";
import { TrainingData } from "../training";
import { actions } from "redux/reducers/ai/chat/reducer";

/**
 * Class for managing and consuming Chat Services from OpenAI API.
 */
class OpenAIChatService extends FirebaseServiceHandler<ChatInstance> {
	_defaultMessages: ChatCompletionMessageParam[] = [
		{
			role: "system",
			content: TrainingData.assumptions.reduce(
				(prev, current) => `${prev} ${current}`
			)
		}
	];

	constructor() {
		super("aiChats", appStateService.firestore, appStateService.error);
		appStateService.logger.log("OpenAI Chat Service initiated.");
	}

	/**
	 * Gets the user ID from the auth state.
	 *
	 * @returns The user ID or null if not authenticated.
	 */
	override getUserId(): null | string {
		const userId: string =
			appStateService.auth.getFromLocalState()?.profile.id ?? "";

		if (!userId || userId === "") {
			return null;
		}

		return userId;
	}

	// /**
	//  * Sends a message to the OpenAI API.
	//  * Also, stores the interaction in the database.
	//  *
	//  * @param message The message to be sent as a prompt.
	//  * @param modelVersion [Optional] The model version to be used.
	//  * @returns A promise with the response.
	//  */
	// async sendMessages(
	// 	messages: string[],
	// 	modelVersion:
	// 		| undefined
	// 		| OpenAIModelVersion = OpenAIModelVersion.GPT3_1106
	// ): Promise<string> {
	// 	const userId =
	// 		UserSelectors.selectUserProfileId(store.getState()) ?? "anonymous";

	// 	const gptResponse = await OpenAIService.chat.completions.create({
	// 		user: userId,
	// 		messages: [
	// 			{
	// 				role: "system",
	// 				content:
	// 					"Você é um(a) ótimo(a) assistente e está " +
	// 					"recebendo algumas mensagens para responder"
	// 			},
	// 			...messages.map((msg) => ({ role: "user", content: msg }))
	// 		],
	// 		model: modelVersion
	// 	});

	// 	const response =
	// 		gptResponse.choices.length > 0
	// 			? gptResponse.choices[0].message.content
	// 			: "Error: No response.";

	// 	return response;
	// }

	/**
	 * Sends a message to the OpenAI API.
	 * Also, stores the interaction in the database.
	 *
	 * @param message The message to be sent as a prompt.
	 * @param modelId The model id to be used.
	 * @param openAIModelVersion [Optional] The model version to be used.
	 * @returns A promise with the response.
	 */
	async sendMessage(
		message: string,
		modelId: string = "bee-ai-chat",
		openAIModelVersion:
			| undefined
			| OpenAIModelVersion = OpenAIModelVersion.GPT3_1106
	): Promise<string> {
		const modelProcessService =
			appStateService.company.aiModelProcess.get();
		const userId =
			UserSelectors.selectUserProfileId(store.getState()) ?? "anonymous";
		const input = message.trim();
		const completionData: ChatCompletionCreateParamsNonStreaming = {
			user: userId,
			messages: [
				...(this._defaultMessages.length > 0
					? this._defaultMessages
					: []),
				{ role: "user", name: "User", content: input }
			],
			model: openAIModelVersion
		};
		let modelProcessData: IAIModelProcess;

		try {
			modelProcessData = await modelProcessService.logModelProcess(
				input,
				userId,
				modelId,
				openAIModelVersion
			);

			// Advances to processing state immediately
			await modelProcessService.logModelProcessProcessing(
				modelProcessData
			);

			const gptResponse = await OpenAIService.chat.completions.create({
				...completionData
			});

			const response =
				gptResponse.choices.length > 0
					? gptResponse.choices[0].message.content
					: "Error: No response.";

			// Advances to processed state immediately
			await modelProcessService.logModelProcessCompletion(
				modelProcessData,
				response,
				gptResponse.usage
			);

			return response;
		} catch (error) {
			// Sets an error state to the entry processing
			await modelProcessService.logModelProcessError(
				modelProcessData,
				error
			);

			appStateService.logger.error(error);
			throw error;
		}
	}

	/**
	 * Sends an Image as a message to the OpenAI API,
	 * for image understanding.
	 *
	 * @param query The query to be sent as a prompt.
	 * @param image The image to be sent as a message input.
	 * @param modelId The model id to be used.
	 * @param openAIModelVersion [Optional] The model version to be used.
	 * @returns A promise with the response.
	 */
	async sendImage(
		query: string,
		image: File | Blob,
		modelId: string,
		openAIModelVersion: OpenAIModelVersion = OpenAIModelVersion.GPT4_VISION
	): Promise<string> {
		const modelProcessService =
			appStateService.company.aiModelProcess.get();
		const userId =
			UserSelectors.selectUserProfileId(store.getState()) ?? "anonymous";
		const imageBase64 = atob(await image.text());
		let modelProcessData: IAIModelProcess;

		try {
			modelProcessData = await modelProcessService.logModelProcess(
				query,
				userId,
				modelId,
				openAIModelVersion
			);

			// Advances to processing state immediately
			await modelProcessService.logModelProcessProcessing(
				modelProcessData
			);

			const gptResponse = await OpenAIService.chat.completions.create({
				user: userId,
				model: openAIModelVersion,
				messages: [
					{
						role: "user",
						name: "User",
						content: [
							{ type: "text", text: query },
							{
								type: "image_url",
								image_url: {
									url: `data:${
										typeof image === "string"
											? "base64"
											: image.type
									};base64,${imageBase64}`
								}
							}
						]
					}
				]
			});

			const response =
				gptResponse.choices.length > 0
					? gptResponse.choices[0].message.content
					: "Error: No response.";

			// Advances to processed state immediately
			await modelProcessService.logModelProcessCompletion(
				modelProcessData,
				response,
				gptResponse.usage
			);

			return response;
		} catch (error) {
			// Logging the error to the database
			await modelProcessService.logModelProcessError(
				modelProcessData,
				error
			);

			appStateService.logger.error(error);
			throw error;
		}
	}

	/**
	 * Sets a given ChatInstance as the active one in the state layer.
	 *
	 * @param chat The ChatInstance to be set as active. If null, it will clear the state portion.
	 */
	setActiveChat(chat: null | ChatInstance): void {
		store.dispatch(actions.setActiveChat(chat));
	}
}

export { OpenAIChatService };
