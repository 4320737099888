// features/chat/chatSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxSlices } from "redux/slices";
import {
	IChatAssist,
	IClickUpTask,
	IClickUpTaskComment,
	chatAssistInitialState as initialState,
	OperationalHours
} from "models";
import { ISetEditingAction } from "interfaces/actions";
import { editListItemById } from "redux/reducers/shared";

/**
 * Chat Assist Redux slice config.
 */
const chatAssistSlice = createSlice({
	name: ReduxSlices.ChatAssist,
	initialState,
	reducers: {
		toggleChat: (state) => {
			state.isOpen = !state.isOpen;
			if (!state.isOpen) {
				// Reset form when closing
				state.data.message = "";
				state.error = null;
				state.success = false;
			}
		},
		setMessage: (state, action: PayloadAction<string>) => {
			state.data.message = action.payload;
		},
		submitStart: (state) => {
			state.loading = true;
			state.error = null;
			state.success = false;
		},
		submitSuccess: (state) => {
			state.loading = false;
			state.success = true;
			state.data.message = "";
		},
		submitFailure: (state, action: PayloadAction<string>) => {
			state.loading = false;
			state.error = action.payload;
		},
		setList: (state, action: PayloadAction<IChatAssist[]>) => {
			state.list = action.payload;
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		setQueried: (state, action: PayloadAction<boolean>) => {
			state.queried = action.payload;
			state.loading = false;
		},
		setEditing: (
			state,
			action: PayloadAction<ISetEditingAction<IChatAssist>>
		) => {
			state.editing = action.payload.editing;
			state.editingList = action.payload.items;
		},
		setEditByID: (state, action: PayloadAction<IChatAssist>) => {
			editListItemById(state.list, action.payload.id, action.payload);
		},
		setEditingList: (state, action: PayloadAction<IChatAssist[]>) => {
			state.editingList = action.payload;
		},
		resetEditing: (state) => {
			state.editing = false;
			state.editingList = [];
		},
		reset: (state) => {
			state.editing = initialState.editing;
			state.editingList = initialState.editingList;
			state.list = initialState.list;
			state.loading = initialState.loading;
			state.queried = initialState.queried;
			state.submitting = initialState.submitting;
		},
		setOperationalHours: (
			state,
			action: PayloadAction<OperationalHours>
		) => {
			state.operationalHours = action.payload;
		},
		updateOnlineStatus: (state, action: PayloadAction<boolean>) => {
			state.isOnline = action.payload;
		},
		setMessagesByTicketId: (
			state,
			action: PayloadAction<{
				ticketId: string;
				messages: IClickUpTaskComment[];
			}>
		) => {
			if (
				Object.keys(state.ticketMessages).includes(
					action.payload.ticketId
				)
			) {
				state.ticketMessages[action.payload.ticketId] = [
					...action.payload.messages
				];
			} else {
				state.ticketMessages = {
					...state.ticketMessages,
					[action.payload.ticketId]: [...action.payload.messages]
				};
			}
		},
		setLoadingTicket: (state, action: PayloadAction<boolean>) => {
			state.loadingTickets = action.payload;
		},
		setQueriedTicket: (state, action: PayloadAction<boolean>) => {
			state.queriedTickets = action.payload;
		},
		setTickets: (state, action: PayloadAction<IClickUpTask[]>) => {
			state.tickets = action.payload;
		},
		setActiveTicket: (state, action: PayloadAction<string>) => {
			state.activeTicket = action.payload ?? "";
		},
		setShowHistory: (state, action: PayloadAction<boolean>) => {
			state.showHistory = action.payload;
		},
		setSubmitting: (state, action: PayloadAction<boolean>) => {
			state.submitting = action.payload;
		}
		// setError: (state, action: PayloadAction<string>) => {
		// 	state.error = action.payload;
		// }
	}
});

export const { reducer, actions } = chatAssistSlice;

export default reducer;
