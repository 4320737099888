import { IAppManagerService } from "interfaces/IAppManagerService";
import { LazyService } from "models/service/LazyService";
import { Firestore } from "firebase/firestore/lite";
import { StoreType } from "types/StoreType";
// import { RootState } from "redux/store";

/* 1st level services */
// import { AuthService } from "./auth/AuthService";
import { UserService } from "./user/UserService";
import { formatServices, FormatServicesFixture } from "./format/FormatService";
import { AppManagerServiceInstance } from "./appManager/AppManagerService";

/* 2nd level services */
import { FirebaseService, firebaseService } from "./firebase/FirebaseService";
import { CategoryService } from "./categories/CategoryService";
import { ProductService } from "./products/ProductService";
import { SupplierService } from "./suppliers/SupplierService";
import { BrandService } from "./brands/BrandService";
import { ViaCEPService } from "./postalCode/ViaCEPService";
import { LandingMailService } from "services/landingPage/LandingMailService";
import { OrderFormService } from "services/orderForm/OrderFormService";
import { ClientService } from "./clients/ClientService";
import { StockService } from "./stock/StockService";
import { SentryService, SentryServiceInstance } from "./sentry/SentryService";
import { OpenAIChatService, OpenAITextCompletionService } from "./openai";
import { FirebaseStorageService } from "./firebase/FirebaseStorageService";
import { CompanyAIModelProcessService } from "./ai/model/CompanyAIModelProcessService";
import { LazyServiceStarter } from "models/service/LazyServiceStarter";
import { CompanyService } from "./companies/CompanyService";
import { CustomerService } from "./customers/CustomerService";

// Firebase functions services
import { UserSignUpService } from "./firebase/functions/request/UserSignUp";
import { GetPublicCompanyMenuService } from "./firebase/functions/request/GetPublicCompanyMenu";
import { SendReleaseAnnouncementService } from "./firebase/functions/request/SendReleaseAnnouncement";
import { CreateUserAccountService } from "./firebase/functions/request/CreateUserAccount";
import { GetAppConfigService } from "./firebase/functions/request/GetAppConfig";
import { GetSecretService } from "./firebase/functions/request/GetSecret";
import { ValidateCustomerCodeService } from "./firebase/functions/request/ValidateCustomerCode";
import { ReleaseMailService } from "./mail/ReleaseMailService";

import { ErrorService, ErrorServiceInstance } from "./error/errorService";
import {
	GoogleMapsLoader,
	GoogleMapsLoaderInstance
} from "./google/GoogleMapsLoader";
import { MailerService, MailerServiceInstance } from "./mail/MailerService";
import { ClickUpService } from "./clickup/ClickUpService";

// New company-segmented services
import { CompanyProductService } from "./products/CompanyProductService";
import { CompanyBrandService } from "./brands/CompanyBrandService";
import { CompanyCategoryService } from "./categories/CompanyCategoryService";
import { CompanyClientService } from "./clients/CompanyClientService";
import { CompanySupplierService } from "./suppliers/CompanySupplierService";
import { CompanyStockService } from "./stock/CompanyStockService";
import { CompanyStockMovementService } from "./stock/CompanyStockMovementService";
import { CompanyOrderService } from "./order/CompanyOrderService";
import { CompanyStoreService } from "./store/StoreService";
import { CompanyLinkService } from "./links/CompanyLinkService";
import { CompanyOpenAIChatService } from "./openai/chat/CompanyOpenAIChatService";
import { CompanyClientTableService } from "./clients/CompanyClientTableService";
import { CompanyCalendarService } from "./calendar/CompanyCalendarService";
import { CompanyChatAssistService } from "./chatAssist/CompanyChatAssistService";

// Payment service scopes
import { SumUpPaymentLazyService } from "./payment/integration/sumup/SumUpPaymentLazyService";
import { IAuthService } from "interfaces/services";

// Auth module options
//import { RemoteAuthService } from "./auth/RemoteAuthService";
import { AuthServiceInstance } from "./auth/AuthService";

/**
 * Type definition for the services that are available in the app.
 * Only allowed types are those listed here.
 */
declare type ServiceTypes =
	| StoreType
	| Firestore
	| FirebaseService
	| FirebaseStorageService
	| FormatServicesFixture
	| IAppManagerService
	| IAuthService
	| UserService
	| ViaCEPService
	| CategoryService
	| ProductService
	| SupplierService
	| BrandService
	| LandingMailService
	| OrderFormService
	| ClientService
	| StockService
	| SentryService
	| OpenAIChatService
	| CompanyOpenAIChatService
	| OpenAITextCompletionService
	| CompanyAIModelProcessService
	| UserSignUpService
	| CompanyProductService
	| CompanyService
	| CompanyBrandService
	| CompanyCategoryService
	| CompanyClientService
	| CompanySupplierService
	| CompanyStockService
	| CompanyStockMovementService
	| CompanyOrderService
	| CompanyStoreService
	| CompanyClientTableService
	| CompanyLinkService
	| CompanyChatAssistService
	| SumUpPaymentLazyService
	| MailerService
	| GoogleMapsLoader
	| ErrorService
	| SendReleaseAnnouncementService
	| GetPublicCompanyMenuService
	| CompanyCalendarService
	| GetAppConfigService
	| GetSecretService
	| CustomerService
	| ClickUpService;

/**
 * Contract for the Application's Service and State management layer.
 */
interface IStateServices {
	// Singletons
	_store: StoreType;
	firebase: FirebaseService;
	firestore: Firestore;
	firebaseStorage: FirebaseStorageService;
	format: FormatServicesFixture;
	logger: SentryService;
	error: ErrorService;
	mailer: LazyServiceStarter<MailerService>;

	// Transient services
	appManager: IAppManagerService;
	auth: IAuthService;
	user: UserService;
	viaCEP: ViaCEPService;
	googleMaps: LazyServiceStarter<GoogleMapsLoader>;
	openai: {
		chat: LazyService<OpenAIChatService>;
		text: LazyService<OpenAITextCompletionService>;
	};
	functions: {
		userSignUp: LazyService<UserSignUpService>;
		validateCustomerCode: LazyService<ValidateCustomerCodeService>;
		sendReleaseAnnouncement: LazyService<SendReleaseAnnouncementService>;
		createUserAccount: LazyService<CreateUserAccountService>;
		getPublicCompanyMenu: LazyService<GetPublicCompanyMenuService>;
		getAppConfig: LazyService<GetAppConfigService>;
		getSecret: LazyService<GetSecretService>;
	};
	company: {
		service: LazyServiceStarter<CompanyService>;
		brand: LazyServiceStarter<CompanyBrandService>;
		product: LazyServiceStarter<CompanyProductService>;
		category: LazyServiceStarter<CompanyCategoryService>;
		client: LazyServiceStarter<CompanyClientService>;
		clientTable: LazyServiceStarter<CompanyClientTableService>;
		supplier: LazyServiceStarter<CompanySupplierService>;
		stock: LazyServiceStarter<CompanyStockService>;
		stockMovement: LazyServiceStarter<CompanyStockMovementService>;
		order: LazyServiceStarter<CompanyOrderService>;
		aiModelProcess: LazyServiceStarter<CompanyAIModelProcessService>;
		aiChat: LazyServiceStarter<CompanyOpenAIChatService>;
		store: LazyServiceStarter<CompanyStoreService>;
		links: LazyServiceStarter<CompanyLinkService>;
		calendar: LazyServiceStarter<CompanyCalendarService>;
		chatAssist: LazyServiceStarter<CompanyChatAssistService>;
	};
	payment: {
		sumup: SumUpPaymentLazyService;
	};

	// 2nd Level services (Lazy)
	landingMail: LazyService<LandingMailService>;
	orderForm: LazyService<OrderFormService>;
	customer: LazyService<CustomerService>;
	clickUp: LazyService<ClickUpService>;
	// aiModelProcess: LazyService<AIModelProcessService>;
}

/**
 * Class for managing the access to a State Layer in the app.
 */
class StateService implements IStateServices {
	_store: StoreType;
	firebase: FirebaseService;
	firestore: Firestore;
	firebaseStorage: FirebaseStorageService;
	format: FormatServicesFixture;
	logger: SentryService;
	error: ErrorService;
	mailer: LazyServiceStarter<MailerService>;

	// Transient services
	appManager: IAppManagerService;
	auth: IAuthService;
	user: UserService;
	viaCEP: ViaCEPService;
	googleMaps: LazyServiceStarter<GoogleMapsLoader>;
	openai: {
		chat: LazyService<OpenAIChatService>;
		text: LazyService<OpenAITextCompletionService>;
	};
	functions: {
		userSignUp: LazyService<UserSignUpService>;
		validateCustomerCode: LazyService<ValidateCustomerCodeService>;
		sendReleaseAnnouncement: LazyService<SendReleaseAnnouncementService>;
		createUserAccount: LazyService<CreateUserAccountService>;
		getPublicCompanyMenu: LazyService<GetPublicCompanyMenuService>;
		getAppConfig: LazyService<GetAppConfigService>;
		getSecret: LazyService<GetSecretService>;
	};
	company: {
		service: LazyServiceStarter<CompanyService>;
		brand: LazyServiceStarter<CompanyBrandService>;
		product: LazyServiceStarter<CompanyProductService>;
		category: LazyServiceStarter<CompanyCategoryService>;
		client: LazyServiceStarter<CompanyClientService>;
		clientTable: LazyServiceStarter<CompanyClientTableService>;
		supplier: LazyServiceStarter<CompanySupplierService>;
		stock: LazyServiceStarter<CompanyStockService>;
		stockMovement: LazyServiceStarter<CompanyStockMovementService>;
		order: LazyServiceStarter<CompanyOrderService>;
		aiModelProcess: LazyServiceStarter<CompanyAIModelProcessService>;
		aiChat: LazyServiceStarter<CompanyOpenAIChatService>;
		store: LazyServiceStarter<CompanyStoreService>;
		links: LazyServiceStarter<CompanyLinkService>;
		calendar: LazyServiceStarter<CompanyCalendarService>;
		chatAssist: LazyServiceStarter<CompanyChatAssistService>;
	};
	payment: {
		sumup: SumUpPaymentLazyService;
	};

	// 2nd level services (Lazy)
	landingMail: LazyService<LandingMailService>;
	orderForm: LazyService<OrderFormService>;
	customer: LazyService<CustomerService>;
	clickUp: LazyService<ClickUpService>;
	// aiModelProcess: LazyServiceStarter<AIModelProcessService>;

	releaseMail: ReleaseMailService;

	constructor(reduxStore: StoreType) {
		// Singleton services
		this._store = reduxStore;
		this.firebase = firebaseService;
		// this.auth = new RemoteAuthService(reduxStore);
		this.auth = AuthServiceInstance; // new AuthService(reduxStore);
		this.firestore = firebaseService.firestore;
		this.firebaseStorage = new FirebaseStorageService();
		this.format = formatServices;
		this.logger = SentryServiceInstance;
		this.error = ErrorServiceInstance;
		this.mailer = new LazyServiceStarter(() => MailerServiceInstance);

		// Transient services
		this.appManager = AppManagerServiceInstance;
		this.user = new UserService(reduxStore);
		this.viaCEP = new ViaCEPService();
		this.googleMaps = new LazyServiceStarter(
			() => GoogleMapsLoaderInstance
		);
		this.openai = {
			chat: new LazyService(OpenAIChatService),
			text: new LazyService(OpenAITextCompletionService)
		};
		this.functions = {
			userSignUp: new LazyService(UserSignUpService),
			validateCustomerCode: new LazyService(ValidateCustomerCodeService),
			sendReleaseAnnouncement: new LazyService(
				SendReleaseAnnouncementService
			),
			createUserAccount: new LazyService(CreateUserAccountService),
			getPublicCompanyMenu: new LazyService(GetPublicCompanyMenuService),
			getAppConfig: new LazyService(GetAppConfigService),
			getSecret: new LazyService(GetSecretService)
		};

		// Scoped services
		this.landingMail = new LazyService(LandingMailService);
		this.orderForm = new LazyService(OrderFormService);
		this.customer = new LazyService(CustomerService);
		this.clickUp = new LazyService(ClickUpService);

		// Company-dependent services
		// const companyId = this.user.getCompanyId();
		this.company = {
			service: new LazyServiceStarter(() => new CompanyService()),
			brand: new LazyServiceStarter(
				() => new CompanyBrandService(this.user.getActiveCompany())
			),
			product: new LazyServiceStarter(
				() => new CompanyProductService(this.user.getActiveCompany())
			),
			category: new LazyServiceStarter(
				() => new CompanyCategoryService(this.user.getActiveCompany())
			),
			client: new LazyServiceStarter(
				() => new CompanyClientService(this.user.getActiveCompany())
			),
			clientTable: new LazyServiceStarter(
				() =>
					new CompanyClientTableService(this.user.getActiveCompany())
			),
			supplier: new LazyServiceStarter(
				() => new CompanySupplierService(this.user.getActiveCompany())
			),
			stock: new LazyServiceStarter(
				() => new CompanyStockService(this.user.getActiveCompany())
			),
			stockMovement: new LazyServiceStarter(
				() =>
					new CompanyStockMovementService(
						this.user.getActiveCompany()
					)
			),
			order: new LazyServiceStarter(
				() => new CompanyOrderService(this.user.getActiveCompany())
			),
			aiModelProcess: new LazyServiceStarter(
				() =>
					new CompanyAIModelProcessService(
						this.user.getActiveCompany()
					)
			),
			aiChat: new LazyServiceStarter(
				() => new CompanyOpenAIChatService(this.user.getActiveCompany())
			),
			store: new LazyServiceStarter(
				() => new CompanyStoreService(this.user.getActiveCompany())
			),
			links: new LazyServiceStarter(
				() => new CompanyLinkService(this.user.getActiveCompany())
			),
			calendar: new LazyServiceStarter(
				() => new CompanyCalendarService(this.user.getActiveCompany())
			),
			chatAssist: new LazyServiceStarter(
				() => new CompanyChatAssistService(this.user.getActiveCompany())
			)
		};

		// Payment services
		this.payment = {
			sumup: new SumUpPaymentLazyService()
		};

		this.releaseMail = ReleaseMailService.getInstance();
	}

	/**
	 * Restarts a service, based on a type, individually.
	 *
	 * @param serviceType
	 * @param restartProcedureFn
	 */
	restartService<T extends ServiceTypes>(
		servicePropName: string,
		restartProcedureFn: () => T
	): void {
		this[servicePropName] = restartProcedureFn();
	}
}

export { StateService };
