import { ModuleActionIds } from "../actions";
import { AppModuleNames } from "../AppModules";
import { IAppProfile } from "./IAppProfile";

/**
 * The application Basic Plan Profile config.
 * Some modules, and actions allowed only.
 */
export const AppBasicPlanProfile: IAppProfile = {
	appModules: [
		AppModuleNames.basic,
		AppModuleNames.dashboard,
		// AppModuleNames.orderExternal,
		AppModuleNames.orderManagement,
		// AppModuleNames.stockManagement,
		AppModuleNames.storeFront,
		AppModuleNames.calendar
		// AppModuleNames.supplierManagement
		// AppModuleNames.tableService
	],
	moduleActions: [ModuleActionIds.brandsListView]
};
