import { IdentifiedItem } from "interfaces/index";

/**
 * Helper function to edit an item in a list, based
 * on config params.
 *
 * @param list The list of items to work on
 * @param itemId The item updating with its ID
 * @param itemUpdated The updated item data
 * @returns
 */
export const editListItemById = <T extends IdentifiedItem>(
	list: T[],
	itemId: string,
	itemUpdated: T
): T[] => {
	const index = list.findIndex((listItem) => listItem.id === itemId);
	if (index >= 0) {
		list[index] = itemUpdated;
	}
	return list;
};
