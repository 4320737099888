import { ICustomer } from "models/index";
import { actions } from "redux/reducers/customers/reducer";
import { whereLike } from "services/firebase/helpers";
import { ReduxStoreService } from "services/redux";

const _listName = "customer";

/**
 * Class for managing the basics of the Customer data.
 * Manages the Redux store, as well as the Firebase database modifications.
 * Also, registers logs of the operations.
 */
class CustomerService extends ReduxStoreService<ICustomer> {
	constructor() {
		super(_listName, actions.setList, actions.setQueried);
	}

	/**
	 * Search customers by specified properties
	 * @param text The text to search for
	 * @param props The properties to search in
	 * @param onResolve Optional callback when search completes
	 * @returns Promise with the list of matching customers
	 */
	async searchCustomers(
		text: string,
		props: string[],
		onResolve: undefined | ((items: ICustomer[]) => void)
	): Promise<ICustomer[]> {
		const customers = Promise.all(
			props.map(async (propKey) => {
				const items = await this.queryItems(whereLike(propKey, text));
				return items;
			})
		).then((items) => {
			const _items = this.unifyLists(items);

			if (onResolve) {
				onResolve(_items);
			}

			return _items;
		});

		return customers;
	}

	/**
	 * Search customers by name and email
	 * @param text The text to search for
	 * @param onResolve Optional callback when search completes
	 * @returns Promise with the list of matching customers
	 */
	async searchCustomersByNameEmail(
		text: string,
		onResolve: undefined | ((items) => void) = (items: ICustomer[]) => items
	): Promise<ICustomer[]> {
		return await this.searchCustomers(text, ["name", "email"], onResolve);
	}

	/**
	 * Get a customer by email
	 * @param email The email to search for
	 * @returns Promise with the customer or undefined if not found
	 */
	async getByEmail(email: string): Promise<ICustomer | undefined> {
		const customers = await this.queryItems(whereLike("email", email));
		return customers.find(
			(customer) => customer.email.toLowerCase() === email.toLowerCase()
		);
	}
}

export { CustomerService };
