/**
 * Defines which are the System's module names.
 */
export enum AppModuleNames {
	admin = "admin",
	basic = "basic",
	brands = "brands",
	dashboard = "dashboard",
	orderManagement = "orderManagement",
	orderProduction = "orderProduction",
	orderExternal = "orderExternal",
	stockManagement = "stockManagement",
	storeFront = "storeFront",
	supplierManagement = "supplierManagement",
	tableService = "tableService",
	calendar = "calendar"
}

/**
 * Contract for the AppModules object configuration
 */
export interface IAppModules {
	[AppModuleNames.admin]: {
		enabled: boolean;
		started: boolean;
	};
	[AppModuleNames.basic]: {
		enabled: boolean;
		started: boolean;
	};
	[AppModuleNames.brands]: {
		enabled: boolean;
		started: boolean;
	};
	[AppModuleNames.dashboard]: {
		enabled: boolean;
		started: boolean;
	};
	[AppModuleNames.orderExternal]: {
		enabled: boolean;
		started: boolean;
	};
	[AppModuleNames.orderManagement]: {
		enabled: boolean;
		started: boolean;
	};
	[AppModuleNames.orderProduction]: {
		enabled: boolean;
		started: boolean;
	};
	[AppModuleNames.stockManagement]: {
		enabled: boolean;
		started: boolean;
	};
	[AppModuleNames.storeFront]: {
		enabled: boolean;
		started: boolean;
	};
	[AppModuleNames.supplierManagement]: {
		enabled: boolean;
		started: boolean;
	};
	[AppModuleNames.tableService]: {
		enabled: boolean;
		started: boolean;
	};
	[AppModuleNames.calendar]: {
		enabled: boolean;
		started: boolean;
	};
	isModuleEnabled: (moduleName: AppModuleNames) => boolean;
	applyProfile: (appModulesEnabled: AppModuleNames[]) => void;
}

/**
 * The AppModules object, which contains the modules
 * and their configurations individually.
 */
export const AppModules: IAppModules = {
	[AppModuleNames.admin]: {
		enabled: true,
		started: false
	},
	[AppModuleNames.basic]: {
		enabled: true,
		started: false
	},
	[AppModuleNames.brands]: {
		enabled: true,
		started: false
	},
	[AppModuleNames.dashboard]: {
		enabled: true,
		started: false
	},
	[AppModuleNames.orderExternal]: {
		enabled: true,
		started: false
	},
	[AppModuleNames.orderManagement]: {
		enabled: true,
		started: false
	},
	[AppModuleNames.orderProduction]: {
		enabled: true,
		started: false
		// TODO: Create selector from user account
	},
	[AppModuleNames.stockManagement]: {
		enabled: true,
		started: false
	},
	[AppModuleNames.storeFront]: {
		enabled: true,
		started: false
	},
	[AppModuleNames.supplierManagement]: {
		enabled: true,
		started: false
	},
	[AppModuleNames.tableService]: {
		enabled: true,
		started: false
	},
	[AppModuleNames.calendar]: {
		enabled: true,
		started: false
	},
	/**
	 * Check if a module is enabled.
	 *
	 * @param moduleName The module name.
	 * @returns enabled or not.
	 */

	isModuleEnabled: (moduleName: AppModuleNames) => {
		return AppModules[moduleName].enabled;
	},
	/**
	 * Apply a profile to the modules configuration.
	 *
	 * @param appModulesEnabled The modules to enable.
	 * @returns void.
	 **/
	applyProfile: (appModulesEnabled: AppModuleNames[]) => {
		Object.keys(AppModules).forEach((moduleName: string) => {
			if (AppModules[moduleName as AppModuleNames]) {
				AppModules[moduleName as AppModuleNames].enabled =
					appModulesEnabled.includes(moduleName as AppModuleNames);
			}
		});
	}
};
