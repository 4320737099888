/**
 * Contract for the possible languages state object.
 */
export interface i18nState {
	language: string;
}

/**
 * The possible languages state object. This is the default state.
 */
export const i18n: i18nState = {
	language: "ptBR"
};

/**
 * Get the language from the i18n state.
 * @returns The language.
 */
export function getLang() {
	// TODO: Replace by redux state capturing
	return i18n.language;
}

/**
 * Custom hook for getting the language.
 * @returns The language.
 */
export function useLang() {
	const language = getLang();

	return language;
}
