import { createSelector } from "@reduxjs/toolkit";
import {
	IChatAssistSlice,
	IClickUpTask,
	IClickUpTaskComment,
	OperationalHours
} from "models/index";
import { RootState } from "redux/store";

/**
 * Selectors: ChatAssist.
 */
export const ChatAssistSelectors: {
	get: (state: RootState) => IChatAssistSlice;
	getIsOpen: (state: RootState) => boolean;
	getIsLoading: (state: RootState) => boolean;
	getSuccess: (state: RootState) => boolean;
	getError: (state: RootState) => string;
	getMessage: (state: RootState) => string;
	getOperationalHours: (state: RootState) => OperationalHours;
	getMessagesByTicketId: (
		state: RootState,
		ticketId: string
	) => IClickUpTaskComment[];
	getLoadingTickets: (state: RootState) => boolean;
	getQueriedTickets: (state: RootState) => boolean;
	getTickets: (state: RootState) => IClickUpTask[];
	getActiveTicketId: (state: RootState) => string;
	getActiveTicket: (state: RootState) => null | IClickUpTask;
	getTicketById: (state: RootState, ticketId: string) => null | IClickUpTask;
	getShowHistory: (state: RootState) => boolean;
	getSubmitting: (state: RootState) => boolean;
} = {
	get: (state: RootState): IChatAssistSlice => state.chatAssist,
	getIsOpen: (state: RootState): boolean =>
		ChatAssistSelectors.get(state).isOpen,
	getIsLoading: (state: RootState): boolean =>
		ChatAssistSelectors.get(state).loading,
	getSuccess: (state: RootState): boolean =>
		ChatAssistSelectors.get(state).success,
	getError: (state: RootState): string =>
		ChatAssistSelectors.get(state).error,
	getMessage: (state: RootState): string =>
		ChatAssistSelectors.get(state).data.message,
	getOperationalHours: (state: RootState): OperationalHours =>
		ChatAssistSelectors.get(state).operationalHours,
	getMessagesByTicketId: createSelector(
		(state: RootState) => ChatAssistSelectors.get(state),
		(_, ticketId: string) => ticketId,
		(state: IChatAssistSlice, ticketId: string): IClickUpTaskComment[] => {
			const keysPresent =
				state.ticketMessages !== null
					? Object.keys(state.ticketMessages)
					: [];

			if (keysPresent.length === 0 || !keysPresent.includes(ticketId)) {
				return [];
			}

			const _list = [...state.ticketMessages[ticketId]];

			return _list.sort((a, b) => {
				const dateA = new Date(parseInt(`${a.date}`));
				const dateB = new Date(parseInt(`${b.date}`));

				return dateA.getTime() - dateB.getTime();
			});
		}
	),
	getLoadingTickets: (state: RootState) => {
		return ChatAssistSelectors.get(state).loadingTickets;
	},
	getQueriedTickets: (state: RootState) => {
		return ChatAssistSelectors.get(state).loadingTickets;
	},
	getTickets: (state: RootState) => {
		return ChatAssistSelectors.get(state).tickets;
	},
	getActiveTicketId: (state: RootState) => {
		return ChatAssistSelectors.get(state).activeTicket;
	},
	getActiveTicket: (state: RootState) => {
		return state.chatAssist.activeTicket !== null &&
			state.chatAssist.activeTicket !== ""
			? ChatAssistSelectors.getTicketById(
					state,
					state.chatAssist.activeTicket
			  )
			: null;
	},
	getTicketById: createSelector(
		(state: RootState): IChatAssistSlice => {
			return ChatAssistSelectors.get(state);
		},
		(_, ticketId: string): string => ticketId,
		(chatAssistState: IChatAssistSlice, ticketId: string) => {
			return (
				chatAssistState.tickets.find((item) => item.id === ticketId) ??
				null
			);
		}
	),
	getShowHistory: (state: RootState) => {
		return ChatAssistSelectors.get(state).showHistory;
	},
	getSubmitting: (state: RootState) => {
		return ChatAssistSelectors.get(state).submitting;
	}
};
