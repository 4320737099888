import React from "react";
import { IClickUpTask } from "models/chatAssist";

import "./ChatAssistModal.scss";

/**
 * The contract of the Inline Ticket properties.
 */
export interface IInlineTicketDetailProps {
	activeTicket: null | IClickUpTask;
}

/**
 * The Inline ticket details component.
 */
export const InlineTicketDetail: React.FC<IInlineTicketDetailProps> = ({
	activeTicket
}) => {
	// TODO: Add translate support

	return activeTicket !== null ? (
		<>
			<div className="inline-detail">
				<dt>Status</dt>
				<dd
					className={`highlight`}
					style={{
						borderColor: `${activeTicket.status.color}`
					}}
				>
					{activeTicket.status.status}
				</dd>
			</div>
			{/* <div className="inline-detail">
				<dt>Status</dt>
				<dd>{activeTicket.status.status}</dd>
			</div> */}
		</>
	) : null;
};
