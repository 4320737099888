import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { AppRouter } from "components/router/AppRouter";
import { Header } from "components/shared/header/Header";
import { HeaderSpacer } from "components/shared/header";
import { IndependentComponents } from "components/shared/layer";
import { configAppModules, preloadUserProfile } from "components/modules";
import { useSelector } from "react-redux";
import { AppConfigSelectors, UserSelectors } from "redux/selectors";
import { LoaderScreen } from "components/shared/loader";
import { isExternalRoute } from "components/router/ModuleRouteGuard";
import { RouterFrame } from "components/router/RouterFrame";

import "./AppLayout.scss";
import { appStateService } from "App";

/**
 * Renders the basic structure of the React application,
 * Notice the className defines layout major styles, from imported AppLayout.css.
 */
export function AppLayout() {
	const location = useLocation();
	const authenticated = useSelector(UserSelectors.selectAuthenticated);
	const authenticating = useSelector(UserSelectors.selectAuthenticating);
	const userProfile = useSelector(UserSelectors.selectUserProfile);
	const profileLoaded = useSelector(
		AppConfigSelectors.getCompanyProfileLoaded
	);
	const profileLoading = useSelector(
		AppConfigSelectors.getCompanyProfileLoading
	);
	const isLoading = useSelector(AppConfigSelectors.getLoading);
	const activeCompanyId = useSelector(
		UserSelectors.selectUserProfileActiveCompany
	);

	// Check if current route is external
	const isExternal = isExternalRoute(location.pathname);

	const authenticatedButNotReady =
		authenticated && (!profileLoaded || profileLoading || isLoading);

	const authenticatingAndProtectedPage = authenticating && !isExternal;

	const showLoader =
		authenticatedButNotReady || authenticatingAndProtectedPage;

	// Effect to load profile when needed
	useEffect(() => {
		if (authenticated && !profileLoaded && !profileLoading) {
			// Configure the app modules
			preloadUserProfile();
		}

		// if (authenticating && !profileLoading && !profileLoaded) {
		// 	appStateService.auth.verifyLocalAuth();
		// 	debugger;
		// 	// 	(success) => {
		// 	// 	},
		// 	// 	() => {
		// 	// 		debugger;
		// 	// 	}
		// 	// );
		// }
	}, [authenticated, profileLoaded, profileLoading]);

	useEffect(() => {
		if (authenticated && profileLoaded && userProfile?.id) {
			// Configure the app modules
			configAppModules();
		}
	}, [authenticated, profileLoaded, userProfile, activeCompanyId]);

	return !showLoader ? (
		<div className="App">
			{/* Begin: Menu - Fixed part of the app */}
			<Header />
			{/* End: Menu - Fixed part of the app */}
			{/* Begin: Moving part of the app */}
			<HeaderSpacer />
			<RouterFrame>
				<AppRouter />
			</RouterFrame>
			{/* End: Moving part of the app */}
			{/* Begin: Grouping free components in the app */}
			<IndependentComponents />
			{/* End: Grouping free components in the app */}
		</div>
	) : (
		<LoaderScreen authenticated={authenticated} />
	);
}
