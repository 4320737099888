import { useCallback } from "react";
import { AppSoundProvider } from "static/index";

/**
 * Notification
 */
export interface INotificationHook {
	play: () => Promise<void>;
}

/**
 * Use notification
 * @returns Use notification
 */
export const useNotification = (): INotificationHook => {
	const onPlayNotificationSound = useCallback(async () => {
		const audio = new Audio(AppSoundProvider.orders.notification);

		audio.play().catch((error) => {
			console.warn("Error playing notification sound:", error);
		});
	}, []);

	return { play: onPlayNotificationSound };
};
