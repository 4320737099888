import {
	and as firestoreAnd,
	QueryFieldFilterConstraint,
	where
} from "firebase/firestore";
import { PropQueryCriteria } from "./firestoreQuery.types";

/**
 * Creates a new `and` constraint for Firestore queries.
 *
 * @param queryConstraints - The query constraints to combine.
 * @returns A new `and` constraint.
 */
export const and = (
	...queryConstraints: PropQueryCriteria[] | QueryFieldFilterConstraint[]
) => {
	const constraints = queryConstraints.map((constraint) => {
		return where(constraint[0], constraint[1], constraint[2]);
	});

	return firestoreAnd(...constraints);
};
