import { CalendarViewMode } from "components/page/calendar/shared/enum";
import { getShortId } from "components/shared/util";
import { CalendarItemType } from "enums";
import { Timestamp } from "firebase/firestore";
import { useTranslateFn } from "hooks/i18n";
import { ICalendarItem } from "interfaces/calendar/ICalendarItem";
import { CalendarItem } from "models/index";
import { IClientOrder } from "models/order";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "redux/reducers/calendar/reducer";
import { CalendarSelectors } from "redux/selectors";

/**
 * Interface for the calendar state and actions
 */
export interface IUseCalendar {
	showEvents: boolean;
	showOrders: boolean;
	showTasks: boolean;
	loadingEvents: boolean;
	loadingOrders: boolean;
	loadedEvents: boolean;
	loadedOrders: boolean;
	events: ICalendarItem[];
	orders: IClientOrder[];
	tasks: ICalendarItem[];
	selectedEvent: ICalendarItem;
	selectedMode: CalendarViewMode;
	selectedDate: Date;
	unifiedEvents: ICalendarItem[];
	setShowEvents: (value: boolean) => void;
	setShowOrders: (value: boolean) => void;
	setShowTasks: (value: boolean) => void;
	setLoadingEvents: (value: boolean) => void;
	setLoadingOrders: (value: boolean) => void;
	setLoadedEvents: (value: boolean) => void;
	setLoadedOrders: (value: boolean) => void;
	setEvents: (value: ICalendarItem[]) => void;
	setOrders: (value: IClientOrder[]) => void;
	setSelectedEvent: (value: ICalendarItem | null) => void;
	setSelectedMode: (value: CalendarViewMode) => void;
	setSelectedDate: (value: Date) => void;
}

/**
 * Hook to manage the calendar
 * @returns {IUseCalendar} The calendar state and actions
 */
export const useCalendar = (): IUseCalendar => {
	const translate = useTranslateFn();
	const dispatch = useDispatch();
	const showEvents = useSelector(CalendarSelectors.getShowEvents);
	const showOrders = useSelector(CalendarSelectors.getShowOrders);
	const showTasks = useSelector(CalendarSelectors.getShowTasks);

	/**
	 * Show/hide events in the calendar.
	 */
	const setShowEvents = useCallback(
		(value: boolean) => {
			dispatch(actions.setShowEvents(value));
		},
		[dispatch]
	);

	/**
	 * Show/hide orders in the calendar.
	 */
	const setShowOrders = useCallback(
		(value: boolean) => {
			dispatch(actions.setShowOrders(value));
		},
		[dispatch]
	);

	/**
	 * Show/hide tasks in the calendar.
	 */
	const setShowTasks = useCallback(
		(value: boolean) => {
			dispatch(actions.setShowTasks(value));
		},
		[dispatch]
	);

	// Loading controllers
	const loadingEvents = useSelector(CalendarSelectors.getLoadingEvents);
	const setLoadingEvents = useCallback(
		(value: boolean) => {
			dispatch(actions.setLoadingEvents(value));
		},
		[dispatch]
	);

	const loadingOrders = useSelector(CalendarSelectors.getLoadingOrders);
	const setLoadingOrders = useCallback(
		(value: boolean) => {
			dispatch(actions.setLoadingOrders(value));
		},
		[dispatch]
	);

	// Loaded controllers
	const loadedEvents = useSelector(CalendarSelectors.getLoadedEvents);
	const setLoadedEvents = useCallback(
		(value: boolean) => {
			dispatch(actions.setLoadedEvents(value));
		},
		[dispatch]
	);

	const loadedOrders = useSelector(CalendarSelectors.getLoadedOrders);
	const setLoadedOrders = useCallback(
		(value: boolean) => {
			dispatch(actions.setLoadedOrders(value));
		},
		[dispatch]
	);

	// Events, orders, and Tasks refs
	const events = useSelector(CalendarSelectors.getEvents);
	const setEvents = useCallback(
		(value: ICalendarItem[]) => {
			dispatch(actions.setEvents(value));
		},
		[dispatch]
	);

	const orders = useSelector(CalendarSelectors.getOrders);
	const setOrders = useCallback(
		(value: IClientOrder[]) => {
			dispatch(actions.setOrders(value));
		},
		[dispatch]
	);

	/**
	 * Tasks in the calendar.
	 */
	const tasks = useSelector(CalendarSelectors.getTasks);

	// Selected event controller
	const selectedEvent = useSelector(CalendarSelectors.getCurrentEvent);
	const setSelectedEvent = useCallback(
		(value: ICalendarItem | null) => {
			dispatch(actions.setCurrentEvent(value ?? new CalendarItem()));
		},
		[dispatch]
	);

	// Selected Mode controller
	const selectedMode = useSelector(CalendarSelectors.getSelectedMode);
	const setSelectedMode = useCallback(
		(value: CalendarViewMode) => {
			dispatch(actions.setSelectedMode(value));
		},
		[dispatch]
	);

	// Selected Date controller
	const selectedDate = useSelector(CalendarSelectors.getSelectedDate);
	const setSelectedDate = useCallback(
		(value: Date) => {
			dispatch(actions.setSelectedDate(value));
		},
		[dispatch]
	);

	// Unified view events + orders
	const unifiedEvents = useMemo(() => {
		// Cast orders to events and add to the return
		const orderEvents: ICalendarItem[] = orders
			.filter(
				(order) => order.deliveryDate && order.deliveryDate?.toDate()
			) // Only include orders with delivery dates
			.map((order: IClientOrder) => ({
				id: order.id,
				title: `${translate("calendar.type.order")}: ${getShortId(
					order.id
				)}`,
				description: "",
				startDate: order.deliveryDate,
				endDate: order.deliveryDate,
				type: CalendarItemType.Order,
				allDay: false,
				enabled: true,
				timestamp: Timestamp.now(),
				orderId: order.id
			}));

		const returnableEvents: ICalendarItem[] = [];

		if (showEvents) {
			returnableEvents.push(...events);
		}

		if (showOrders) {
			returnableEvents.push(...orderEvents);
		}

		if (showTasks) {
			returnableEvents.push(...tasks);
		}

		return returnableEvents;
	}, [events, orders, showEvents, showOrders, showTasks, tasks]);

	return {
		showEvents,
		showOrders,
		showTasks,
		loadingEvents,
		loadingOrders,
		loadedEvents,
		loadedOrders,
		events,
		orders,
		tasks,
		selectedEvent,
		selectedMode,
		selectedDate,
		unifiedEvents,
		setShowEvents,
		setShowOrders,
		setShowTasks,
		setLoadingEvents,
		setLoadingOrders,
		setLoadedEvents,
		setLoadedOrders,
		setEvents,
		setOrders,
		setSelectedEvent,
		setSelectedMode,
		setSelectedDate
	};
};
