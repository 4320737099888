// components/ChatModal.tsx
import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { actions } from "redux/reducers/chatAssist/reducer";
import { ChatAssistSelectors } from "redux/selectors";

import { IClickUpTask } from "models/chatAssist";

import "./ChatAssistModal.scss";

/**
 * Renders the message History part of the Chat Assist module.
 */
export const ChatAssistTicketHistoryList: React.FC = () => {
	const dispatch = useDispatch();
	const chatAssist = useSelector(ChatAssistSelectors.get);

	const onClickHistoryItem =
		(historyItem: IClickUpTask) => async (e: React.MouseEvent) => {
			e.preventDefault();

			dispatch(actions.setActiveTicket(historyItem.id));
		};

	return (
		<div className="chat-history-scrollable">
			{chatAssist.tickets.map((item) => {
				return (
					<button
						key={item.id}
						className={`chat-history-item ${
							chatAssist.activeTicket &&
							item.id === chatAssist.activeTicket
								? "selected"
								: ""
						}`}
						title={item.description}
						onClick={onClickHistoryItem(item)}
					>
						<div>{item.name}</div>
					</button>
				);
			})}
		</div>
	);
};
