import React, { useMemo } from "react";

import { IInputBaseProps, InputBase } from "./InputBase";

/**
 * InputValue props contract interface.
 */
interface IInputNumberProps extends IInputBaseProps {
	minLength?: number;
	maxLength?: number;
	step?: number;
	minValue?: number;
	maxValue?: number;
}

/**
 * InputValue shared component.
 *
 * @param props IInputValueProps
 * @returns
 */
function InputNumber(props: IInputNumberProps): JSX.Element {
	const resolvedStep = props?.step ?? 0.01;
	const resolvedValue = useMemo(() => {
		try {
			const preprocessedValue =
				!props?.value ||
				props?.value === "" ||
				props?.value === "NaN" ||
				undefined === props?.value
					? false
					: props?.value.toString();

			if (!preprocessedValue) {
				return "";
			}

			return props.value;
		} catch (error) {
			console.error(error);
		}
	}, [props?.value]);

	return (
		<InputBase
			step={resolvedStep}
			type="number"
			id={props.id}
			value={resolvedValue}
			onChange={props.onChange}
			disabled={props.disabled ?? undefined}
			placeholder={props.placeholder ?? undefined}
			minValue={props.minValue ?? undefined}
			maxValue={props.maxValue ?? undefined}
			{...props}
		/>
	);
}

export { InputNumber, IInputNumberProps };
