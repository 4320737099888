import FirebaseFunctionsService from "services/firebase/functions/FirebaseFunctionsService";

/**
 * Gets the public menu data for a given company, by ID.
 * This service uses a callable function with App Check enabled.
 */
export class GetPublicCompanyMenuService extends FirebaseFunctionsService {
	constructor() {
		super("getPublicCompanyMenu");
	}

	/**
	 * Gets the public menu data for a company
	 * @param companyId The company ID
	 * @returns Promise with the company menu data
	 */
	async getMenu(companyId: string): Promise<any> {
		try {
			const result = await this.callService({
				companyId
			});
			return result.data;
		} catch (error) {
			console.error("error-getting-public-menu", error);
			throw error;
		}
	}
}
