import { appStateService, store } from "App";
import { StockMovementDirection } from "enums";
import { Timestamp } from "firebase/firestore";
import { IStock, StockMovement } from "models/index";
import { actions } from "redux/reducers/stock/list/reducer";
import { StockListSelectors } from "redux/selectors";
import { CompanySegment } from "services/companies/CompanySegment";

/**
 * Class for managing the basics of the Company Product Stock data.
 * Manages the Redux store, as well as the Firebase database modifications.
 * Also, registers logs of the operations.
 */
class CompanyStockService extends CompanySegment<IStock> {
	constructor(companyId: string) {
		super(
			companyId,
			"productStock",
			actions.setList,
			actions.setQueried,
			actions.setLoading
		);
	}

	/**
	 * Override: The default create, to intercept and dispatch the Stock Movement.
	 *
	 * @param item The stock item to be created.
	 * @param onCreated The callback to be executed after the item is created.
	 *
	 * @returns A boolean indicating the success of the operation.
	 */
	async createItem(
		item: IStock,
		onCreated: (item: IStock) => void,
		skipStockMovement?: undefined | boolean
	): Promise<boolean> {
		const result = await super.createItem(item, onCreated);
		let stockAdded = false;

		if (!result) return false;
		if (item.quantity === 0) return result;

		if (!skipStockMovement) {
			// Dispatch the Stock Movement
			stockAdded = await this.registerEntering(
				item.productId,
				item.quantity
			);
		}

		return result && stockAdded;
	}

	/**
	 * Override: The default update, to intercept and dispatch the Stock Movement.
	 *
	 * @param item The stock item to be updated.
	 * @param onUpdated The callback to be executed after the item is updated.
	 *
	 * @returns A boolean indicating the success of the operation.
	 */
	async updateItem(
		item: IStock,
		onUpdated: (item: IStock) => void
	): Promise<boolean> {
		let stockUpdated = await super.updateItem(item, onUpdated);

		return stockUpdated;
	}

	/**
	 * Gets the list of stocks by product ID.
	 *
	 * @param productId The product ID referenced by the Stock entry.
	 * @returns A list of stocks.
	 */
	async getByProductId(productId: string): Promise<IStock[]> {
		return this.queryItemsByProp("productId", productId);
	}

	/**
	 * Gets the list of stocks by supplier ID.
	 *
	 * @param supplierId The supplier ID referenced by the Stock entry.
	 * @returns A list of stocks.
	 */
	async getBySupplierId(supplierId: string): Promise<IStock[]> {
		return this.queryItemsByProp("supplierId", supplierId);
	}

	/**
	 * Gets the list of stocks by product SKU.
	 *
	 * @param productSku The product SKU referenced by the Stock entry.
	 * @returns A list of stocks.
	 */
	async getByProductSku(productSku: string): Promise<IStock[]> {
		return this.queryItemsByProp("productSku", productSku);
	}

	/**
	 * Gets the list of stocks by product Bar Code.
	 *
	 * @param productBarCode The product Bar Code referenced by the Stock entry.
	 * @returns A list of stocks.
	 */
	async getByProductBarCode(productBarCode: string): Promise<IStock[]> {
		return this.queryItemsByProp("productBarCode", productBarCode);
	}

	// TODO: Implement missing functionalities

	/**
	 * Registers a new stock in/out, to a given product ID.
	 *
	 * @param productSearch The product ID, Bar Code, or SKU, to query a product with.
	 * @param quantity The quantity to register moving in/out to stock.
	 * @param isOut A boolean indicating if the movement is out of stock.
	 * @returns A boolean indicating the success of the operation.
	 */
	async registerMovement(
		productSearch: string,
		quantity: number,
		direction: StockMovementDirection,
		onCompleted?: undefined | ((item: IStock) => void)
	): Promise<boolean> {
		const productSvc = appStateService.company.product.get();
		const stockMovement = appStateService.company.stockMovement.get();

		if (!productSearch || quantity <= 0) return false;

		const productEntries = this.unifyLists([
			[await productSvc.getItemById(productSearch)],
			[await productSvc.getBySKU(productSearch)],
			[await productSvc.getByBarCode(productSearch)]
		]);

		const foundEntries = this.unifyLists([
			[...(await this.getByProductId(productSearch))],
			[...(await this.getByProductSku(productSearch))],
			[...(await this.getByProductBarCode(productSearch))]
		]);

		if (!productEntries || productEntries.length === 0) return false;

		if (foundEntries.length === 0) {
			// TODO: Study this case when no entry is found
			throw new Error("No-inventory-entry-found");
		}

		// Update the existing entry
		let foundStock = foundEntries[0];

		// TODO Based on the movement type, checks if the quantity is available
		// if (direction === StockMovementDirection.out) {
		// 	if (foundStock.quantity < quantity)
		// 		throw new Error("not-enough-stock");
		// 	foundStock.quantity -= quantity;
		// } else if (direction === StockMovementDirection.in) {
		// 	foundStock.quantity += quantity;
		// } else if (direction === StockMovementDirection.destroy) {
		// 	foundStock.quantity = 0;
		// } else {
		// 	// Any other case will be considered copy quantity
		// 	foundStock.quantity = quantity;
		// }

		// debugger;

		// // Updates the stock entry
		// await this.updateItem(foundStock, (updatedItem) => {
		// 	foundStock = updatedItem;
		// });

		// return await super.updateItem(foundStock, (updatedItem) => {
		const movementEntry: StockMovement = {
			id: null,
			productId: foundStock.productId,
			productSku: foundStock.productSku,
			productBarCode: foundStock.productBarCode,
			stockId: foundStock.id,
			quantity,
			direction,
			timestamp: Timestamp.now(),
			deleted: false
		};

		const movementResult = await stockMovement.createItem(
			movementEntry,
			(createdItem) => {
				if (typeof onCompleted === "function") onCompleted(foundStock);
			}
		);

		return movementResult;
	}

	/**
	 * Registers a new stock entry, to a given product ID.
	 *
	 * @param productSearch The product ID, Bar Code, or SKU to query with.
	 * @param quantity The quantity to register entering to stock.
	 * @returns A boolean indicating the success of the operation.
	 */
	async registerEntering(
		productSearch: string,
		quantity: number
	): Promise<boolean> {
		return await this.registerMovement(
			productSearch,
			quantity,
			StockMovementDirection.in
		);
	}

	/**
	 * Registers a new stock out, to a given product ID.
	 *
	 * @param productSearch The product ID, Bar Code, or SKU to query with.
	 * @param quantity The quantity to register moving out of stock.
	 * @returns A boolean indicating the success of the operation.
	 */
	async registerOutgoing(
		productSearch: string,
		quantity: number
	): Promise<boolean> {
		return await this.registerMovement(
			productSearch,
			quantity,
			StockMovementDirection.out
		);
	}

	/**
	 * Sets the Stock form data for the Stock editing currently.
	 *
	 * @param data The form data to be set.
	 */
	setStockFormData(data: Partial<IStock>): void {
		store.dispatch(actions.setStockFormData(data));
	}

	/**
	 * Gets the Stock form data for the Stock editing currently,
	 * from Redux store.
	 */
	getStockFormData(): IStock {
		return StockListSelectors.getStockFormData(store.getState());
	}

	/**
	 * Gets the Queried state of the Stock List,
	 * from Redux store.
	 */
	getQueried(): boolean {
		return StockListSelectors.getStockListQueried(store.getState());
	}

	/**
	 * Gets the Loading state of the Stock List,
	 * from Redux store.
	 */
	getLoading(): boolean {
		return StockListSelectors.getStockListLoading(store.getState());
	}
}

export { CompanyStockService };
