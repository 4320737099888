import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CalendarViewMode } from "components/page/calendar/shared/enum";
import { ICalendarItem } from "interfaces/calendar/ICalendarItem";
import {
	CalendarItem,
	calendarSliceInitialState,
	IClientOrder
} from "models/index";
import { ReduxSlices } from "redux/slices";

/**
 * Calendar Redux slice.
 */
const calendarSlice = createSlice({
	name: ReduxSlices.Calendar,
	initialState: calendarSliceInitialState,
	reducers: {
		setEvents: (state, action: PayloadAction<ICalendarItem[]>) => {
			state.events = action.payload;
		},
		setLoadingEvents: (state, action: PayloadAction<boolean>) => {
			state.loading.events = action.payload;
		},
		setLoadingOrders: (state, action: PayloadAction<boolean>) => {
			state.loading.orders = action.payload;
		},
		setError: (state, action: PayloadAction<string | null>) => {
			state.error = action.payload;
		},
		setCurrentEvent: (
			state,
			action: PayloadAction<ICalendarItem | null>
		) => {
			state.currentEvent = action.payload ?? new CalendarItem();
		},
		updateEvent: (state, action: PayloadAction<ICalendarItem>) => {
			const index = state.events.findIndex(
				(event) => event.id === action.payload.id
			);
			if (index >= 0) {
				const newEvents = [...state.events];
				newEvents[index] = action.payload;
				state.events = newEvents;
			}
		},
		updateCurrentEvent: (
			state,
			action: PayloadAction<Partial<ICalendarItem>>
		) => {
			state.currentEvent = { ...state.currentEvent, ...action.payload };
		},
		addEvent: (state, action: PayloadAction<ICalendarItem>) => {
			state.events = [...state.events, action.payload];
		},
		removeEvent: (state, action: PayloadAction<string>) => {
			state.events = state.events.filter(
				(event) => event.id !== action.payload
			);
		},
		setSelectedMode: (state, action: PayloadAction<CalendarViewMode>) => {
			state.selectedMode = action.payload;
		},
		setSelectedDate: (state, action: PayloadAction<Date>) => {
			state.selectedDate = action.payload;
		},
		setOrders: (state, action: PayloadAction<IClientOrder[]>) => {
			state.orders = action.payload;
		},
		setLoadedEvents: (state, action: PayloadAction<boolean>) => {
			state.loaded.events = action.payload;
		},
		setLoadedOrders: (state, action: PayloadAction<boolean>) => {
			state.loaded.orders = action.payload;
		},
		setShowOrders: (state, action: PayloadAction<boolean>) => {
			state.filters.showOrders = action.payload;
		},
		setShowEvents: (state, action: PayloadAction<boolean>) => {
			state.filters.showEvents = action.payload;
		},
		setShowTasks: (state, action: PayloadAction<boolean>) => {
			state.filters.showTasks = action.payload;
		},
		reset: (state) => {
			state.orders = [];
			state.events = [];
			state.currentEvent = new CalendarItem();
			state.loading = {
				events: false,
				orders: false
			};
			state.loaded = {
				events: false,
				orders: false
			};
			state.error = null;
			state.selectedMode = CalendarViewMode.Week;
			state.selectedDate = new Date();
			state.filters = {
				showOrders: true,
				showEvents: true,
				showTasks: true
			};
		}
	}
});

export const {
	setEvents,
	setLoadingEvents,
	setLoadingOrders,
	setError,
	setCurrentEvent,
	updateEvent,
	addEvent,
	removeEvent,
	setSelectedMode,
	setSelectedDate,
	setOrders,
	setLoadedEvents,
	setLoadedOrders,
	setShowOrders,
	setShowEvents,
	reset
} = calendarSlice.actions;

export const { reducer, actions } = calendarSlice;

export default reducer;
