import FirebaseFunctionsService from "services/firebase/functions/FirebaseFunctionsService";

/**
 * Used to authenticate a first-login User Validation Code.
 * This service uses a callable function with App Check enabled.
 */
class ValidateCustomerCodeService extends FirebaseFunctionsService {
	constructor() {
		super("validateUserCode");
	}

	/**
	 * Validates a customer code for first-time login
	 * @param code The validation code
	 * @param customerId The customer ID
	 * @returns Promise with the result of the operation
	 */
	async validateCode(code: string, customerId: string): Promise<any> {
		try {
			const result = await this.callService({
				code,
				customerId
			});
			return result.data;
		} catch (error) {
			console.error("error-validating-customer-code", error);
			throw error;
		}
	}
}

export { ValidateCustomerCodeService };
