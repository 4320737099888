import { store } from "App";
import { actions } from "redux/reducers/chatAssist/reducer";
import { ChatAssistSelectors } from "redux/selectors";
import { onCreateCommentToActiveTicket } from "./onCreateCommentToActivateTicket";
import { onCreateNewTicketAndActivate } from "./onCreateNewTicketAndActivate";

/**
 * Handles the submission of Message / Ticket data.
 *
 * @param e The event to handle
 */
export const handleSubmitMessage = async (
	e: React.FormEvent | React.MouseEvent,
	onSubmitComplete?: undefined | ((createdItem: any) => Promise<void>)
): Promise<void> => {
	const storeState = store.getState();
	const activeTicket = ChatAssistSelectors.getActiveTicket(storeState);

	e.preventDefault();

	// Submitting state, to block fields and buttons
	store.dispatch(actions.setSubmitting(true));

	try {
		// When handling the submit to a chat response, will instead add a task comment.
		if (activeTicket && activeTicket?.id !== "") {
			await onCreateCommentToActiveTicket(async (createdComment) => {
				if (typeof onSubmitComplete === "function") {
					await onSubmitComplete(createdComment);
					return;
				}
			});

			return;
		}

		// Submits data to click up
		await onCreateNewTicketAndActivate();
	} catch (err) {
		store.dispatch(
			actions.submitFailure(err.message || "Failed to submit ticket")
		);
	} finally {
		// Clearing the block state, for fields and buttons
		store.dispatch(actions.setSubmitting(false));
	}
};
