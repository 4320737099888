import { FirebaseOperators, PropQueryCriteria } from "./firestoreQuery.types";
import "firebase/firestore";

/**
 * Returns the necessary criteria for a "where-like" query.
 * In Firestore, some indexing issues might occur - please, monitor the Console Log.
 *
 * @param propName The property name to use for filtering
 * @param value The value to use for filtering (creating the like expression)
 *
 * @returns The criteria for the Where-Like query.
 */
export function whereLike(
	propName: string,
	value: string | boolean,
	includeDeleted: boolean = false
): PropQueryCriteria[] {
	const conditions: PropQueryCriteria[] =
		typeof value === "boolean"
			? [[propName, FirebaseOperators.equals, value]]
			: [
					[propName, FirebaseOperators.greaterThanOrEqual, value],
					[propName, FirebaseOperators.lessThan, value + "\uf8ff"]
			  ];

	return includeDeleted
		? conditions
		: [["deleted", FirebaseOperators.equals, false], ...conditions];
}
