import React from "react";

/**
 * InputBase props contract interface.
 */
interface IInputBaseProps {
	value?: undefined | string | number;
	checked?: undefined | boolean;
	id?: undefined | string;
	onChange?: undefined | ((event: any) => void);
	onKeyDown?: undefined | ((event: any) => void);
	onKeyUp?: undefined | ((event: any) => void);
	className?: undefined | string;
	type?: undefined | string;
	disabled?: undefined | boolean;
	placeholder?: undefined | string;
	maxLength?: undefined | number;
	minLength?: undefined | number;
	name?: undefined | string;
	required?: undefined | boolean;
	ref?: undefined | React.RefObject<HTMLInputElement>;
	minValue?: undefined | number;
	maxValue?: undefined | number;
	readonly?: undefined | boolean;
	step?: undefined | number;
}

/**
 * InputBase, basic for constructing other shared input components.
 *
 * @param props IInputBaseProps
 * @returns
 */
function InputBase(props: IInputBaseProps): JSX.Element {
	const resolvedType = props?.type ?? "text";
	// const formattedValue = ? appStateService.format.float(props.value)

	// In case a ref is passed, wrap the input component with the correct approach to render with forwardRef.
	if (props?.ref) {
		return (
			<input
				type={resolvedType}
				id={props?.id ?? undefined}
				value={props?.value ?? undefined}
				checked={props?.checked ?? undefined}
				onChange={props?.onChange ?? undefined}
				onKeyDown={props?.onKeyDown ?? undefined}
				onKeyUp={props?.onKeyUp ?? undefined}
				className={props?.className ?? undefined}
				disabled={props?.disabled ?? undefined}
				placeholder={props?.placeholder ?? undefined}
				maxLength={props?.maxLength ?? undefined}
				minLength={props?.minLength ?? undefined}
				name={props?.name ?? undefined}
				required={props?.required ?? undefined}
				min={props?.minValue ?? undefined}
				max={props?.maxValue ?? undefined}
				readOnly={props?.readonly ?? undefined}
				step={props?.step ?? undefined}
				{...props}
				ref={props?.ref}
			/>
		);
	}

	return (
		<input
			type={resolvedType}
			id={props?.id ?? undefined}
			value={props?.value ?? undefined}
			checked={props?.checked ?? undefined}
			onChange={props?.onChange ?? undefined}
			onKeyDown={props?.onKeyDown ?? undefined}
			onKeyUp={props?.onKeyUp ?? undefined}
			className={props?.className ?? undefined}
			disabled={props?.disabled ?? undefined}
			placeholder={props?.placeholder ?? undefined}
			maxLength={props?.maxLength ?? undefined}
			minLength={props?.minLength ?? undefined}
			name={props?.name ?? undefined}
			required={props?.required ?? undefined}
			min={props?.minValue ?? undefined}
			max={props?.maxValue ?? undefined}
			readOnly={props?.readonly ?? undefined}
			step={props?.step ?? undefined}
			{...props}
		/>
	);
}

export { InputBase, IInputBaseProps };
