import { WhereFilterOp, Timestamp } from "firebase/firestore";
import "firebase/firestore";

/**
 * Contract for the Firebase operators for querying.
 */
export type PropQueryCriteria = [
	string,
	WhereFilterOp,
	(
		| string
		| number
		| boolean
		| Date
		| Timestamp
		| string[]
		| number[]
		| boolean[]
		| Date[]
		| Timestamp[]
	)
];

/**
 * Contract for the Firebase operators for querying.
 */
export interface IFirebaseOperators {
	equals: WhereFilterOp; //"==",
	notEqualTo: WhereFilterOp; //"!=",
	lessThan: WhereFilterOp; //"<",
	lessThanOrEqual: WhereFilterOp; //"<=",
	greaterThan: WhereFilterOp; //">",
	greaterThanOrEqual: WhereFilterOp; //">=",
	arrayContains: WhereFilterOp; //"array-contains",
	arrayContainsAny: WhereFilterOp; //"array-contains-any",
	in: WhereFilterOp; //"in",
	notIn: WhereFilterOp; //"not-in"
	// [key: string]: WhereFilterOp;
}

/**
 * Firebase operators for querying.
 * From https://firebase.google.com/docs/firestore/query-data/queries#query_operators.
 */
export const FirebaseOperators: IFirebaseOperators = {
	equals: "==",
	notEqualTo: "!=",
	lessThan: "<",
	lessThanOrEqual: "<=",
	greaterThan: ">",
	greaterThanOrEqual: ">=",
	arrayContains: "array-contains",
	arrayContainsAny: "array-contains-any",
	in: "in",
	notIn: "not-in"
};
