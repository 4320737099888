import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxSlices } from "redux/slices";
import {
	appConfigInitialState as initialState,
	IAppConfig,
	IToast
} from "models";
import { MessageModalType } from "enums";
import { AppModuleNames, ModuleActionIds } from "components/modules";
import { IStoreProfileConfig } from "interfaces/index";
import { AppProfileNames } from "components/modules/profiles/AppProfileNames";

/**
 * Categories Redux slice.
 */
export const categoriesSlice = createSlice({
	name: ReduxSlices.AppConfig,
	initialState,
	reducers: {
		/**
		 * Sets the whole app configuration base on its domain model.
		 *
		 * @param state The current state of the app
		 * @param action The action with data to be carried
		 */
		setConfig: (state, action: PayloadAction<IAppConfig>): void => {
			state = action.payload;
		},
		/**
		 * Sets the app bar title.
		 *
		 * @param state The current state of the app
		 * @param action The action with data to be carried
		 */
		setAppBarHidden: (state, action: PayloadAction<boolean>): void => {
			state.appBar.hidden = action.payload;
		},
		/**
		 * Sets the app bar title.
		 *
		 * @param state The current state of the app
		 * @param action The action with data to be carried
		 */
		setAppBarTitle: (state, action: PayloadAction<string>): void => {
			state.appBar.title = action.payload;
		},
		/**
		 * Cleans up and resets the App Config state.
		 *
		 * @param state The current state of the app.
		 */
		reset: (state) => {
			state = initialState;
		},
		/**
		 * Sets the loading state of the App Config.
		 *
		 * @param state The current state of the app
		 * @param action The action with data to be carried
		 */
		setLoading: (state, action: PayloadAction<boolean>): void => {
			state.loader.loading = action.payload;
		},
		/**
		 * Sets the loading text of the App Config.
		 *
		 * @param state The current state of the app
		 * @param action The action with data to be carried
		 */
		setLoadingText: (state, action: PayloadAction<string>): void => {
			state.loader.loadingText = action.payload;
		},
		/**
		 * Sets the message modal type.
		 *
		 * @param state The current state of the app
		 * @param action The action with data to be carried
		 */
		setMessageModalType: (
			state,
			action: PayloadAction<MessageModalType>
		): void => {
			state.messageModal.type = action.payload;
		},
		/**
		 * Sets the Message Modal open state of the App.
		 *
		 * @param state The current state of the app
		 * @param action The action with current open state
		 */
		setMessageModalOpen: (state, action: PayloadAction<boolean>): void => {
			state.messageModal.open = action.payload;
		},
		/**
		 * Sets the Message Modal title of the App.
		 *
		 * @param state The current state of the app
		 * @param action The action with current title
		 */
		setMessageModalTitle: (state, action: PayloadAction<string>): void => {
			state.messageModal.title = action.payload;
		},
		/**
		 * Sets the Message Modal text of the App.
		 *
		 * @param state The current state of the app
		 * @param action The action with current text
		 */
		setMessageModalText: (state, action: PayloadAction<string>): void => {
			state.messageModal.text = action.payload;
		},
		/**
		 * Sets the Company Profile type.
		 *
		 * @param state The current state of the app
		 * @param action The action with current type
		 */
		setCompanyProfileType: (
			state,
			action: PayloadAction<AppProfileNames | null>
		): void => {
			state.companyProfile.type = action.payload;
		},
		/**
		 * Sets the Company Profile modules loading state.
		 *
		 * @param state The current state of the app
		 * @param action The action with current modules
		 */
		setCompanyProfileLoading: (
			state,
			action: PayloadAction<boolean>
		): void => {
			state.companyProfile.loading = action.payload;
		},
		/**
		 * Sets the Company Profile modules loaded state.
		 *
		 * @param state The current state of the app
		 * @param action The action with current modules
		 */
		setCompanyProfileLoaded: (
			state,
			action: PayloadAction<boolean>
		): void => {
			state.companyProfile.loaded = action.payload;
		},
		/**
		 * Sets the Company Profile modules list.
		 *
		 * @param state The current state of the app
		 * @param action The action with current modules
		 */
		setCompanyProfileModules: (
			state,
			action: PayloadAction<AppModuleNames[]>
		): void => {
			state.companyProfile.modules = action.payload;
		},
		/**
		 * Sets the Company Profile module actions list.
		 *
		 * @param state The current state of the app
		 * @param action The action with current module actions
		 */
		setCompanyProfileModuleActions: (
			state,
			action: PayloadAction<ModuleActionIds[]>
		): void => {
			state.companyProfile.moduleActions = action.payload;
		},
		/**
		 * Sets the Company Profile config.
		 *
		 * @param state The current state of the app
		 * @param action The action with current config
		 */
		setCompanyProfileConfig: (
			state,
			action: PayloadAction<IStoreProfileConfig>
		): void => {
			state.companyProfile.config = action.payload;
		},
		/**
		 * Sets the Company Profile Title in its configuration.
		 *
		 * @param state The current state of the app
		 * @param action The action with current config
		 */
		setCompanyProfileTitle: (
			state,
			action: PayloadAction<string>
		): void => {
			state.companyProfile.config.storeTitle = action.payload;
		},
		/**
		 * Sets the Company Profile Name in its configuration.
		 *
		 * @param state The current state of the app
		 * @param action The action with current config
		 */
		setCompanyProfileName: (state, action: PayloadAction<string>): void => {
			state.companyProfile.config.storeName = action.payload;
		},
		/**
		 * Shows a toast message by adding it to the queue.
		 *
		 * @param state The current state of the app
		 * @param action The action with current message and type
		 */
		showToast: (
			state,
			action: PayloadAction<Omit<IToast, "id" | "timestamp" | "open">>
		) => {
			const newToast: IToast = {
				id: Math.random().toString(36).substr(2, 9),
				message: action.payload.message,
				type: action.payload.type,
				open: true,
				timestamp: Date.now()
			};

			// Add new toast to queue
			state.toast.queue.push(newToast);

			// Keep only the most recent maxToasts
			if (
				state.toast.maxToasts &&
				state.toast.queue.length > state.toast.maxToasts
			) {
				state.toast.queue = state.toast.queue.slice(
					-state.toast.maxToasts
				);
			}
		},
		/**
		 * Removes a specific toast from the queue.
		 */
		dismissToast: (state, action: PayloadAction<string>) => {
			state.toast.queue = state.toast.queue.filter(
				(toast) => toast.id !== action.payload
			);
		},
		/**
		 * Clears all toasts from the queue.
		 */
		clearToasts: (state) => {
			state.toast.queue = [];
		}
	}
});

export const { reducer, actions } = categoriesSlice;

export default reducer;
