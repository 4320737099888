import { Loader } from "@googlemaps/js-api-loader";
import { MapsConfigs } from "./mapsConfigs";
import { IAddressResult } from "components/shared/address/AddressDisambiguation";
import { firebaseService } from "services/firebase/FirebaseService";
import { getFunctions, httpsCallable } from "firebase/functions";

/**
 * @description Enum for the secret keys
 */
enum SecretKeys {
	googleMapsAPI = "REACT_APP_GOOGLE_MAPS_API_KEY"
}

/**
 * @description Get a key from the Secret Manager via Firebase Function
 * @param key The key to get
 * @returns The key value
 */
async function getKeyFromSecretManager(
	key: SecretKeys
): Promise<string | null> {
	try {
		// Get Firebase Functions instance
		const functions = getFunctions(firebaseService.app);

		// Create a callable reference to our getSecret function
		const getSecret = httpsCallable<{ key: string }, { value: string }>(
			functions,
			"getSecret"
		);

		// Call the function with the key we want
		const result = await getSecret({ key });

		return result.data.value;
	} catch (error) {
		console.error("Error fetching secret:", error);

		// Fallback to environment variable if function fails
		return process.env[key] || null;
	}
}

/**
 * Class for loading
 * Google Maps API underneath.
 */
export class GoogleMapsLoader {
	private loader: Loader;
	private map: google.maps.Map;
	private latitude: string;
	private longitude: string;
	private locationBias: google.maps.LatLngLiteral;
	private markers: google.maps.marker.AdvancedMarkerElement[] = [];
	private mapElementQuery: string;
	private isSearching: boolean = false;
	private isMapLoaded: boolean = false;
	private defaultZoom: number = MapsConfigs.mapZooms.default;
	private apiKey: string | null = null;

	private _mapsLibrary: google.maps.MapsLibrary;
	private _placesLibrary: google.maps.PlacesLibrary;
	private _markerLibrary: google.maps.MarkerLibrary;
	private _coreLibrary: google.maps.CoreLibrary;

	private PlaceScopes: string[] = MapsConfigs.placeScopes;

	constructor() {
		// Initialize with empty API key - will be set during initialization
		this.loader = new Loader({
			apiKey: "",
			version: "weekly",
			libraries: MapsConfigs.enabledAPIs
		});

		this.locationBias = { lat: -29.771219, lng: -51.148103 };
	}

	/**
	 * Initialize the loader with the API key from Secret Manager
	 */
	async initialize(): Promise<void> {
		if (!this.apiKey) {
			this.apiKey = await getKeyFromSecretManager(
				SecretKeys.googleMapsAPI
			);

			if (!this.apiKey) {
				console.error("Failed to get Google Maps API key");
				throw new Error("Failed to get Google Maps API key");
			}

			// Reinitialize loader with the API key
			this.loader = new Loader({
				apiKey: this.apiKey,
				version: "weekly",
				libraries: MapsConfigs.enabledAPIs
			});
		}
	}

	async load(
		elementSelector: string,
		latitude?: undefined | string,
		longitude?: undefined | string
	): Promise<google.maps.Map> {
		// Ensure we have the API key
		if (!this.apiKey) {
			await this.initialize();
		}

		if (elementSelector !== this.mapElementQuery) {
			this.mapElementQuery = elementSelector;
			this.isMapLoaded = false;
		}

		if (this.isMapLoaded && this.map) {
			const lat = parseFloat(
				latitude ?? this.locationBias.lat.toString()
			);
			const lng = parseFloat(
				longitude ?? this.locationBias.lng.toString()
			);

			this.map.setCenter({ lat, lng });
			this.map.setZoom(this.defaultZoom);

			return this.map;
		}

		if (!this._mapsLibrary) {
			this._mapsLibrary = await this.loader.importLibrary("maps");
		}

		const { Map } = this._mapsLibrary;

		this.latitude = latitude ?? this.locationBias.lat.toString();
		this.longitude = longitude ?? this.locationBias.lng.toString();

		const mapElement = document.querySelector(
			elementSelector
		) as HTMLElement;

		if (!mapElement) {
			throw new Error(`Map element not found: ${elementSelector}`);
		}

		this.map = new Map(mapElement, {
			center: {
				lat: parseFloat(this.latitude),
				lng: parseFloat(this.longitude)
			},
			zoom: this.defaultZoom,
			mapId: MapsConfigs.mapIds.quickView,
			mapTypeControl: false,
			streetViewControl: false,
			fullscreenControl: false,
			tiltInteractionEnabled: false,
			zoomControl: false
		});

		this.isMapLoaded = true;
		return this.map;
	}

	/**
	 * Clears all markers from the map
	 */
	private clearMarkers(): void {
		this.markers.forEach((marker) => {
			marker.map = null;
		});
		this.markers = [];
	}

	/**
	 * Adds a marker to the map
	 */
	private addMarker(
		position: google.maps.LatLngLiteral,
		title?: string
	): google.maps.marker.AdvancedMarkerElement {
		const marker = new google.maps.marker.AdvancedMarkerElement({
			map: this.map,
			position,
			title
		});
		this.markers.push(marker);
		return marker;
	}

	async findPlaces(
		mapRef: google.maps.Map,
		textQuery: string,
		placeTypes: undefined | string[] = this.PlaceScopes,
		onFound:
			| undefined
			| ((places: google.maps.places.Place[]) => void) = null
	): Promise<google.maps.places.Place[]> {
		if (this.isSearching) return [];
		this.isSearching = true;

		try {
			if (!this._placesLibrary) {
				this._placesLibrary = (await this.loader.importLibrary(
					"places"
				)) as google.maps.PlacesLibrary;
			}
			if (!this._markerLibrary) {
				this._markerLibrary = (await this.loader.importLibrary(
					"marker"
				)) as google.maps.MarkerLibrary;
			}
			if (!this._coreLibrary) {
				this._coreLibrary = (await this.loader.importLibrary(
					"core"
				)) as google.maps.CoreLibrary;
			}

			const { Place } = this._placesLibrary;
			const { LatLngBounds } = this._coreLibrary;

			// Clear existing markers
			this.clearMarkers();

			const request: google.maps.places.SearchByTextRequest = {
				textQuery,
				fields: [
					"displayName",
					"location",
					"businessStatus",
					"formattedAddress",
					"id"
				],
				// includedType: placeTypes,
				// includedType: placeTypes?.[0] ?? "establishment",
				locationBias: this.locationBias
			};

			const { places } = await Place.searchByText(request);

			if (places?.length) {
				const bounds = new LatLngBounds();

				places.forEach((place) => {
					if (place.location) {
						const position = {
							lat:
								typeof place.location.lat === "function"
									? place.location.lat()
									: Number(place.location.lat),
							lng:
								typeof place.location.lng === "function"
									? place.location.lng()
									: Number(place.location.lng)
						};

						const marker = this.addMarker(
							position,
							place.displayName
						);
						bounds.extend(place.location);
					}
				});

				//if (bounds.isEmpty()) {
				if (!places[0].location) {
					mapRef.setCenter(this.locationBias);
					mapRef.setZoom(this.defaultZoom);
				} else {
					//mapRef.fitBounds(bounds);
					//mapRef
					mapRef.setCenter(places[0].location);
					mapRef.setZoom(this.defaultZoom);
				}

				if (typeof onFound === "function") {
					onFound(places);
				}
			}

			return places || [];
		} catch (error) {
			console.error("Error searching for places:", error);
			throw error;
		} finally {
			this.isSearching = false;
		}
	}

	getMap(): google.maps.Map {
		return this.map;
	}

	getIsSearching(): boolean {
		return this.isSearching;
	}

	getIsMapLoaded(): boolean {
		return this.isMapLoaded;
	}

	openLocationOnMaps(address: IAddressResult): void {
		// TODO: Open a new window with the google maps search for that Place ID
		// Otherwise, use its coordinates.
		if (address.placeId) {
			window.open(
				`https://www.google.com/maps/place/?q=place_id:${address.placeId}`,
				"_blank"
			);
		} else {
			window.open(
				`https://www.google.com/maps/search/?api=1&query=${address.location.lat},${address.location.lng}`,
				"_blank"
			);
		}
	}
}

export const GoogleMapsLoaderInstance = new GoogleMapsLoader();
